import { useState } from "react"; 
import { toast } from "react-toastify";

import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Ripple from "../components/Ripple";
import { forgotPassword } from "../services/auth";
import history from "../components/History";

interface EmailFormProps {
  handleSubmit: any,
  handleEmailChange: any,
  disabled: boolean,
  busy: boolean,
  emailRef: React.RefObject<any>
}

const ResetPasswordEmail = (props: EmailFormProps) => {
  const [email, setEmail] = useState('');
  const [disabled] = useState(false);
  const [busy, setBusy] = useState(false);

  const validateEmail = (): boolean => {
    if(email.length === 0) {
      toast.info("Please enter your email address");
      return false;
    }
    if(!email.includes('@') || !email.includes('.')) {
      toast.info("Please enter a valid email address");
      return false;
    }
    return true;
  }

  const toastOptions = {
    onClose: () => history.push('/login') 
  }


  const handleSubmit = async(e: any) => {
    e.preventDefault();
    if(!validateEmail())
      return;
    
    setBusy(true);
    let form = { email: email};

    await forgotPassword(form)
      .then(() => {
        setBusy(false);
        toast.success("We've sent a link to the email address you provided. Follow the link to reset your password.", toastOptions);
      })
      .catch((e: any) => {
        setBusy(false);
        toast.error(e.response.data.errors.email[0])
      })
  }


  return (
    <>
      <Nav />
      <div className="login-header" style={{height: '50vh', marginBottom: '40vh'}}>
        <div className="login-title-div" style={{paddingTop: '230px'}}>
          <h3>Reset Your Password</h3>
          <h5>Enter your email and we will send you a password reset link</h5>
        </div>
      </div>
      <div className="form-div column-center">
        <form className="form-container" style={{width: '400px'}} onSubmit={handleSubmit}>
          <div className="input-div" style={{width: '100%', paddingLeft: 0}}>
            <div className="input-group">
              <p>Email</p>
              <input 
                placeholder="someone@example.com"
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            {busy ?
              (
                <div className="loader">
                  <Ripple color="#ff3366" />
                </div>
              ) : (
                <button
                  className="form-action"
                  onClick={handleSubmit}
                  disabled={disabled}
                  style={{ backgroundColor: props.disabled ? "#616161" : "", margin: '20px 0 20px 0'}}
                >SEND LINK</button>
              )}
          </div>
        </form>
      </div>
      <Footer upper_div={false}/>
    </>
  )
}

export default ResetPasswordEmail;