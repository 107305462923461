import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";

import Nav from "../../dashboard_components/Nav";
import OrgMemberSidebar from "../../dashboard_components/OrgMemberSidebar";
import { addAuctions, fetchOrgAuctions, handlePrizeSelect, fetchPrizes, sortOptions, handleTeamSelect } from "../../services/dashboardauctions";
import AddAuctionForm from "../../models/add-auction-form";
import SelectModel from "../../models/select";
import DashboardPrize from "../../models/dashboardprize";
import { RootContext } from "../..";
import DashboardAuction from "../../models/dashboard-auction";
import Ripple from "../../components/Ripple";
import CustomTable from "../../components/CustomTable";
import Team from "../../models/team";

export default function OrgMemberAuctions(props: any) {
    const root = React.useContext(RootContext);
    const [prize, setPrize] = useState<DashboardPrize>();
    const [team, setTeam] = useState<Team>();
    const [teams] = useState(root.authStore.orgMember.organization.groups);
    const [teamOptions, setTeamOptions] = useState<SelectModel[]>([]);
    const [auctions, setAuctions] = useState<DashboardAuction[]>([]);
    const [prizes, setPrizes] = useState<DashboardPrize[]>([]);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [prizeOptions, setPrizeOptions] = useState<SelectModel[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [hideForm, setHideForm] = useState<boolean>(true);
    
    useEffect(() => {
        startFetch();
        handleTeamOptions();
    }, []);


    const columns = [
        { dataField: 'name', text: 'Auction Name' },
        {
            dataField: 'prize.name', text: 'Prize',
            filterValue: (cell: any, row: any) => {
                return cell.prize.name;
            }
        },
        { dataField: 'numberOfBids', text: 'Bids Remaining' },

    ];

    const handleTeamOptions = () => {
        const { optionsArray } = sortOptions(teams as []);
        setTeamOptions(optionsArray);
    }

    const startFetch = async () => {
        let prizes: DashboardPrize[] = null!;
        let prizeOptionsArray: SelectModel[] = null!;
        try {
            let data = await fetchPrizes();
            prizes = data.data;
            prizeOptionsArray = data.optionsArray;
        } catch (error) {
            toast.error("We are unable to fetch your data at this moment. Please check your internet connection and try again.")
        }
        let { auctions } = await fetchOrgAuctions(root.authStore.orgMember.organization.id);
        setPrizes(prizes);
        let auctionList: DashboardAuction[] = [];
        auctions.forEach((auc: any) => {
            let auctionObj: DashboardAuction = extractAuctionData(auc);
            auctionList.push(auctionObj);
        });
        setAuctions(auctionList);
        setPrizeOptions(prizeOptionsArray);
    }

    const fetchAuctionsSeperate = async () => {
        let { auctions } = await fetchOrgAuctions(root.authStore.orgMember.organization.id);
        let auctionList: DashboardAuction[] = [];
        auctions.forEach((auc: any) => {
            let auctionObj: DashboardAuction = extractAuctionData(auc);
            auctionList.push(auctionObj);
        });
        setAuctions(auctionList);
    }

    const extractAuctionData = (auctionjson: any): DashboardAuction => {
        let dashprize: DashboardPrize = extractPrizeFromJson(auctionjson.prizes[0]);
        return {
            id: auctionjson.id, name: auctionjson.name, description: auctionjson.description,
            numberOfBids: auctionjson.number_of_bids, prize: dashprize
        }
    }

    const extractPrizeFromJson = (json: any): DashboardPrize => {
        return {
            id: json.id, name: json.name, value: json.value, cost_per_bid: json.cost_per_bid,
            max_number_of_bids: json.max_number_of_bids, specification: json.specification.specification_text,
            images: json.images
        }
    }


    const prizeSelection = (id: string) => {
        let val = handlePrizeSelect(parseInt(id), prizes);
        setPrize(val);
    }

    const addAuctionTrigger = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!validateForm())
            return;
        setBusy(true);
        let addauctionform: AddAuctionForm = {
            name: title,
            description,
            prizes: `${prize!.id}`,
            cost_per_bid: prize!.cost_per_bid,
            number_of_bids: prize!.max_number_of_bids
        };
        addAuctions(addauctionform);
        fetchAuctionsSeperate();
        setBusy(false);
        toast.success('Your auction has started');
        toggleForm();
    }

    const teamSelection = (id: string) => {
        let val = handleTeamSelect(parseInt(id), teams);
        setTeam(val);
    }

    const validateForm = () => {
        if(!validatePrize())
            return false;

        if(!validateTitle())
            return false;
        
        if(!validateDescription())
            return false;
        
        return true;
    }

    const validateTitle = () => {
        if(title.length === 0) {
            toast.info('Please enter a title for your auction');
            return false;
        }
        return true;
    }

    const validateDescription = () => {
        if(description.length === 0) {
            toast.info('Please enter a description for your auction')
            return false;
        }
        return true;
    }

    const validatePrize = () => {
        if(!prize) {
            toast.info('Please select a prize for your auction');
            return false;
        }
        return true;
    }


    const toggleForm = (e?: any) => {
        if(e)
            e.preventDefault();
        setHideForm(prevState => !prevState);
    }

    return (<React.Fragment>
        <div className="dashboard-popup-container" id="form-popup" hidden={hideForm}>
            <div className="popup-form">
                <form onSubmit={addAuctionTrigger}>
                    <div className="popup-heading">
                        <h2>Start An Auction</h2>
                        <button 
                            className="dashboard-button red-btn" 
                            onClick={toggleForm}
                            style={{padding: '5px', height: 'fit-content', borderRadius: '2px'}}
                        > <AiOutlineClose size={20}/>
                        </button>
                    </div>

                    <div className="shared-input">
                        <div className="form-input-box-2">
                            <label> Select the prize</label>
                            <Select options={prizeOptions}
                                onChange={(e) => prizeSelection(e!.value)}
                            />
                        </div>
                        <div className="form-input-box-2">
                            <label> Cost Per Bid (Please Note that this value is set by the Prize Selected)</label>
                            <input type="text" className="text-input-2" name="cost_per_bid"
                             placeholder="Cost Per Bid" defaultValue={prize?.cost_per_bid} disabled />
                        </div>
                    </div>
                    <div className="shared-input">
                        <div className="form-input-box-2">
                            <label> Number of Bids (Please Note that this value is set by the Prize Selected)</label>
                            <input id="firstname" type="text" className="text-input-2"
                                name="firstname" placeholder="Number of Bids" defaultValue={prize?.max_number_of_bids} disabled />
                        </div>
                        <div className="form-input-box-2">
                            <label> Select the Team</label>
                            <Select options={teamOptions} onChange={(e) => teamSelection(e!.value)}/>
                        </div>
                    </div>
                    <div className="form-input-box-2">
                        <label>Enter the title of the Auction</label>
                        <input type="text" className="text-input-2" name="full_address"
                            id="full_address" placeholder="Enter the title"
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-input-box-2">
                        <label>Enter the description of the Auction</label>
                        <textarea 
                            className="textarea"
                            placeholder="Enter description"
                            onChange={e => setDescription(e.target.value)}>
                        </textarea>
                    </div>
                    {busy ? (
                        <div>
                            <Ripple color="#ff3366"/>
                        </div>
                    ): (
                        <button type="submit" className="dashboard-button">
                            Start Auction
                        </button>
                    )}

                </form>
            </div>
        </div>
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <OrgMemberSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="flex settings-header">
                                <div>
                                    <h1>Auctions</h1>
                                </div>
                                <button className="dashboard-button" onClick={toggleForm}>Add Auction</button>
                            </div>

                            <div className="statement-history-2">
                                <div style={{width: '100%', height: '100%', overflow: 'scroll'}}>
                                    <CustomTable 
                                        data={auctions} 
                                        columns={columns} 
                                        pagination={true}
                                        headerClasses={"dashboard-table-header"}
                                        classes={"dashboard-table"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>

    );
}