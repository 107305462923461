import { useContext, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { AiOutlineSetting } from 'react-icons/ai';
import { RiDashboardLine, RiMessage3Line } from "react-icons/ri";
import { IoIosNotificationsOutline, IoIosLogOut } from "react-icons/io";
import { GiCash } from "react-icons/gi";
import { HiOutlineDocumentReport } from "react-icons/hi";

import "../dashboard_styles/sidebar.css";
import { RootContext } from '..';
import history from "../components/History";
import { loadMessages } from "../services/bidder";

interface SidebarProps {
    unreadMessages?: number
}

const Sidebar = observer((props: SidebarProps) => {
    const root = useContext(RootContext);
    const [unreadMessages, setUnreadMessages] = useState<number>(0);

    const handleLogout = () => {
        root.authStore.signOut();
        history.push('/');
    }

    const fetchMessages = useCallback(async () => {
        try {
            let { unread } = await loadMessages(root.authStore.user.id);
            setUnreadMessages(unread);
        } catch (error) {}
    }, [root.authStore.user.id])

    

    useEffect(() => {
        fetchMessages();
        
    }, [])

    return (
        <div className="sidebar">
            <nav>
                <Link to="/bidderdashboard" className="side-bar-nav-link"> <RiDashboardLine size={20}/> Dashboard </Link>
                <Link to="/bidderstatement" className="side-bar-nav-link"> <HiOutlineDocumentReport size={20}/> Customer Statement </Link>
                <Link to="/biddercredits" className="side-bar-nav-link"> <GiCash size={20}/>Buy Credits </Link>
                <Link to="/bidderinbox" className="side-bar-nav-link">
                    <RiMessage3Line size={20}/> Messages
                    {props.unreadMessages! > 0 && (
                        <span className="messages-bubble">{props.unreadMessages}</span>
                    )}
                    {!props.unreadMessages && unreadMessages > 0 && (
                        <span className="messages-bubble">{unreadMessages}</span>
                    )}
                </Link>
                <Link to="/biddernotification" className="side-bar-nav-link"> 
                    <IoIosNotificationsOutline size={20}/> Notifications
                    {root.authStore.user.unread_notifications.length > 0 && (
                        <span className="notifications-bubble">{root.authStore.user.unread_notifications.length}</span>
                    )}
                </Link>
                <Link to="/bidderdashboard_accountsettings" className="side-bar-nav-link">
                    <AiOutlineSetting size={20}/> Account Settings 
                </Link>
                <button className="side-bar-nav-link" onClick={handleLogout}>
                    <IoIosLogOut size={20}/> Logout 
                </button>
            </nav>
            <div className="sidebar-copyright">
                <p>Copyright © 2021 SchoolBidz</p>
                <p>All Rights Reserved</p>
            </div>
        </div>
    )
})

export default Sidebar;