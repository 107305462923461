import TermsAndConditions from "../components/Terms&Conditions";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const TermsPage = () => {
  return (
    <>
      <Nav />
      <div className="auction-header">
        <div className="auction-title-div">
          <h3>Terms & Conditions</h3>
        </div>
      </div>
      <div className="lg:px-[15%] sm:px-0 pb-20 pt-10">
        <TermsAndConditions hideHeader={true}/>
      </div>
      <Footer upper_div={false}/>
    </>
  )
}

export default TermsPage