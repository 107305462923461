import TermsAndConditions from "./Terms&Conditions";

interface AgreeModalProps {
  hidden: boolean,
  onAgreeClick: any,
  onCancelClick: any
}

const AgreeModal = (props: AgreeModalProps) => {
  return (
    <div 
      className="absolute top-0 bottom-0 left-0 right-0 z-10 flex flex-col items-center justify-center bg-black bg-opacity-30"
      hidden={props.hidden}
    >
      <div className="bg-white sm:w-[60%] w-[95%] h-[90%] rounded shadow-sm flex-col overflow-y-scroll">
        <TermsAndConditions hideHeader={false}/>
        <div className="flex flex-row items-center justify-end pb-4 pr-4 mt-4">
          <button className="font-bold text-[#ff3366] mr-6" onClick={props.onCancelClick}>
            Close
          </button>
          <button className="font-bold bg-[#96C864] py-2 px-4 rounded-lg text-white" onClick={props.onAgreeClick}>
            I Agree
          </button>
        </div>
      </div>
    </div>
  );
}

export default AgreeModal;