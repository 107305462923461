import "../styles/404.css";
import Nav from "../components/Nav";
import history from "../components/History";
import illustration from "../assets/images/404.png";
import Footer from "../components/Footer";

const NotFound = () => {
  const goHome = () => {
    history.push('/');
  }

  return(
    <>
      <Nav />
      <div className="not-found-container">
        <img src={illustration} alt="Not found" />
        <h2>Whoops! Lost in Space?</h2>
        <p>The link you followed is probably broken, or the page has been removed.</p>
        <button onClick={goHome}>Back To Home</button>
      </div> 
      <Footer upper_div={false}/>
    </>
  )
}

export default NotFound;