import React, { useCallback, useContext, useEffect, useState } from "react";
import Nav from "../../dashboard_components/Nav";
import OrgMemberSidebar from "../../dashboard_components/OrgMemberSidebar";
import "../../dashboard_styles/notifications.css";
import { observer } from "mobx-react-lite";
import { RootContext } from '../..';
import { formatNotificationDate } from "../../utils";
import Alert from '../../models/alert';
import { markAdminNotificationAsRead } from "../../services/dashboardorgnotifications";


const OrgMemberNotification = observer((props: any) => {
    const root = useContext(RootContext);
    const [activeNotification, setActiveNotification] = useState<string>('');
    const [activeNotificationText, setActiveNotificationText] = useState<string>('');
    const [selectValue, setSelectValue] = useState<string>('All');
    const [searchResults, setSearchResults] = useState<Alert[]>([]);

    const handleNotificationPress = (item: Alert) => {
        setActiveNotification(item.id);
        setActiveNotificationText(item.message);
        if (!item.read)
            markAsRead(item);
    }

    const markAsRead = async (item: Alert) => {
        try {
            await markAdminNotificationAsRead(item.id);
            root.authStore.refreshAdmin();
        } catch (error) { }
    }


    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectValue(event.target.value);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length === 0) {
            setSelectValue('All')
            return;
        }
        const database: Alert[] = [...root.authStore.orgMember.unread_notifications,
        ...root.authStore.orgMember.read_notifications];
        const query = event.target.value;
        let results = database.filter((alert: Alert) => alert.message.toLowerCase().includes(query));
        setSelectValue('None');
        setSearchResults(results);
    }

    const refreshAdmin = useCallback(() => {
        root.authStore.refreshAdmin();
    }, [root.authStore])

    useEffect(() => {
        refreshAdmin();
    }, [refreshAdmin])
    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <OrgMemberSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="settings-header">
                                <h1>Notificatons</h1>
                            </div>

                            <div className="notification-menu-bar">
                                <p>Filter:</p>
                                <div className="filter-dropdown">
                                    <select
                                        name="filter-select"
                                        value={selectValue}
                                        onChange={handleSelectChange}
                                    >
                                        <option value="All"> All </option>
                                        <option value="Unread"> Unread </option>
                                        <option value="Read"> Read </option>
                                    </select>
                                </div>
                                {/* <div className="date-search">
                                    <input type="text" className="before-date" placeholder="27/08/21" />
                                to
                                <input type="text" className="after-date" placeholder="27/08/21" />

                                    <button>
                                        <BiSearch color="white" />
                                    </button>
                                </div> */}
                                <div className="filter-search-div">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        onChange={handleSearch}
                                    />
                                </div>

                            </div>
                            <div className="notifications-container">
                                <div className="notification-list-items">
                                    {(selectValue === 'All' || selectValue === 'Unread') && root.authStore.orgMember.unread_notifications.map((item, index) => (
                                        <button
                                            className={`list-item unread ${activeNotification === item.id && 'active'}`}
                                            key={index}
                                            onClick={() => handleNotificationPress(item)}
                                        >
                                            <div className="compact-list-item">
                                                <div className="date-con">{formatNotificationDate(item.date)}</div>
                                                <p className="text-con">{item.message}</p>
                                            </div>
                                            <div className={`full-text-con`}>
                                                <p>{item.message}</p>
                                            </div>
                                        </button>
                                    ))}
                                    {(selectValue === 'All' || selectValue === 'Read') && root.authStore.orgMember.read_notifications.map((item, index) => (
                                        <button
                                            className={`list-item ${activeNotification === item.id && 'active'}`}
                                            key={index}
                                            onClick={() => handleNotificationPress(item)}
                                        >
                                            <div className="compact-list-item">
                                                <div className="date-con">{formatNotificationDate(item.date)}</div>
                                                <p className="text-con">{item.message}</p>
                                            </div>
                                            <div className={`full-text-con`}>
                                                <p>{item.message}</p>
                                            </div>
                                        </button>
                                    ))}
                                    {selectValue === 'None' && searchResults.map((item, index) => (
                                        <button
                                            className={`list-item ${activeNotification === item.id && 'active'}`}
                                            key={index}
                                            onClick={() => handleNotificationPress(item)}
                                        >
                                            <div className="compact-list-item">
                                                <div className="date-con">{formatNotificationDate(item.date)}</div>
                                                <p className="text-con">{item.message}</p>
                                            </div>
                                            <div className={`full-text-con`}>
                                                <p>{item.message}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <div className="notification-message">
                                    {activeNotificationText}                            </div>
                            </div>
                        </div>
                        <div className="dashboard-copyright-div">
                            <p>COPYRIGHT © 2021 SCHOOLBIDZ  |  ALL RIGHTS RESERVED</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
})

export default OrgMemberNotification;