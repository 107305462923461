import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { MdExpandMore } from "react-icons/md";

import Nav from "../components/Nav";
import Footer from "../components/Footer";
import HelmetData from "../components/Helmet";

const questions = [
  {
    question: "What is a unique bid?",
    answer: "A unique bid is a bid that has not been placed or repeated."
  },
  {
    question: "How will I be notified if I win?",
    answer: "At the end of every auction School Bidz will issue a Transparency Report that will list " +
     "every bid placed, the date and time of the bid and highlight the lowest unique bid."
  },
  {
    question: "Will I be notified if I don’t win?",
    answer: "At the end of every auction School Bidz will issue a Transparency Report that will list " +
      "every bid placed, the date and time of the bid and highlight the lowest unique bid."
  },
  {
    question: "What are bid credits?",
    answer: "At this time 1 bid costs $10 which is equal to 1 Bid Credit. Bidders are able to buy" +
     " Bid Credits so that their bidding process is not interrupted by the credit card form " +
     "every time they place a bid."
  },
  {
    question: "How do I purchase a credit?",
    answer: "To purchase Bid Credits you simply go to your user menu in the top right corner " + 
     "below you user name and select the menu item that is labeled Buy Credits. Follow " +
     "the screen prompts from there."
  },
  {
    question: "Is there a refund for bid credit?",
    answer: "Once a bid has been placed there is no opportunity for refunds."
  },
  {
    question: "How will I receive my gift if I win?",
    answer: "All products are ordered and shipped through third party vendors such as Best Buy " +
     "or Amazon. Receipt of delivery is dependent upon availability."
  },
  {
    question: "How does the nonprofit org make money from the bids?",
    answer: "The participating non-profit will collect 50% of every auction they close within 24hrs " +
      "and 40% of every auction closed after 24hrs"
  },
  {
    question: "How can my org use School Bidz for our next fundraising event?",
    answer: "Organizations simply need to sign up as an organization on www.schoolbidz.com " +
      "and select the Organization tab during sign up, Once the sign up form is completed and the " +
      "proper EIN forms have been uploaded and confirmed by School Bidz, the organization " +
      "is ready to select an auction to promote."
  }
]

const FAQ = () => {
  return (
    <>
      <Nav />
      <HelmetData 
        title={'FAQs | School Bidz'}
        description={'Frequently Asked Questions'}
      />
      <div className="organization-header">
        <div className="organizations-title-div">
          <h2 className="sm:text-[30px]">Frequently Asked Questions</h2>
        </div>
      </div>
      <div className="sm:w-[80%] m-auto py-20 w-[95%]">
        {questions.map((item, index) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls={`"panel${index}bh-content"`}
              id={`panel${index}bh-header`}
            >
              <p className="font-bold text-[#3f22b3] kidsRock">
                {item.question}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Footer upper_div={false}/>
    </>
  )
}

export default FAQ;
