import React, { useContext, createRef, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { BsPencil } from "react-icons/bs";
import Cookies from 'js-cookie'
import { toast } from "react-toastify";

import "../../dashboard_styles/dashboard.css";
import "../../dashboard_styles/account_settings.css";
import Nav from '../../dashboard_components/Nav';
import Sidebar from '../../dashboard_components/Sidebar';
import { RootContext } from '../..';
import Ripple from "../../components/Ripple";
import Crop from "../../dashboard_components/Crop";
import DeleteModal from '../../components/DeleteModal';
import history from "../../components/History";

import { updateAvatar, updateShipping, deleteUser } from "../../services/auth";

const { REACT_APP_SERVER } = process.env;

const BidderAccountSettings = observer(() => {
    const root = useContext(RootContext);
    const fileInput = createRef<HTMLInputElement>();
    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [file, setFile] = useState<any>(null);
    const [firstName, setFirstName] = useState<string>(root.authStore.user.shippingInfo.firstName);
    const [lastName, setLastName] = useState<string>(root.authStore.user.shippingInfo.lastName);
    const [address, setAddress] = useState<string>(root.authStore.user.shippingInfo.address);
    const [city, setCity] = useState<string>(root.authStore.user.shippingInfo.city);
    const [state, setState] = useState<string>(root.authStore.user.shippingInfo.state);
    const [zip, setZip] = useState<string>(root.authStore.user.shippingInfo.zip);
    const [notes, setNotes] = useState<string>(root.authStore.user.shippingInfo.notes);
    const [edited, setEdited] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(false);
    const [showCrop, setShowCrop] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [cropImage, setCropImage] = useState<string>(''); // image to be cropped

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files: FileList = e.currentTarget.files!;
        setShowCrop(true);
        setCropImage(URL.createObjectURL(files[0]));
    }

    const hideCrop = () => {
        setShowCrop(false);
    }

    const saveCroppedImage = async(canvas: HTMLCanvasElement) => {
        canvas.toBlob(file => {
            setNewAvatar(URL.createObjectURL(file));
            handleFile(file!);
        }, 'image/jpeg')
    }

    const handleFile = (file: Blob) => {
        if(validateFile(file)) {
          let reader = new FileReader();
          reader.onload = e => { setFile(e.target?.result) }
          reader.readAsDataURL(file)
        }
    } 

    const validateFile = (file: Blob): boolean => {
        if(file.type === 'application/pdf') {
          return false;
        }
        else if(file.size > 100000000) {
          return false;
        }
        return true;
      }

    const handleUploadPress = (e: React.MouseEvent) => {
        e.preventDefault();
        fileInput.current?.click();
    }

    const generateAccountForm = () => (
        {
            profile_image1: file,
            userId: root.authStore.user.id,
            reason: "update_user_details"
        }
    )

    const generateShippingForm = () => (
        {
            first_name: firstName,
            last_name: lastName,
            state: state,
            city: city,
            zip_code: zip,
            full_address: address,
            additional_notes: notes
        }
    )

    const validateForm = () => {
        if(!validateAddress())
            return false;
        if(!validateName())
            return false;
        if(!validateCity())
            return false;
        if(!validateState())
            return false;
        if(!validateZip())
            return false;
        return true;
    } 

    const validateName = () => {
        if(firstName.length === 0) {
            toast.info('Please enter your first name');
            return false;
        }

        if(lastName.length === 0) {
            toast.info('Please enter your last name');
            return false;
        }
        return true;
    }

    const validateAddress = () => {
        if(address.length === 0) {
            toast.info('Please enter your address');
            return false
        }
        return true;
    }

    const validateState = () => {
        if(state.length === 0) {
            toast.info("Please enter your state");
            return false;
        }
        return true;
    }

    const validateCity = () => {
        if(city.length === 0) {
            toast.info('Please enter your city');
            return false;
        }
        return true;
    }

    const validateZip = () => {
        if(zip.length === 0) {
            toast.info("Please enter your  zip code");
            return false;
        }

        return true;
    }

    const handleSubmit = async() => {
        if(!edited)
            return;
        setBusy(true);
        const form = generateAccountForm();
        const shippingForm = generateShippingForm();
        const token = Cookies.get("customerToken");

        try {
            if(file)
                await updateAvatar(token, form).then(() => {
                    toast.success('Your profile photo has been updated');
                });
            if(validateForm())    
                await updateShipping(token, shippingForm, root.authStore.user.id).then(() => {
                    toast.success("Your shipping information has been updated");
                });
            root.authStore.refreshUser();
            setBusy(false);                
        } catch (error) {
            setBusy(false);
            toast.error("An error occurred while updating your account. Please try again later");
        }
    }

    const deleteAccount = async() => {
        const token = Cookies.get("customerToken");
        try {
            setBusy(true)
            await deleteUser(root.authStore.user.id, token)
                .then(() => {
                    history.push('/');
                    root.authStore.signOut();
                });
        } catch (error) {
            setBusy(false);
            toast.error("An error occurred while attempting to delete your account. Please try again later")
        }
    }

    

    useEffect(() => {
        setEdited(true);
    }, [firstName, lastName, address, city, state, zip, notes, file])
    
    return (
        <div className="page-container">
            <Nav />
            <Crop 
                hidden={showCrop} 
                hideCrop={hideCrop} 
                imageSrc={cropImage}
                saveCroppedImage={saveCroppedImage}
            />
            
            <div className="page-content-box">
                <Sidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="settings-header">
                                <h1>Account Settings</h1>
                                <p> Come here to set your shipping address as well as your profile picture</p>
                            </div>

                            <div className="settings-boxes">
                                <div className="form">
                                    <div className="setting-box-1">
                                        <div className="settings-user-profile" style={{background: 'linear-gradient(to right, #ffab28, #ffc728)'}}>
                                            {!file && root.authStore.user.image_url.substring(0, 6) === 'avatar' && (
                                                <p>{root.authStore.user.username.substring(0, 1)}</p>
                                            )}
                                            {!file && root.authStore.user.image_url !== 'avatar_01.jpg' && 
                                                <img src={`${REACT_APP_SERVER}/images/profileimages/im${root.authStore.user.image_url}`} alt="avatar" />
                                            }
                                            {file && <img src={newAvatar} alt="avatar" />}
                                            <button 
                                                className="upload-btn"
                                                onClick={handleUploadPress}
                                            >
                                                <BsPencil size={20} />
                                            </button>
                                            <input 
                                                type="file" 
                                                hidden 
                                                onChange={handleFileSelect} 
                                                ref={fileInput}
                                            />
                                        </div>
                                        <div className="form-input-box">
                                            <input 
                                                type="text" 
                                                className="text-input username" 
                                                name="username"
                                                defaultValue={root.authStore.user.username}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="form-input-box">
                                            <input 
                                                type="text" 
                                                className="text-input" 
                                                name="email"
                                                defaultValue={root.authStore.user.email}
                                                disabled={true}
                                            />
                                        </div>
                                        <button 
                                            className="delete-btn" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            Delete Account
                                        </button>
                                    </div>
                                    <div className="setting-box-2">
                                        <h2>Shipping Information</h2>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input 
                                                    id="firstname" 
                                                    type="text" 
                                                    className="text-input-2" 
                                                    name="firstname" 
                                                    placeholder="First Name" 
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.currentTarget.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div">
                                                <input 
                                                    type="text" 
                                                    className="text-input-2" 
                                                    name="lastname"
                                                    id="lastname" 
                                                    placeholder="Last Name" 
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.currentTarget.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-input-div">
                                            <textarea 
                                                name="full_address"
                                                id="full_address" 
                                                placeholder="Enter your full address"
                                                rows={5} 
                                                value={address}
                                                onChange={(e) => setAddress(e.currentTarget.value)}
                                            />
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input 
                                                    type="text" 
                                                    className="text-input-2" 
                                                    name="city"
                                                    id="city" 
                                                    placeholder="City" 
                                                    value={city}
                                                    onChange={(e) => setCity(e.currentTarget.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div">
                                                <input 
                                                    type="text" 
                                                    className="text-input-2" 
                                                    name="state"
                                                    id="state" 
                                                    placeholder="State" 
                                                    value={state}
                                                    onChange={(e) => setState(e.currentTarget.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input 
                                                    type="text" 
                                                    className="text-input-2" 
                                                    name="zipcode"
                                                    id="zip_code" 
                                                    placeholder="Zip Code" 
                                                    value={zip}
                                                    onChange={(e) => setZip(e.currentTarget.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-input-div">
                                            <textarea 
                                                name="additional_notes"
                                                id="additional_notes" 
                                                placeholder="Enter additional notes here..."
                                                rows={7}
                                                value={notes}
                                                onChange={(e) => setNotes(e.currentTarget.value)}
                                            />
                                        </div>
                                        {busy ? (
                                            <div className="loader">
                                                <Ripple color="#ff3366" />
                                            </div> 
                                        ) : (
                                            <input 
                                                type="submit" 
                                                className="submit-btn" 
                                                value="Save Changes" 
                                                onClick={handleSubmit}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                deleteAccount={deleteAccount}
                busy={busy}
            />
        </div>
    )
})

export default BidderAccountSettings;