import Cookies from "js-cookie";
import SelectModel from "../models/select";
import DashboardPrize from "../models/dashboardprize";
import AddAuctionForm from "../models/add-auction-form";
import Team from "../models/team";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env


let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("adminToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
      "accept": "Application/json",
      "Authorization": `Bearer ${token}`
    }
  })
}

const fetchPrizes = async() => {
  setAxiosInstance();
  try {
    let { data } = await axiosInstance.get('/api/prizes');
    return sortOptions(data);
  } catch (error) {
    throw error;
  }
}

const fetchAdminAuctionInfo = async(admin_id: number) => {
  setAxiosInstance();
  try {
    let {data} = await axiosInstance.get(`api/data/adminauctiondata/${admin_id}`)
    return data;
  } catch (error) {
    throw error;
  }
}

const fetchOrgAuctions = async(id: number) => {
  setAxiosInstance();
  try {
    let {data} = await axiosInstance.get(`/api/organisations/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

const addAuctions = async(form: AddAuctionForm) => {
  setAxiosInstance();
  try {
    await axiosInstance.post(`/api/auctions`, form)
  } catch (error) {
    throw error;
  }
}


const handlePrizeSelect = (id: number, prizes: DashboardPrize[]): DashboardPrize => {
  return prizes.find(prize => prize.id === id )!;
} 

const handleTeamSelect = (id: number, teams: Team[]) => {
  return teams.find(team => team.id = id);
}

const sortOptions = (data: []) => {
  let optionsArray: SelectModel[] = [];
  data.forEach((element: any) => {
    let prizeOption: SelectModel = {
        value: `${element.id}`, label: element.name
    }
    optionsArray.push(prizeOption);
  });
  return { data, optionsArray};
}

const auctionStatementColumns = [
  { dataField: 'id', text: 'ID' },
  { dataField: 'name', text: 'Auction Name'},
  { dataField: 'created_at', text: 'Start Date' },
  {
    dataField: 'prize',
    text: 'Prize',
    flex: 1,
    filterValue: (cell: any, row: any) => {
      return cell.prizes[0].name
    }        
  },
  {
    dataField: 'bids_placed',
    text: 'Bids Placed',
  },
  {
    dataField: 'number_of_bids',
    text: 'Bids Remaining',
  },
  {
    dataField: 'status',
    text: 'Status',
  },
  {
    dataField: 'lowest_unique_bidder',
    text: 'Lowest Unique Bidder',
    flex: 1,
    filterValue: (cell: any, row: any) => {
      return cell.lub.name
    }
  },
  {
    dataField: 'amount_raised',
    text: 'Amount Raised',
  },
];

export {
  fetchPrizes, 
  fetchAdminAuctionInfo, 
  auctionStatementColumns, 
  handlePrizeSelect, 
  addAuctions, 
  fetchOrgAuctions,
  handleTeamSelect,
  sortOptions
};