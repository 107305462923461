import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { MdExpandMore } from "react-icons/md";

import Nav from '../components/Nav';
import Footer from '../components/Footer';
import HelmetData from '../components/Helmet';

import "../styles/HowToBid.css";
import signUpImg from "../assets/images/signUp.png";
import bidImg from "../assets/images/bid.png";
import winImg from "../assets/images/win.png";
import { RootContext } from '../index';


const HowToBid = observer(() => {
    const root = useContext(RootContext);
    const [expanded, setExpanded] = useState<boolean | string>(false);

    const handleChange = (_: React.ChangeEvent<{}>, isExpanded:  boolean, panel: string) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <>
            <Nav />
            <HelmetData title="How School Bidz Works"/>
            <div className="how-to-bid">
                <div className="htb-header">  </div>
                <div className="htb-content">
                    <div className="htb-content-child">
                        <div className="video-container">
                            <iframe 
                                width="100%" 
                                height="515" 
                                src={`https://www.youtube.com/embed/videoseries?list=PL10ykqLV9pOJM-aRrNJTqGhnXR3o95mK5${!root.authStore.isSignedIn ? "&autoplay=1" : ""}`}
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                        <div className="help-div">
                            <h2>How</h2>
                            <h2>School Bidz works?</h2>
                            <p>Click the link to see simple and detailed videos on how School Bidz works.</p>
                            <div className="help-pictures">
                                <div className="help-pictures-row">
                                    <div className="help-pictures-item">
                                        <img src={signUpImg} alt="sign up" />
                                        <h4>
                                            Sign Up
                                        </h4>
                                        <p className="w-[196px] h-[63px]">Start a user profile. </p>
                                    </div>
                                    <div className="help-pictures-item">
                                        <img src={bidImg} alt="bid" />
                                        <h4>
                                            Bid
                                        </h4>
                                        <p className="w-[196px]">Place the lowest unique bid in the auction that you desire to support.</p>
                                    </div>
                                    <div className="help-pictures-item">
                                        <img src={winImg} alt="win" />
                                        <h4>
                                            Win
                                        </h4>
                                        <p className="w-[196px]">Once you win the auction, School Bidz will ship the item directly to you.</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="mt-20 mb-10">
                                <h3 className="text-[24px] text-center kidsRock m-auto pb-10 text-[#3f22b3]">Frequently Asked Questions</h3>
                                {questions.map((item, index) => (
                                    <Accordion>
                                        <AccordionSummary
                                        expandIcon={<MdExpandMore />}
                                        aria-controls={`"panel${index}bh-content"`}
                                        id={`panel${index}bh-header`}
                                        >
                                        <p className="font-bold text-[#3f22b3] kidsRock text-left">
                                            {item.question}
                                        </p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography>
                                            {item.answer}
                                        </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                        <div className="help-div-desktop" style={{marginBottom: '0'}}>
                            <h2>How School Bidz Works?</h2>
                            <div className="help-pictures">
                                <div className="help-pictures-row">
                                    <div className="help-pictures-item">
                                        <img src={signUpImg} alt="sign up" />
                                        <h4>
                                            Sign Up
                                        </h4>
                                        <p style={{ "width": "60%" }}>Start a user profile.</p>
                                    </div>
                                    <div className="help-pictures-item">
                                        <img src={bidImg} alt="bid" />
                                        <h4>
                                            Bid
                                        </h4>
                                        <p style={{ "width": "60%" }}>Place the lowest unique bid in the auction that you desire to support.</p>
                                    </div>
                                    <div className="help-pictures-item">
                                        <img src={winImg} alt="win" />
                                        <h4>
                                            Win
                                        </h4>
                                        <p style={{ "width": "60%" }}>Once you win the auction, School Bidz will ship the item directly to you.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-20 mb-10">
                                <h3 className="sm:text-[24px] text-center kidsRock m-auto pb-10 text-[#3f22b3]">Frequently Asked Questions</h3>
                                {questions.map((item, index) => (
                                    <Accordion
                                        onChange={(e: React.ChangeEvent<{}>, expanded: boolean) => handleChange(e, expanded, `panel${index + 1}`)}
                                        expanded={expanded === `panel${index + 1}`}
                                    >
                                        <AccordionSummary
                                        expandIcon={<MdExpandMore />}
                                        aria-controls={`"panel${index}bh-content"`}
                                        id={`panel${index}bh-header`}
                                        >
                                        <p className="font-bold text-[#3f22b3] kidsRock text-left">
                                            {item.question}
                                        </p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <p className="text-left">
                                            {item.answer}
                                        </p>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer upper_div={false} />
            </div>

        </>
    );
});

export default HowToBid;


const questions = [
    {
      question: "What is a unique bid?",
      answer: "A unique bid is a bid that has not been placed or repeated."
    },
    {
      question: "How will I be notified if I win?",
      answer: "At the end of every auction School Bidz will issue a Transparency Report that will list " +
       "every bid placed, the date and time of the bid and highlight the lowest unique bid."
    },
    {
      question: "Will I be notified if I don’t win?",
      answer: "At the end of every auction School Bidz will issue a Transparency Report that will list " +
        "every bid placed, the date and time of the bid and highlight the lowest unique bid."
    },
    {
      question: "What are bid credits?",
      answer: "At this time 1 bid costs $10 which is equal to 1 Bid Credit. Bidders are able to buy" +
       " Bid Credits so that their bidding process is not interrupted by the credit card form " +
       "every time they place a bid."
    },
    {
      question: "How do I purchase a credit?",
      answer: "To purchase Bid Credits you simply go to your user menu in the top right corner " + 
       "below you user name and select the menu item that is labeled Buy Credits. Follow " +
       "the screen prompts from there."
    },
    {
      question: "Is there a refund for bid credit?",
      answer: "Once a bid has been placed there is no opportunity for refunds."
    },
    {
      question: "How will I receive my gift if I win?",
      answer: "All products are ordered and shipped through third party vendors such as Best Buy " +
       "or Amazon. Receipt of delivery is dependent upon availability."
    },
    {
      question: "How does the nonprofit org make money from the bids?",
      answer: "The participating non-profit will collect 50% of every auction they close within 24hrs " +
        "and 40% of every auction closed after 24hrs"
    },
    {
      question: "How can my org use School Bidz for our next fundraising event?",
      answer: "Organizations simply need to sign up as an organization on www.schoolbidz.com " +
        "and select the Organization tab during sign up, Once the sign up form is completed and the " +
        "proper EIN forms have been uploaded and confirmed by School Bidz, the organization " +
        "is ready to select an auction to promote."
    }
  ]