import Cookies from "js-cookie";
const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("customerToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
        "Authorization": `Bearer ${token}`
    }
  })
}

const fetchAuctions = async() => {
  setAxiosInstance();
  try {
    return await axiosInstance.get('/api/sort_auctions')
  } catch (error) {
    throw error;
  }
}

const getAuction = async(id: string) => {
  setAxiosInstance();
  try {
    return await axiosInstance.get(`/api/auctions/${id}`)
  } catch (error) {
    throw error;
  }
}

const getLowestUniqueBidder = async(auctionId: number) => {
  setAxiosInstance();
  try {
    return await axiosInstance.get(`/api/getlowestuniquebidder/${auctionId}`)
  } catch (error) {
    throw error;
  }
}

const getBidResponses = async() => {
  setAxiosInstance();
  try {
    return  await axiosInstance.get('/api/get_bid_responses')
  } catch (error) {
    throw error
  }
}

const bid = async(auctionId: number, bidAmount: string) => {
  setAxiosInstance();
  const data = {
    amount: bidAmount,
    auction_id: auctionId
  }
  try {
    return await axiosInstance.post(`/api/bids`, data);
  } catch (error) {
    throw error;
  }
}

export {
  fetchAuctions,
  getAuction,
  getLowestUniqueBidder,
  getBidResponses,
  bid
}