import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReCaptcha from "react-google-recaptcha";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import "../styles/Login.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Ripple from "../components/Ripple";
import img from "../assets/images/login-img.png";
import LoginForm from "../models/login-form";
import { RootContext } from "../index";
import history from "../components/History";
import useQuery from '../components/useQuery';
import { validateRecaptcha } from '../services/auth';
import HelmetData from '../components/Helmet';

const { REACT_APP_RECAPTCHA } = process.env;

const Login = observer(() => {
  let query = useQuery();
  const [bidderLogin, setBidderLogin] = useState<boolean>(true);
  const [orgLogin, setOrgLogin] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const root = useContext(RootContext);

  useEffect(() => {
    if (bidderLogin)
      setOrgLogin(false);
  }, [bidderLogin])

  useEffect(() => {
    if (orgLogin)
      setBidderLogin(false);
  }, [orgLogin])

  const handleParams = () => {
    const status = query.get('status');
    if(status === 'email-verified')
      toast.success("Your email has been verified");
  }

  useEffect(() => {
    handleParams();
    windowWidthListener();
    return function cleanup() {
      window.removeEventListener('resize', () => {
        setWindowWidth(window.innerWidth)
      })
    }
  }, [])

  const windowWidthListener = () => {
    window.addEventListener('resize', () => { 
      setWindowWidth(window.innerWidth)
    })
  }

  const generateForm = (): LoginForm => {
    let form: LoginForm = {
      email: email,
      password: password,
      role: ""
    }

    if (bidderLogin)
      form.role = "customer"
    else
      form.role = "organisation_admin"

    return form;
  }

  const handleSubmit = async () => {
    if (!validateForm())
      return;

    const form = generateForm();
    await root.authStore.signIn(form)
      .then((_) => history.push('/'))
      .catch((error) => {
        window.scrollTo(0, 0);
        console.log(error)
        toast.error(error.response.data.error);
      })
  }

  const handleRecaptchaEvent: Function = (event: string): void => {
    const response = validateRecaptcha(event);
    if(response)
      setDisabled(false)
    else
      setDisabled(true);
  }

  const validateEmail = (): boolean => {
    if (email.length === 0) {
      toast.info("Please enter your email");
      return false;
    }
    return true;
  }

  const validatePassword = (): boolean => {
    if (password.length === 0) {
      toast.info("Please enter a password");
      return false;
    }
    return true;
  }

  const validateForm = (): boolean => {
    if (!validateEmail())
      return false

    if (!validatePassword())
      return false;

    return true;
  }

  return (
    <>
      <Nav />
      <HelmetData 
        title="Login to School Bidz"
        description="They need your help. Fundraising for schools"
      />
      <div className='login-header'>
        <div className="login-title-div">
          <h3>They Need Your Help!</h3>
          <h3>Fundraising For Schools</h3>
        </div>
      </div>
      <div className="form-div">
        <div className="form-container">
          <img src={img} alt="smiling girl with backpack" />
          <div className="input-div">
            <div className="input-tabs">
              <button
                className={`input-tab ${bidderLogin && 'active-tab'}`}
                onClick={() => setBidderLogin(true)}
              >
                <p>Bidder</p>
                <span></span>
              </button>
              <button
                className={`input-tab ${orgLogin && 'active-tab'}`}
                onClick={() => setOrgLogin(true)}
              >
                <p>Organization</p>
                <span></span>
              </button>
            </div>
            <div className="input-group">
              <p>Email</p>
              <input
                placeholder="someone@example.com"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            <div className="input-group">
              <p>Password</p>
              <input
                placeholder="*******************"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type="password"
              />
            </div>
            <div className="forgot-div">
              <p>Forget your password?</p>
              <Link to='/resetPasswordEmail'><span>Click Here!</span></Link>
            </div>
            {disabled && (
              <ReCaptcha
                sitekey={REACT_APP_RECAPTCHA!}
                onChange={(e) => handleRecaptchaEvent(e)}
                size={windowWidth > 400 ? 'normal' :  'compact'}
              />
            )}
            {root.authStore.busy ?
              (
                <div className="loader">
                  <Ripple color="#ff3366" />
                </div>
              ) : (
                <button
                  className="form-action"
                  onClick={handleSubmit}
                  disabled={disabled}
                  style={{ backgroundColor: disabled ? "#616161" : "" }}
                >
                  LOGIN
                </button>
              )}
            <p className="sign-up-sentence">Don't have an account? <Link to="/signup"><span>SIGN UP</span></Link></p>
          </div>
        </div>
      </div>
      <Footer upper_div={false} />
    </>
  )
})

export default Login;