import React, { useContext, useState, useRef, createRef } from "react";
import { AiOutlineMenu, AiOutlineInfoCircle, AiOutlineSetting } from "react-icons/ai";
import { ImKey } from "react-icons/im";
import { FiEdit } from "react-icons/fi";
import { FaCaretDown } from "react-icons/fa";
import { RiDashboardLine, RiMessage3Line, RiAuctionLine } from "react-icons/ri";
import { IoIosNotificationsOutline, IoIosLogOut, IoMdClose } from "react-icons/io";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GiCash } from "react-icons/gi";
import { VscOrganization } from "react-icons/vsc";
import { SiStorybook } from "react-icons/si"
import { observer } from "mobx-react-lite";
import { BsPeople, BsSearch } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";

import "../styles/nav.css";
import logo from "../assets/images/logo-horizontal.png";
import { RootContext } from "../index";
import RootStore from "../stores/root";
import history from "./History";
import SearchResults from "./SearchResults";
import Auction from "../models/auction";
import Organization from "../models/organization";

const Nav = observer(() => {
  const root = useContext(RootContext);
  const [location] = useState(useLocation());
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [auctionResults, setAuctionResults] = useState<Auction[]>([]);
  const [orgResults, setOrgResults] = useState<Organization[]>([]);
  const [query, setQuery] = useState<string>('');
  const mobileMenuRef = useRef<any>();
  const searchRef = createRef<HTMLDivElement>();

  const handleSearchPress = () => {
    if (showSearch) { }
    else {
      setShowSearch(true);
    }
  }

  const toggleMenu = () => {
    const mobileMenuNode = mobileMenuRef.current;

    if (menuVisible) {
      enableScroll()
      mobileMenuNode.classList.remove("slide-into")
      mobileMenuNode.classList.add("slide-out")
    }
    else {
      disableScroll()
      mobileMenuNode.classList.remove("slide-out");
      mobileMenuNode.classList.add("slide-into")
    }
    setMenuVisible(!menuVisible);
  }

  const disableScroll = () => {
    const body = document.body;
    body.classList.add('no-scroll')
  }

  const enableScroll = () => {
    const body = document.body;
    body.classList.remove('no-scroll');
  }

  const goHome = () => {
    history.push('/');
    enableScroll();
  }

  const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    if(e.target.value.length === 0) {
      setShowResults(false);
      return;
    }
    if(e.target.value.length === 1) {
      setShowResults(true);
      searchRef.current?.focus();
    }
      
    searchAuctions();
    searchOrganisations();
  }

  const searchAuctions = () => {
    const quarry = root.auctionStore.auctions.filter(
      (auction) => auction.name.toLowerCase().includes(query.toLowerCase()) 
        || auction.prize.name.toLowerCase().includes(query.toLowerCase()));
    setAuctionResults(quarry);
  }
  
  const searchOrganisations = () => {
    const quarry = root.organizationStore.organizations.filter(
      (org) => org.name.toLowerCase().includes(query.toLowerCase()));
    setOrgResults(quarry);
  }

  const closeSearchDialog = () => {
    setShowResults(false);
    setQuery('');
  }

  return (
    <div className="header-container">
      {!root.authStore.admin && !root.authStore.orgMember && !root.authStore.user.id && !root.authStore.busy && (
        <div className="auth-div">
          <Link to='/login'>
            <button className="auth-item">
              <ImKey color={"#33cc00"} size={15} />
              <p>LOGIN</p>
            </button>
          </Link>
          <Link to='/signup'>
            <button className="auth-item">
              <FiEdit color={"#33cc00"} size={15} />
              <p>SIGN UP</p>
            </button>
          </Link>
        </div>
      )}
      {root.authStore.isSignedIn && !root.authStore.isAdmin && root.authStore.role === "customer" && !root.authStore.busy && (
        <UserNav root={root} />
      )}
      {root.authStore.isSignedIn && root.authStore.isAdmin && root.authStore.role === "organisation_admin" && !root.authStore.busy && (
        <AdminNav root={root} />
      )}
      {root.authStore.isSignedIn && root.authStore.isAdmin && root.authStore.role === "organisation_member" && !root.authStore.busy && (
        <OrgMemberNav root={root} />
      )}
      <header className="nav-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <nav>
          <Link to="/auctions" className={location.pathname === '/auctions' ? 'selected nav-link' : 'nav-link'}> Auctions </Link>
          <Link to="/how_to_bid" className={location.pathname === '/how_to_bid' ? 'selected nav-link' : 'nav-link'}> How to Bid</Link>
          <Link to="/organizations" className={location.pathname === '/organizations' ? 'selected nav-link' : 'nav-link'}> Organizations</Link>
          <Link to="/stories" className={location.pathname === '/stories' ? 'selected nav-link' : 'nav-link'}> Success Stories</Link>
        </nav>
        <div className="search-box">
          <input 
            type="text" 
            placeholder="Search..." 
            value={query} 
            onChange={handleQuery}
          />
          <button>
            <BsSearch size={20} color="gray" />
          </button>
        </div>
      </header>
      <header className="mobile-nav-container">
        <button onClick={toggleMenu}>
          {menuVisible ? <IoMdClose color={"#fff"} size={30} /> : <AiOutlineMenu color={"#fff"} size={30} />}
        </button>
        {showSearch ? (
          <input
            className="mobile-search"
            value={query}
            onChange={handleQuery}
            onBlur={() => setShowSearch(false)}
            placeholder="Search auctions, organizations"
            autoFocus
          />
        ) : (
          <img src={logo} alt="Logo" onClick={goHome} />
        )}
        <button onClick={handleSearchPress}>
          <BsSearch color={"#fff"} size={25} />
        </button>
      </header>
      <MobileMenu 
        root={root} 
        visible={menuVisible} 
        location={location} 
        reference={mobileMenuRef} 
      />
      {showResults && (
        <SearchResults 
          auctions={auctionResults}
          organizations={orgResults}
          query={query}
          closeDialog={closeSearchDialog}
          ref={searchRef}
        />
      )}
    </div>
  );
})

interface MenuItem {
  label: string,
  icon: React.ReactElement,
  dest: string
}

const UserNav = observer((props: { root: RootStore }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const userMenu = createRef<HTMLDivElement>();
  const handleItemPress = (item: MenuItem) => {
    if (item.dest === "/logout") {
      props.root.authStore.signOut();
      history.push('/');
      return;
    }

    history.push(item.dest);
  }

  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
    userMenu.current?.focus();
  }

  const menuVisibleStyle = {
    display: 'block',
    opacity: '1',
    top: '60px'
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if(!e.currentTarget.contains(e.relatedTarget as Node))
      setShowMenu(false);
  }

  return (
    <div className="user-nav-container">
      <div className="username-div" onClick={toggleMenu}>
        <p className="username">{props.root.authStore.user.username}</p>
        <div>
          <p className="credits">{props.root.authStore.user.credits}</p>
          <p className="credits-label">credits</p>
        </div>
        <div 
          className="user-menu" 
          style={showMenu ? menuVisibleStyle : {}}
          tabIndex={1}
          onBlur={handleBlur}
          ref={userMenu}
        >
          <ul>
            {bidderMenuItems.map((item, index) => (
              <li className="user-menu-item" onClick={() => handleItemPress(item)} key={index}>
                {item.icon}
                <p>{item.label}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <FaCaretDown className="caret" color={'white'} onClick={toggleMenu} />
    </div>
  )
})

const AdminNav = observer((props: { root: RootStore }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const userMenu = createRef<HTMLDivElement>();

  const handleItemPress = (item: MenuItem) => {
    if (item.dest === "/logout") {
      props.root.authStore.signOut();
      history.push('/');
      return;
    }

    history.push(item.dest);
  }

  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
    userMenu.current?.focus();
  }

  const menuVisibleStyle = {
    display: 'block',
    opacity: '1',
    top: '60px'
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if(!e.currentTarget.contains(e.relatedTarget as Node))
      setShowMenu(false);
  }

  return (
    <div className="user-nav-container">
      <div className="username-div" onClick={toggleMenu}>
        <p className="username">{props.root.authStore.admin.username}</p>
        <div>
          {/* <p className="credits">{props.root.authStore.auth.credits}</p>
          <p className="credits-label">credits</p> */}
        </div>
        <div 
          className="user-menu" 
          style={showMenu ? menuVisibleStyle : {}}
          tabIndex={1}
          onBlur={handleBlur}
          ref={userMenu}
        >
          <ul>
            {orgMenuItems.map((item, index) => (
              <li className="user-menu-item" onClick={() => handleItemPress(item)} key={index}>
                {item.icon}
                <p>{item.label}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <FaCaretDown className="caret" color={'white'} onClick={toggleMenu}/>
    </div>
  )
})

const OrgMemberNav = observer((props: { root: RootStore }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const userMenu = createRef<HTMLDivElement>();

  const handleItemPress = (item: MenuItem) => {
    if (item.dest === "/logout") {
      props.root.authStore.signOut();
      history.push('/');
      return;
    }

    history.push(item.dest);
  }

  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
    userMenu.current?.focus();
  }

  const menuVisibleStyle = {
    display: 'block',
    opacity: '1',
    top: '60px'
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if(!e.currentTarget.contains(e.relatedTarget as Node))
      setShowMenu(false);
  }

  return (
    <div className="user-nav-container">
      <div className="username-div" onClick={toggleMenu}>
        <p className="username">{props.root.authStore.orgMember.username}</p>
        <div>
          {/* <p className="credits">{props.root.authStore.auth.credits}</p>
          <p className="credits-label">credits</p> */}
        </div>
        <div 
          className="user-menu"
          style={showMenu ? menuVisibleStyle : {}}
          tabIndex={1}
          onBlur={handleBlur}
          ref={userMenu}
        >
          <ul>
            {authOrgMemberMenuItems.map((item, index) => (
              <li className="user-menu-item" onClick={() => handleItemPress(item)} key={index}>
                {item.icon}
                <p>{item.label}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <FaCaretDown className="caret" color={'white'} />
    </div>
  )
})

const MobileMenu = observer((props:
  { root: RootStore, visible: boolean, location: any, reference: React.RefObject<any> }) => {

  const handleItemPress = (item: MenuItem) => {
    if (item.dest === "/logout") {
      props.root.authStore.signOut();
      history.push('/');
      return;
    }

    history.push(item.dest);
    disableScroll();
  }

  const disableScroll = () => {
    const body = document.body;
    body.classList.toggle('no-scroll')
  }

  return (
    <div className="mobile-menu-container" id="mobile-menu" ref={props.reference}>
      {props.root.authStore.user.id && !props.root.authStore.busy && (
        <div className="username-div">
          <p className="username">{props.root.authStore.user.username}</p>
          <div className="credits-div">
            <p className="credits">{props.root.authStore.user.credits}</p>
            <p className="credits-label">credits</p>
          </div>
        </div>
      )}
      <div className="mobile-menu">
        {mobileMenu.map((item, index) => (
          <div
            className={props.location.pathname === item.dest ? 'selected-mobile-menu-item' : 'mobile-menu-item'}
            key={index}
            onClick={() => handleItemPress(item)}
          >
            {item.icon}
            <p className="mobile-menu-item-label">{item.label}</p>
          </div>
        ))}
        {props.root.authStore.user.id && bidderMenuItems.map((item, index) => (
          <div
            className={props.location.pathname === item.dest ? 'selected-mobile-menu-item' : 'mobile-menu-item'}
            key={index}
            onClick={() => handleItemPress(item)}
          >
            {item.icon}
            <p className="mobile-menu-item-label">{item.label}</p>
            {/* {item.label === 'Messages' && (
              <span className="messages-bubble">{}</span>
            )} */}
            {item.label === 'Notifications' && props.root.authStore.user.unread_notifications.length > 0 && (
              <span className="alert-bubble">{props.root.authStore.user.unread_notifications.length}</span>
            )}
          </div>
        ))}
        {(props.root.authStore.isAdmin && props.root.authStore.admin) && orgMenuItems.map((item, index) => (
          <div
            className={props.location.pathname === item.dest ? 'selected-mobile-menu-item' : 'mobile-menu-item'}
            key={index}
            onClick={() => handleItemPress(item)}
          >
            {item.icon}
            <p className="mobile-menu-item-label">{item.label}</p>
            {/* {item.label === 'Messages' && (
              <span className="messages-bubble">{props.messages}</span>
            )} */}
            {props.root.authStore.admin && (
              item.label === 'Notifications' && (
                <span className="alert-bubble">{props.root.authStore.admin.unread_notifications.length}</span>
              )
            )}
            {props.root.authStore.orgMember && (
              item.label === 'Notifications' && (
                <span className="alert-bubble">{props.root.authStore.orgMember.unread_notifications.length}</span>
              )
            )}
          </div>
        ))}
        {(props.root.authStore.isAdmin && props.root.authStore.orgMember) && authOrgMemberMenuItems.map((item, index) => (
          <div
            className={props.location.pathname === item.dest ? 'selected-mobile-menu-item' : 'mobile-menu-item'}
            key={index}
            onClick={() => handleItemPress(item)}
          >
            {item.icon}
            <p className="mobile-menu-item-label">{item.label}</p>
            {/* {item.label === 'Messages' && (
              <span className="messages-bubble">{props.messages}</span>
            )} */}
            {props.root.authStore.admin && (
              item.label === 'Notifications' && (
                <span className="alert-bubble">{props.root.authStore.admin.unread_notifications.length}</span>
              )
            )}
            {props.root.authStore.orgMember && (
              item.label === 'Notifications' && (
                <span className="alert-bubble">{props.root.authStore.orgMember.unread_notifications.length}</span>
              )
            )}
          </div>
        ))}
        {(!props.root.authStore.user.id && !props.root.authStore.isAdmin) && authMenu.map((item, index) => (
          <div
            className={props.location.pathname === item.dest ? 'selected-mobile-menu-item' : 'mobile-menu-item'}
            key={index}
            onClick={() => handleItemPress(item)}
          >
            {item.icon}
            <p className="mobile-menu-item-label-2">{item.label}</p>
          </div>
        ))}
      </div>
    </div>
  )
})

const bidderMenuItems: MenuItem[] = [
  {
    label: "Dashboard",
    icon: <RiDashboardLine size={25} color={"white"} />,
    dest: "/bidderdashboard"
  },
  {
    label: "Messages",
    icon: <RiMessage3Line size={25} color={"white"} />,
    dest: "/bidderinbox"
  },
  {
    label: "Notifications",
    icon: <IoIosNotificationsOutline size={25} color={"white"} />,
    dest: "/biddernotification"
  },
  {
    label: "Buy Credits",
    icon: <GiCash size={25} color={"white"} />,
    dest: "/biddercredits"
  },
  {
    label: "Statement",
    icon: <HiOutlineDocumentReport size={25} color={"white"} />,
    dest: "/bidderstatement"
  },
  {
    label: "Logout",
    icon: <IoIosLogOut size={25} color={"white"} />,
    dest: "/logout"
  }
]

const mobileMenu: MenuItem[] = [
  {
    label: "Auctions",
    icon: <RiAuctionLine size={28} color={"white"} />,
    dest: "/auctions"
  },
  {
    label: "How to Bid",
    icon: <AiOutlineInfoCircle size={28} color={"white"} />,
    dest: "/how_to_bid"
  },
  {
    label: "Organizations",
    icon: <VscOrganization size={28} color={"white"} />,
    dest: "/organizations"
  },
  {
    label: "Success Stories",
    icon: <SiStorybook size={28} color={"white"} />,
    dest: "/stories"
  },
]

const authMenu: MenuItem[] = [
  {
    label: "Login",
    icon: <ImKey color={"#33cc00"} size={25} />,
    dest: "/login"
  },
  {
    label: "Sign Up",
    icon: <FiEdit color={"#33cc00"} size={25} />,
    dest: "/signup"
  }
]

const orgMenuItems: MenuItem[] = [
  {
    label: "Dashboard",
    icon: <RiDashboardLine size={25} color={"white"} />,
    dest: "/orgdashboard"
  },
  {
    label: "Messages",
    icon: <RiMessage3Line size={25} color={"white"} />,
    dest: "/orginbox"
  },
  {
    label: "Notifications",
    icon: <IoIosNotificationsOutline size={25} color={"white"} />,
    dest: "/orgnotification"
  },
  {
    label: "Statement",
    icon: <HiOutlineDocumentReport size={25} color={"white"} />,
    dest: "/orgstatement"
  },
  {
    label: "Auctions",
    icon: <RiAuctionLine size={20} color={'white'} />,
    dest: "/orgauctions"
  },
  {
    label: "Teams",
    icon: <BsPeople size={20} color={"white"} />,
    dest: "/orgteams"
  },
  {
    label: "Acccount Settings",
    icon: <AiOutlineSetting size={20} color={"white"} />,
    dest: "/orgaccountsettings"
  },
  {
    label: "Logout",
    icon: <IoIosLogOut size={25} color={"white"} />,
    dest: "/logout"
  }
]

const authOrgMemberMenuItems: MenuItem[] = [
  {
    label: "Dashboard",
    icon: <RiDashboardLine size={25} color={"white"} />,
    dest: "/orgmemberdashboard"
  },
  {
    label: "Messages",
    icon: <RiMessage3Line size={25} color={"white"} />,
    dest: "/orgmemberinbox"
  },
  {
    label: "Notifications",
    icon: <IoIosNotificationsOutline size={25} color={"white"} />,
    dest: "/orgmembernotifications"
  },
  {
    label: "Statement",
    icon: <HiOutlineDocumentReport size={25} color={"white"} />,
    dest: "/orgmemberstatement"
  },
  {
    label: "Logout",
    icon: <IoIosLogOut size={25} color={"white"} />,
    dest: "/logout"
  }
]

export default Nav;

