import React, { useCallback, useEffect, useState, useContext } from "react";
import { AiOutlineSend, AiOutlineSearch } from "react-icons/ai";
import { RootContext } from "../..";
import Nav from "../../dashboard_components/Nav";
import OrgSidebar from "../../dashboard_components/OrgSidebar";
import "../../dashboard_styles/dashboard.css";
import "../../dashboard_styles/inbox.css";
import { MessageBox, Message, Receiver } from "../../models/message-box";
import { fetchOrgMessages, sendUserMessage } from "../../services/dashboardorginbox";
import Echo from "laravel-echo";
import Cookies from "js-cookie";
import useQuery from "../../components/useQuery";
import Organization from "../../models/organization";
import { markMessageRead } from "../../services/bidder";
import ChatTile from "../../components/ChatTile";
import NewChatTile from "../../components/NewChatTile";
import OrgChatBubble from "../../components/OrgChatBubble";

const { REACT_APP_SERVER, REACT_APP_PUSHER_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;


export default function OrgInbox() {
    const root = useContext(RootContext);
    const query = useQuery();
    const [conversations, setConversations] = useState<MessageBox[] | undefined>([]);
    const [chatHistory, setChatHistory] = useState<Message[] | undefined>([]);
    const [text, setText] = useState<string>("");
    const [recipient, setRecipient] = useState<Receiver>();
    const [unreadMessages, setUnreadMessages] = useState<number>(0);
    const [searchResults, setSearchResults] = useState<MessageBox[] | undefined>([]);
    const [newChats, setNewChats] = useState<Organization[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const fetchMessages = useCallback(async () => {
        try {
            let { message_box_array, unread } = await fetchOrgMessages(root.authStore.admin.id);
            setConversations(message_box_array);
            setUnreadMessages(unread);
        } catch (error) { }
    }, [root.authStore.admin.id])

    const handleParams = () => {
        const organisation = query.get('organisation');
        if (organisation) {
            let newChat = root.organizationStore.organizations.filter(org => org.id === parseInt(organisation));
            if (!checkForExistingChat(newChat[0].name)) {
                setNewChats(newChat)
                const recipient = { name: newChat[0].name, id: newChat[0].adminId, profile_image: newChat[0].imageUrl };
                setRecipient(recipient);
                setChatHistory([]);
            }
        }
    }

    const checkForExistingChat = (name: string): boolean => {
        let isExisting = false;
        conversations?.forEach(element => {
            if (element.name === name)
                isExisting = true;
        });
        return isExisting;
    }

    const handleConversationPress = (name: string) => {
        let recipient: Receiver;
        let history: MessageBox | undefined = conversations?.find(message => message.name === name);
        setChatHistory(history?.messages);

        if (root.authStore.admin.id === history?.messages[0].from) {
            recipient = {
                name: history?.name, id: history.messages[0].to,
                profile_image: history.messages[0].receiver.profile_image
            };
            setRecipient(recipient);
        } else if (root.authStore.admin.id === history?.messages[0].to) {
            recipient = {
                name: history.name, id: history.messages[0].from,
                profile_image: history.messages[0].sender.profile_image
            };
            setRecipient(recipient);
        }

        if (!history?.messages[history?.messages.length - 1].read_by_receiver
            && history?.messages[history.messages.length - 1].to === root.authStore.admin.id)
            markAsRead();
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (text.length === 0)
            return
        setBusy(true);
        let data = await sendUserMessage(root.authStore.admin.id, recipient!.id, text);
        let newChatHistory: Message[] = [...chatHistory!, data];
        if (chatHistory?.length === 0) {
            let newConversation: MessageBox = { name: recipient?.name!, messages: newChatHistory }
            setChatHistory(newChatHistory);
            setConversations((prevState) => [newConversation, ...prevState!])
            setSearch('');
            setNewChats([]);
            setSearchResults([]);
        } else {
            let newConversations: MessageBox[] = conversations!;
            let conversationIndex = newConversations.findIndex(messages => recipient?.name === messages.name);
            newConversations[conversationIndex].messages = newChatHistory;
            setChatHistory(newChatHistory);
            setConversations(newConversations);
        }
        setText('');
        setBusy(false);
    }

    const handleConversationUpdates = useCallback((message: Message) => {
        if (recipient && message.from === recipient!.id) {
            let newChatHistory: Message[] = [...chatHistory!, message];
            let newConversations: MessageBox[] = conversations!;
            let conversationIndex = newConversations.findIndex(conversation => recipient.name === conversation.name);
            newConversations[conversationIndex].messages = newChatHistory;
            setChatHistory(newChatHistory);
            setConversations(newConversations);
        } else {
            let newConversations: MessageBox[] = conversations!;
            let conversationIndex = newConversations.findIndex(conversation => message.sender.name === conversation.name);
        }
    }, [chatHistory, conversations, recipient])

    const initializeEcho = useCallback(() => {
        let token = Cookies.get('customerToken');

        let localEchoConfig = {
            broadcaster: "pusher",
            key: REACT_APP_PUSHER_KEY,
            cluster: REACT_APP_PUSHER_CLUSTER,
            forceTLS: false,
            encrypted: false,
            wsHost: window.location.hostname,
            wsPort: 6001,
            enabledTransport: ['ws', 'wss'],
            authEndpoint: `${REACT_APP_SERVER}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: "application/json"
                }
            },
        };

        let prodEchoConfig = {
            broadcaster: "pusher",
            key: REACT_APP_PUSHER_KEY,
            cluster: REACT_APP_PUSHER_CLUSTER,
            forceTLS: true,
            encrypted: true,
            wsHost: "admin.schoolbidz.com",
            wssPort: 443,
            enabledTransport: ['ws', 'wss'],
            authEndpoint: `${REACT_APP_SERVER}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: "application/json"
                }
            },
        }
        let echoConfig = window.location.hostname != "www.schoolbidz.com" ? localEchoConfig : prodEchoConfig;

        let echo: Echo | any = new Echo(echoConfig)

        try {
            echo.private(`messages.${root.authStore.admin.id}`).listen(
                "NewMessage",
                (e: any) => {
                    handleConversationUpdates(e.message);
                }
            )
        } catch (e) {
            console.log(e)
        }
    }, [handleConversationUpdates, root.authStore.admin.id]);

    useEffect(() => {
        fetchMessages();
        initializeEcho();
        handleParams();
    }, [])

    const updateReadConversation = () => {
        const conversationIndex = conversations?.findIndex(conversation => conversation.name === recipient?.name)!;
        const newConversations: MessageBox[] = conversations!;
        newConversations[conversationIndex].messages[newConversations[conversationIndex].messages.length - 1].status = 'read';
        setConversations(newConversations);
    }

    const markAsRead = async () => {
        try {
            await markMessageRead(recipient?.id);
            updateReadConversation();
            fetchMessages()
        } catch (error) { }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        if (event.target.value.length === 0) {
            setSearchResults([]);
            setNewChats([]);
            return;
        }
        const query = event.target.value.toLowerCase();
        let results = conversations?.filter((conversation: MessageBox) => conversation.name.toLowerCase().includes(query));
        setSearchResults(results);
        // let newChats = root.organizationStore.organizations.filter((organization) => organization.name.toLowerCase().includes(query));
        // sanitizeNewChats(results!, newChats);
    }

    // prevent duplicates
    const sanitizeNewChats = (chats: MessageBox[], newChats: Organization[]) => {
        for (let i = 0; i < newChats.length; i++) {
            for (let z = 0; z < chats.length; z++) {
                if (newChats[i].name === chats[z].name)
                    setNewChats(newChats.slice(i, i + 1))
            }
        }
        setNewChats(newChats);
    }

    const handleNewChatPress = (item: Organization) => {
        const recipient = { name: item.name, id: item.adminId, profile_image: item.imageUrl };
        setRecipient(recipient);
        setChatHistory([]);
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <OrgSidebar unreadMessages={unreadMessages} />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="settings-header">
                                <h1>Inbox</h1>
                            </div>
                            <div className="search-and-chat-title-bar">
                                <div className="search-bar">
                                    <span className="search-span">
                                        <AiOutlineSearch />
                                        <input
                                            type="text"
                                            className="search-input"
                                            placeholder="Search or start new chat"
                                            onChange={handleSearch}
                                            value={search}
                                        />
                                    </span>
                                </div>
                                <div className="chat-title-bar">
                                    {recipient && (
                                        <div className="chat-avatar">
                                            <div className="chat-profile-image">
                                                {recipient?.profile_image === 'avatar_01.jpg' ? (
                                                    <p>{recipient.name.substring(0, 1)}</p>
                                                ) : (
                                                    <img src={`${REACT_APP_SERVER}/images/profileimages/im${recipient?.profile_image}`} alt="avatar" />
                                                )}
                                            </div>
                                            <p className="chat-user-name">
                                                {recipient?.name}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="inbox-boxes">
                                <div className="users-box">
                                    {conversations!.length > 0 && (
                                        <p>Chats</p>
                                    )}
                                    {searchResults?.length === 0 && conversations?.map((conversation, index) => (
                                        <ChatTile
                                            handlePress={handleConversationPress}
                                            conversation={conversation}
                                            key={index}
                                        />
                                    ))}
                                    {searchResults?.map((conversation, index) => (
                                        <ChatTile
                                            handlePress={handleConversationPress}
                                            conversation={conversation}
                                            key={index}
                                        />
                                    ))}
                                    {newChats.length !== 0 && (
                                        <p>New Chats</p>
                                    )}
                                    {newChats?.map((item, index) => (
                                        <NewChatTile
                                            handlePress={handleNewChatPress}
                                            data={item}
                                            key={index}
                                        />
                                    ))}
                                </div>
                                <div className="message-history">
                                    <div className="message-history-container">
                                        {chatHistory?.map((message: Message, index) => (
                                            <OrgChatBubble data={message} key={index} />
                                        ))}
                                    </div>
                                    <div className="send-message-container">
                                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                            <span className="send-message-span">
                                                <input
                                                    type="text"
                                                    className="send-message-input"
                                                    placeholder="Type your message"
                                                    value={text}
                                                    onChange={e => setText(e.target.value)}
                                                />
                                                {busy ? (
                                                    <div className="chat-loader"></div>
                                                ) : (
                                                    <button className="send-message-btn">
                                                        <AiOutlineSend size={20} color="#424242" />
                                                    </button>
                                                )}
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}