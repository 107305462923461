import { useEffect, useState, useContext } from 'react';
import { observer } from "mobx-react-lite";
import Cookies from 'js-cookie';

import Sidebar from '../../dashboard_components/Sidebar';
import Nav from '../../dashboard_components/Nav';
import "../../dashboard_styles/credits.css";
import { fetchCreditPacks, purchaseCreditPack } from "../../services/credits";
import CreditPack from '../../models/credit-pack';
import Ripple from "../../components/Ripple";
import AgreeModal from '../../components/AgreeModal';
import { toast } from 'react-toastify';
import { acceptTerms } from '../../services/auth';
import { RootContext } from '../..';
import PaymentModal from '../../dashboard_components/PaymentModal';
import { useAcceptJs } from 'react-acceptjs';

const authData = {
    apiLoginID: process.env.REACT_APP_API_LOGIN_ID!,
    clientKey: process.env.REACT_APP_CLIENT_KEY!
}

const BidderCredits = observer(() => {
    const root = useContext(RootContext);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [packs, setPacks] = useState<CreditPack[]>([]);
    const [activePack, setActivePack] = useState<CreditPack>(null!);
    const [busy, setBusy] = useState<boolean>(false);
    const [hideAgreement, setHideAgreement] = useState<boolean>(true);
    const [number, setNumber] = useState<string>("");
    const [cvc, setCVC] = useState<string>("");
    const [expiry, setExpiry] = useState<string>("");
    const { dispatchData } = useAcceptJs({ authData, environment: 'PRODUCTION' });
    
    const loadCreditPacks = async () => {
        const data = await fetchCreditPacks();
        setPacks(data);
    }

    useEffect(() => {
        loadCreditPacks()
    }, [])

    const handleCreditPackPress = (item: CreditPack) => {
        setActivePack(item)
    }

    // const fetchCheckoutSession = async () => {
    //     if (!activePack)
    //         return;
    //     setBusy(true);
    //     return await purchaseCreditPack(activePack.id, activePack.name);
    // }

    const handlePurchase = async () => {
        if (!root.authStore.user.acceptedTerms) {
            setHideAgreement(false);
            toast.info(
                "Before you purchase credits, we need you to agree to our Terms and Conditions " +
                "for use of the platform. Don't worry, this won't show up again once you agree.",
                { autoClose: 10000 }
            )
        } else {
            setOpenModal(true);
        }

    }

    const agreeToTerms = async () => {
        const token = Cookies.get("customerToken");
        try {
            await acceptTerms(token)
                .then(async () => {
                    setHideAgreement(true);
                    root.authStore.refreshUser();
                    setOpenModal(true);
                })
        } catch (error) {
            toast.error('An error occurred. Please try again later.')
        }
    }

    const makePayment = async () => {
        let split = expiry.split("/").map(item => item.trim());
        const cardData = {
            cardNumber: number.replaceAll(' ', ''),
            month: split[0],
            year: split[1],
            cardCode: cvc
        }
        setBusy(true);
        try {
            const response = await dispatchData({ cardData });
            await purchaseCreditPack(activePack.id, activePack.name, response);
            setBusy(false);
            setOpenModal(false);
            toast.success("Credit purchase successful");
            setNumber("");
            setCVC("");
            setExpiry("");
        } catch (error) {
            console.log(error);
            setBusy(false);
            setOpenModal(false);
            setNumber("");
            setCVC("");
            setExpiry("");
            toast.error("An error occurred, we couldn't process your payment. Please wait a while and try again.")
        }
        
    }

    return (
        <div className="relative page-container">
            <Nav />
            <AgreeModal
                hidden={hideAgreement}
                onAgreeClick={agreeToTerms}
                onCancelClick={() => setHideAgreement(true)}
            />
            <div className="page-content-box">
                <Sidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="mb-4 settings-header">
                                <h1 className="mb-2">Buy Credits</h1>
                                <p> In order to be able to place bids without any interruptions you will need to purchase bid credits here.</p>
                            </div>
                            <div className="credit-pack-container">
                                {packs.map((item, index) => (
                                    <div className="credit-pack" key={index}>
                                        <div className="heading">{item.label}</div>
                                        <p className="font-thin">{item.name}</p>
                                        <h1>${item.price}</h1>
                                        <p>{item.numberOfCredits} credits</p>
                                        <button
                                            className={`${activePack?.id === item.id ? 'active-credit-btn' : 'credit-btn'}`}
                                            onClick={() => handleCreditPackPress(item)}
                                        >{activePack?.id === item.id ? 'Selected' : 'Select'}</button>
                                    </div>
                                ))}
                            </div>
                            <div className="mobile-credit-pack-container">
                                {packs.map((item, index) => (
                                    <div className="credit-pack" key={index}>
                                        <div className="heading">{item.label}</div>
                                        <p>{item.name}</p>
                                        <h1>${item.price}</h1>
                                        <p>{item.numberOfCredits} credits</p>
                                        <button
                                            className={`${activePack?.id === item.id ? 'active-credit-btn' : 'credit-btn'}`}
                                            onClick={() => handleCreditPackPress(item)}
                                        >{activePack?.id === item.id ? 'Selected' : 'Select'}</button>
                                    </div>
                                ))}
                            </div>
                            <div className="buy-credits-box">
                                <div className="heading">
                                    Payment & Confirmation
                                </div>
                                <p>Choose Payment Method</p>
                                <input type="radio" name="stripe" id="stripe" checked /> Authorize.Net
                                <div>
                                    {busy ? (
                                        <Ripple />
                                    ) : (
                                        <button
                                            onClick={handlePurchase}
                                            style={!activePack ? { background: '#616161' } : {}}
                                            disabled={!activePack}
                                        >Buy Credits Now!</button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-copyright-div">
                            <p>COPYRIGHT © 2021 SCHOOLBIDZ  |  ALL RIGHTS RESERVED</p>
                        </div>
                    </div>
                </div>
            </div>
            <PaymentModal
                open={openModal}
                close={() => {
                    setOpenModal(false);
                    setNumber("");
                    setCVC("");
                    setExpiry("");
                }}
                cvc={cvc}
                number={number}
                expiry={expiry}
                setCVC={setCVC}
                loading={busy}
                setExpiry={setExpiry}
                setNumber={setNumber}
                makePayment={makePayment}
            />
        </div>
    )
})

export default BidderCredits;