import Cookies from "js-cookie";
import { makeAutoObservable, action, runInAction } from "mobx";

import User from "./userModel";
import RootStore from "./root";
import { fetchAdmin, fetchCustomer} from "../services/auth";
import Alert from "../models/alert";
import Admin from "./adminModel";
import OrgMember from "./orgMemberModel";

interface TokenObject {
  role: string,
  token: string
}

interface ShippingInfo {
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  notes: string
}

class AutoLoginModule {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      autoSignIn: action,
    })
    this.rootStore = rootStore;
  }

  async autoSignIn() {
    const tokenObject: TokenObject = this.getToken();
    if(tokenObject.role === "customer")
      this.customerAutoSignIn(tokenObject.token);
    else if(tokenObject.role === "organization")
      this.adminAutoSignIn(tokenObject.token);
    else
      runInAction(() => { this.rootStore.authStore.busy = false })
  }

  private async customerAutoSignIn(token: string) {
    runInAction(() => { this.rootStore.authStore.busy = true })
    try {
      const { data } = await fetchCustomer(token);
      runInAction(() => {
        this.rootStore.authStore.user = new User(data.id, data.name,
        data.email, data.profile_image, data.number_of_credits, 
        data.roles[0].name, this.getAlertsFromServer(data.read_notifications), 
        this.getAlertsFromServer(data.unread_notifications), data.access_token,
        this.getShippingInfoFromServer(data.shipping_address), this.parseAcceptedTerms(data.accepted_terms))
        
        this.rootStore.authStore.isSignedIn = true
        this.rootStore.authStore.busy = false
        this.rootStore.authStore.role = data.roles[0].name;
      })
    } catch (error) { 
      this.rootStore.authStore.busy = false 
    }
  }

  private async adminAutoSignIn(token: string) {
    runInAction(() => { this.rootStore.authStore.busy = true })
    try {
      const {data} = await fetchAdmin(token);
      runInAction(() => {

        if (data.roles[0].name === "organisation_admin") {
          this.rootStore.authStore.admin = new Admin(data.id, data.name, data.profile_image, data.roles[0].name, 
            this.getAlertsFromServer(data.read_notifications), 
            this.getAlertsFromServer(data.unread_notifications),
             data.access_token, data.organization)
        } else if(data.roles[0].name === "organisation_member") {
          this.rootStore.authStore.orgMember = new OrgMember(data.id, data.name, data.profile_image, data.roles[0].name, 
            this.getAlertsFromServer(data.read_notifications), 
            this.getAlertsFromServer(data.unread_notifications),
             data.access_token, data.organization)
        }
        this.rootStore.authStore.isSignedIn = true
        this.rootStore.authStore.busy = false
        this.rootStore.authStore.isAdmin = true
        this.rootStore.authStore.role = data.roles[0].name;

      })
    } catch (error) { this.rootStore.authStore.busy = false}
  }

  private getToken():TokenObject {
    let customerToken = Cookies.get("customerToken");
    let adminToken = Cookies.get("adminToken");
    if(customerToken)
      return { role: "customer", token: customerToken };
    else if(adminToken)
      return { role: "organization", token: adminToken }
    
    return { role: "", token: "" };
  }

  private getShippingInfoFromServer(json: any): ShippingInfo {
    return {
      firstName: json.first_name,
      lastName: json.last_name,
      address: json.full_address,
      city: json.city,
      state: json.state,
      zip: json.zip_code,
      notes: json.additional_notes
    }
  }

  private parseAcceptedTerms(json: any): boolean {
    if(!json)
      return false;

    if(json === '0')
      return false
    else
      return true
  }

  getAlertsFromServer(json: any): Alert[] {
    let notifications: Alert[] = [];
    json.forEach((element: any) => {
      const notification: Alert = {id: element.id, message: element.data.message, date: element.created_at, read: element.read_at? true:false}
      notifications.push(notification);
    });
    return notifications;
  }

}

export default AutoLoginModule;