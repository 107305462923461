import { makeAutoObservable } from "mobx";
import Alert from "../models/alert";

type ShippingInfo = {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  notes: string;
}

const emptyShippingInfo: ShippingInfo = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  notes: ''
}

class User {
  id: number = null!;
  username: string = null!;
  email: string = null!;
  image_url: string = null!;
  credits: number = null!;
  role: string = null!;
  read_notifications: Alert[] = null!;
  unread_notifications: Alert[] = null!;
  token: string = null!;
  shippingInfo: ShippingInfo;
  acceptedTerms: boolean;

  constructor(
    id: number,
    username: string, 
    email: string,
    image_url: string, 
    credits: number, 
    role: string,
    read_notifications: Alert[],
    unread_notifications: Alert[],
    token: string,
    shippingInfo: ShippingInfo,
    acceptedTerms: boolean
    ) {

    makeAutoObservable(this, {
      id: false
    })

    this.id = id;
    this.username = username;
    this.email = email;
    this.image_url = image_url;
    this.credits = credits;
    this.role = role;
    this.read_notifications = read_notifications;
    this.unread_notifications = unread_notifications;
    this.token = token;
    this.shippingInfo = shippingInfo;
    this.acceptedTerms = acceptedTerms
  }

  static EmptyInstance() {
    return new User(null!, null!, null!, null!, null!, null!, [], [], null!, emptyShippingInfo, null!)
  }
}



export default User