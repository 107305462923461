import { makeAutoObservable, action, runInAction } from "mobx";
import Auction from "../models/auction";
import { fetchAuctions, getAuction } from "../services/auctions";
import RootStore from "./root";
import Prize from "../models/prize";
import Organization from "../models/organization";

class AuctionStore {
  rootStore: RootStore;
  busy: boolean = true;
  auctions: Auction[] = [];
  almostEndingAuctions: Auction[] = [];
  quarry: Auction = Auction.EmptyInstance();

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      loadAuctions: action,
      findAuction: action,
      quietUpdate: action
    })
    this.rootStore = rootStore;
  }

  async loadAuctions() {
    runInAction(() => {
      this.busy = true;
    })
    try {
      const { data } = await fetchAuctions();
      runInAction(() => {
        this.updateAuctions(data);
        this.busy = false;
      })
    } catch (error) {
      runInAction(() => {
        this.busy = false;
      })
      throw error;
    }
  }

  async findAuction(id: string) {
    runInAction(() => {
      this.busy = true;
    })
    try {
      const { data } = await getAuction(id);
      if(data.length === 0)
        throw new Error("Auction not found");
      runInAction(() => {
        this.updateQuarry(data);
        this.busy = false;
      })
    } catch (error) {
      runInAction(() => {
        this.busy = false;
      })
      throw error;
    }
  }

  async quietUpdate() {
    try {
      const { data } = await fetchAuctions();
      runInAction(() => { this.updateAuctions(data) })
    } catch (error) {}
  }

  private updateAuctions(json: any) {
    const { new_auctions, almost_ending } = json;
    let newAuctions: Auction[] = [];
    let almostEndingAuctions: Auction[] = [];
    new_auctions.forEach((element: any) => {
      const auction: Auction = new Auction(element.id, element.name, element.description, 
        element.number_of_bids, this.extractPrizeFromJson(element.prizes[0]), 
        this.extractOrganizationFromJson(element.organization), element.created_at)
      newAuctions.push(auction);
    });
    this.auctions = newAuctions;
    almost_ending.forEach((element: any) => {
      const auction: Auction = new Auction(element.id, element.name, element.description, 
        element.number_of_bids, this.extractPrizeFromJson(element.prizes[0]), 
        this.extractOrganizationFromJson(element.organization), element.created_at)
      almostEndingAuctions.push(auction);
    });
    this.almostEndingAuctions = almostEndingAuctions;
  }

  private extractPrizeFromJson(json: any): Prize {
    return {
      id: json.id, name: json.name, value: json.value, costPerBid: json.cost_per_bid,
      maxNumberOfBids: json.max_number_of_bids, specification: json.specification.specification_text,
      images: json.images
    }
  }

  private extractOrganizationFromJson(json: any): Organization {
    return new Organization(
      json.id, json.about, json.admin_id, json.name, json.profile_photo, json.website, json.country, json.facebook_address, 
      json.twitter_address, json.instagram_address, "", [], [], [], json.created_at)
  }

  private updateQuarry(json: any) {
    this.quarry = new Auction(json.id, json.name, json.description, json.number_of_bids,
      this.extractPrizeFromJson(json.prizes[0]), 
      this.rootStore.organizationStore.getOrganization(json.organization_id), json.created_at)
  }
}

export default AuctionStore;