import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import "../styles/cookies.css";
import cookies from "../assets/images/cookies.svg";
import { RootContext } from "..";
import { toast } from "react-toastify";
import { acceptCookies } from "../services/auth";

const CookiesAlert = observer(() => {
  const root = useContext(RootContext);
  const [hidden, setHidden] = useState<boolean>(true);

  const checkGuestCookie = () => {
    const cookie = localStorage.getItem('guest-cookie');
    if(!cookie)
      setHidden(false);
    else
      setHidden(true);
  }

  const checkBidderCookie = () => {
    const cookie = localStorage.getItem('bidder-cookie');
    console.log(cookie)
    if(!cookie)
      setHidden(false);
    else
      setHidden(true);
  }

  const checkAdminCookie = () => {
    const cookie = localStorage.getItem('admin-cookie');
    if(!cookie)
      setHidden(false);
    else
      setHidden(true);
  }

  const accept = async() => {
    if(!root.authStore.isSignedIn)
      localStorage.setItem("guest-cookie", 'accepted',);
    else if(!root.authStore.isAdmin) {
      localStorage.setItem("bidder-cookie", 'accepted');
      await acceptCookies(root.authStore.user.token).catch((e: any) => console.log(e));
    }
    else {
      localStorage.setItem("admin-cookie", 'accepted');
      await acceptCookies(root.authStore.admin.token).catch((e: any) => console.log(e))
    }

    setHidden(true);
    toast.dismiss();
  }

  const declineCookies = () => {
    if(root.authStore.isSignedIn) {
      toast.info(`All first party and third party cookies from Stripe are required to make this website work.
      You can't opt out as a registered user. By continuing to browse or closing this banner, you consent to our use of cookies.`,
      {autoClose: false})
    } else
      setHidden(true);
  }

  useEffect(() => {
    if(!root.authStore.busy) {
      if(!root.authStore.isSignedIn)
        checkGuestCookie();
      else if(!root.authStore.isAdmin)
        checkBidderCookie();
      else if(root.authStore.isAdmin)
        checkAdminCookie();
    }
  }, [root.authStore.isSignedIn, root.authStore.isAdmin, root.authStore.busy])

  return(
    <div className="cookies-container" hidden={hidden}>
      <img src={cookies} alt="cookies" />
      <h5>We use cookies to ensure you get the best experience on our website</h5>
      <span>
        <button className="allow-cookies" onClick={accept}>Allow Cookies</button>
        <button className="decline-cookies" onClick={declineCookies}>Decline</button>
      </span>
    </div>
  )
})

export default CookiesAlert;