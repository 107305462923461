import React, { createRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BsPencil } from 'react-icons/bs';
import Cookies from 'js-cookie'


import "../../dashboard_styles/dashboard.css";
import "../../dashboard_styles/account_settings.css";
import Nav from '../../dashboard_components/Nav';
import OrgMemberSidebar from '../../dashboard_components/OrgMemberSidebar';
import { RootContext } from '../..';
import { updateAdminDetails } from '../../services/dashboardaccountsettings';
import OrgDashboardSettingsForm from '../../models/org-dashboard-settings-form';
import Ripple from '../../components/Ripple';
import OrgMemberSettingsHeader from './OrgMemberSettingsHeader';
import Crop from '../../dashboard_components/Crop';
import DeleteModal from '../../components/DeleteModal';
import history from "../../components/History";
import { deleteUser } from "../../services/auth";


const { REACT_APP_SERVER } = process.env;

function OrgMemberAccountSettings() {
    const fileInput = createRef<HTMLInputElement>();
    const [webAddress, setWebAddress] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [facebookAddress, setFacebookAddress] = useState<string>("");
    const [twitterAddress, setTwitterAddress] = useState<string>("");
    const [about, setAbout] = useState<string>("");
    const [busy, setBusy] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [newAvatar, setNewAvatar] = useState<any>(null);
    const root = React.useContext(RootContext);
    const [showCrop, setShowCrop] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [cropImage, setCropImage] = useState<string>(''); // image to be cropped
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [street, setStreet] = useState('');

    const splitLocationInfo = () => {
        const locationInfo = root.authStore.orgMember.organization.location.split(',');
        setStreet(locationInfo[0]);
        setCity(locationInfo[1]);
        setState(locationInfo[2]);
        setZip(locationInfo[3]);
    }

    useEffect(() => {
        if(root.authStore.orgMember.organization.location)
            splitLocationInfo();
    }, [])

    const updateProfileInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setBusy(true);
        let orgForm: OrgDashboardSettingsForm = {
            webAddress, location, facebookAddress, twitterAddress, about, profile_photo1: file,
        }
        let data = await updateAdminDetails(orgForm, root.authStore.orgMember.organization.id);
        setBusy(false);
        toast.success(data);
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files: FileList = e.currentTarget.files!;
        setShowCrop(true);
        setCropImage(URL.createObjectURL(files[0]));
    }

    const hideCrop = () => {
        setShowCrop(false);
    }

    const saveCroppedImage = async(canvas: HTMLCanvasElement) => {
        canvas.toBlob(file => {
            setNewAvatar(URL.createObjectURL(file));
            handleFile(file!);
        }, 'image/jpeg')
    }

    const handleFile = (file: Blob) => {
        if (validateFile(file)) {
            let reader = new FileReader();
            reader.onload = e => { setFile(e.target?.result) }
            reader.readAsDataURL(file);
        }
    }

    const validateFile = (file: Blob): boolean => {
        if (file.type === 'application/pdf') {
            return false;
        }
        else if (file.size > 100000000) {
            return false;
        }
        return true;
    }

    const handleUploadPress = (e: React.MouseEvent) => {
        e.preventDefault();
        fileInput.current?.click();
    }

    const deleteAccount = async() => {
        const token = Cookies.get("customerToken");
        try {
            setBusy(true)
            await deleteUser(root.authStore.user.id, token)
                .then(() => {
                    history.push('/');
                    root.authStore.signOut();
                });
        } catch (error) {
            setBusy(false);
            toast.error("An error occurred while attempting to delete your account. Please try again later")
        }
    }

    return (
        <div className="page-container">
            <Nav />
            <Crop 
                hidden={showCrop} 
                hideCrop={hideCrop} 
                imageSrc={cropImage}
                saveCroppedImage={saveCroppedImage}
            />
            <div className="page-content-box">
                <OrgMemberSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <OrgMemberSettingsHeader
                                title="Account Settings"
                                subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elicit" />

                            <div className="settings-boxes">
                                <div className="form">
                                    <div className="setting-box-1">
                                        <div className="settings-user-profile" style={{background: 'linear-gradient(to right, #ffab28, #ffc728)'}}>
                                            {!file && (root.authStore.orgMember.image_url === 'avatar_01.jpg') && (
                                                <p>{root.authStore.orgMember.username.substring(0, 1)}</p>
                                            )}
                                            {!file && root.authStore.orgMember.image_url !== 'avatar_01.jpg' &&
                                                <img src={`${REACT_APP_SERVER}/images/profileimages/im${root.authStore.orgMember.image_url}`} alt="avatar" />
                                            }
                                            {file && <img src={newAvatar} alt="avatar" />}
                                            <button
                                                className="upload-btn"
                                                onClick={handleUploadPress}
                                            >
                                                <BsPencil size={20} />
                                            </button>
                                            <input
                                                type="file"
                                                hidden
                                                id="avatar"
                                                onChange={handleFileSelect}
                                                ref={fileInput}
                                            />
                                        </div>
                                        <div className="form-input-box">
                                            <input type="text" className="text-input username" name="Organization Name"
                                                defaultValue={root.authStore.orgMember.username} disabled />
                                        </div>
                                        <div className="form-input-box">
                                            <input type="text" className="text-input" name="email"
                                                defaultValue={root.authStore.orgMember.organization.name} disabled />
                                        </div>
                                        <div className="form-input-box">
                                            <input type="text" className="text-input" name="location"
                                                defaultValue={root.authStore.orgMember.organization.location} disabled />
                                        </div>
                                        <button 
                                            className="delete-btn" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            Delete Account
                                        </button>
                                    </div>
                                    <div className="setting-box-2">
                                        <h2>Organization Information</h2>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input
                                                    id="web_address"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="web_address"
                                                    placeholder="Website Address"
                                                    defaultValue={root.authStore.orgMember.organization.website}
                                                    onChange={e => setWebAddress(e.target.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div">
                                                <input
                                                    type="text"
                                                    className="text-input-2"
                                                    name="location"
                                                    id="location"
                                                    placeholder="Street information"
                                                    defaultValue={street}
                                                    onChange={e => setStreet(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input
                                                    id="city"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="city"
                                                    placeholder="City"
                                                    defaultValue={city}
                                                    onChange={e => setCity(e.target.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div" >
                                                <input
                                                    id="state"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="state"
                                                    placeholder="State"
                                                    defaultValue={state}
                                                    onChange={e => setState(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div" style={{width: '48%'}}>
                                                <input
                                                    id="zip_code"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="zip_code"
                                                    placeholder="Zipcode"
                                                    defaultValue={zip}
                                                    onChange={e => setZip(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-input-div">
                                            <textarea
                                                name="full_address"
                                                id="full_address"
                                                placeholder="Enter your About information"
                                                rows={5}
                                                defaultValue={root.authStore.orgMember.organization.about}
                                                onChange={e => setAbout(e.target.value)}
                                            />
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input
                                                    type="text"
                                                    className="text-input-2"
                                                    name="city"
                                                    id="city"
                                                    placeholder="Twitter Page address"
                                                    defaultValue={root.authStore.orgMember.organization.twitter_address}
                                                    onChange={e => setTwitterAddress(e.target.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div">
                                                <input
                                                    type="text"
                                                    className="text-input-2"
                                                    name="lastname"
                                                    id="lastname"
                                                    placeholder="Facebook Page address"
                                                    defaultValue={root.authStore.orgMember.organization.facebook_address}
                                                    onChange={e => setFacebookAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {busy ? (
                                            <div className="loader">
                                                <Ripple color="#ff3366" />
                                            </div>
                                        ) : (
                                            <button type="submit" className="submit-btn" onClick={updateProfileInfo}>Save Changes</button>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                deleteAccount={deleteAccount}
                busy={busy}
            />
        </div>
    )
}

export default OrgMemberAccountSettings;