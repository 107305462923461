import { Modal } from "@material-ui/core";

import "../styles/deleteModal.css";
import Ripple from "./Ripple";

interface DeleteModalProps {
  isOpen: boolean,
  onClose: any,
  deleteAccount: any,
  busy: boolean
}

const DeleteModal = (props: DeleteModalProps) => {
  return (
    <Modal
      open={props.isOpen}
      className="delete-modal"
      onClose={props.onClose}
      disableBackdropClick
    >
      <div className="delete-body">
        <p className="delete-body-title">Are you sure you want to delete your account?</p>
        <p className="delete-body-subtitle">This action cannot be reversed</p>
        {props.busy ? (
          <div className="loader">
              <Ripple color="#ff3366" />
          </div> 
        ): (
          <div className="delete-actions">
            <button 
              onClick={props.onClose}
              className="cancel-btn"
            >Cancel</button>
            <button
              style={{marginTop: '40px'}}
              className="delete-btn"
              onClick={props.deleteAccount}
            >Delete Account</button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default DeleteModal;