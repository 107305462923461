import { makeAutoObservable } from "mobx";
import Organization from "./organization";
import Prize from "./prize";

const emptyPrize: Prize = {
  id: 0,
  costPerBid: 0,
  specification: '',
  images: [],
  maxNumberOfBids: 0,
  name: '',
  value: 0
}

class Auction {
  id: number = null!;
  name: string = null!;
  description: string = null!;
  numberOfBids: number = null!;
  prize: Prize = null!;
  organization: Organization = null!;
  createdAt: string = null!;

  constructor(
    id: number,
    name: string,
    description: string,
    numberOfBids: number,
    prize: Prize,
    organization: Organization,
    createdAt: string
  ) {
    makeAutoObservable(this, {
      id: false
    })

    this.id = id;
    this.name = name;
    this.description = description;
    this.numberOfBids = numberOfBids;
    this.prize = prize;
    this.organization = organization;
    this.createdAt = createdAt
  }
  
  static EmptyInstance() {
    return new Auction(null!, null!, null!, null!, emptyPrize, null!, null!)
  }
}

export default Auction;