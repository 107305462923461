import React, { createContext } from 'react';
import { render } from 'react-dom';
import { Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Echo from 'laravel-echo';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import history from "./components/History";
import RootStore from "./stores/root";
import packageJson from '../package.json';
import CacheBuster from './components/CacheBuster';

const { REACT_APP_PUSHER_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;

const RootContext = createContext<RootStore>(new RootStore());

declare global {
  namespace NodeJS {
    interface Global {
      appVersion: any
    }
  }
}

declare global {
  interface Window {
    Echo: any;
  }
}

declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = require('pusher-js');

let localEchoConfig = {
  broadcaster: "pusher",
  key: REACT_APP_PUSHER_KEY,
  cluster: REACT_APP_PUSHER_CLUSTER,
  forceTLS: false,
  encrypted: false,
  wsHost: window.location.hostname,
  wsPort: 6001,
  enabledTransport: ['ws', 'wss']
};

let prodEchoConfig = {
  broadcaster: "pusher",
  key: REACT_APP_PUSHER_KEY,
  cluster: REACT_APP_PUSHER_CLUSTER,
  forceTLS: true,
  encrypted: true,
  wsHost: "admin.schoolbidz.com",
  wssPort: 443,
  enabledTransport: ['ws', 'wss']
}

let echoConfig = window.location.hostname !== "www.schoolbidz.com" ? localEchoConfig : prodEchoConfig;

window.Echo = new Echo(echoConfig);

global.appVersion = packageJson.version;

const rootElement = document.getElementById("root");

render(
  <React.StrictMode>
    <Router history={history}>
      <RootContext.Provider value={new RootStore()}>
        {/* <CacheBuster> */}
          <App />
        {/* </CacheBuster> */}
      </RootContext.Provider>
    </Router>
  </React.StrictMode>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { RootContext };