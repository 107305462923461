import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useContext, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";

import Nav from '../components/Nav';
import { RootContext } from '..';
import History from '../components/History';
import "../styles/OrgProfile.css";
import Footer from '../components/Footer';
import Ripple from "../components/Ripple";
import AuctionItemAlt from '../components/AuctionItemAlt';
import { formatDate, pluralize } from '../utils';

const { REACT_APP_SERVER } = process.env;

const OrgProfile = observer(() => {
  const { id } = useParams<{id: string}>();
  const root = useContext(RootContext);
  const [activeTab, setActiveTab] = useState<string>("desc");

  const getOrganisation = useCallback(async() => {
    await root.organizationStore.findOrganisation(id)
      .catch((e) => {
        console.log(e);
        History.push('/404');
      })
  }, [id, root.organizationStore])

  useEffect(() => {
    getOrganisation();
  },  [getOrganisation]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const showDescription = () => {
    setActiveTab('desc');
  }

  const showActiveAuctions = () => {
    setActiveTab('active')
  }

  const showClosedAuctions = () => {
    setActiveTab('closed')
  }

  return(
    <>
      <Nav />
      <div className="auction-header">
        <div className="auction-title-div">
          {root.organizationStore.quarry.name && (
            <h3 className="">{root.organizationStore.quarry.name}</h3>
          )}
        </div>
      </div>
      {root.organizationStore.busy && (
        <div className="activity-indicator" style={{height: '60vh'}}>
          <Ripple />
        </div>
      )}
      {!root.organizationStore.busy && (
        <div className="organisation-detail">
          <div className="organisation-meta">
            <span className="org-meta-col">
              <p className="org-meta-label">Joined</p>
              {root.organizationStore.quarry.createdOn && (
                <p className="org-meta-value">{formatDate(root.organizationStore.quarry.createdOn)}</p>
              )}
            </span>
            <div className="meta-divider"></div>
            <span className="org-meta-col">
              <p className="org-meta-label">Auctions</p>
              <p className="org-meta-value">{root.organizationStore.quarry.auctions.length + root.organizationStore.quarry.closedAuctions.length}</p>
            </span>
            <div className="meta-divider"></div>
            {root.organizationStore.quarry.website && (
              <span className="org-meta-col">
                <p className="org-meta-label">Website</p>
                <a href={`https://${root.organizationStore.quarry.website}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#283acd'}} className="website-link">
                  <p className="org-meta-value">Website link</p>
                </a>
              </span>
            )}
          </div>
          <div className="org-logo-and-tabs">
            <div className="org-logo-container">
              {root.organizationStore.quarry.id &&(!root.organizationStore.quarry.imageUrl || root.organizationStore.quarry.imageUrl === "avatar_09.jpg") ? (
                <p className="org-initials">{root.organizationStore.quarry.name.substring(0,1)}</p>
              ): (
                <img src={`${REACT_APP_SERVER}/images/profileimages/im${root.organizationStore.quarry.imageUrl}`} alt="logo" />
              )}
            </div>
            <button className={activeTab === 'desc' ? "org-tab active-org-tab" : "org-tab"} onClick={showDescription}>Description</button>
            <button className={activeTab === 'active' ? "org-tab active-org-tab" : "org-tab"} onClick={showActiveAuctions}>Auctions</button>
            <button className={activeTab === 'closed' ? "org-tab active-org-tab": "org-tab"} onClick={showClosedAuctions}>Closed Auctions</button>
          </div>
          {activeTab === 'desc' && (
            <p className="organisation-desc">{root.organizationStore.quarry.about}</p>
          )}
          {activeTab === 'active' && (
            <div className="org-auction-count-label">
              {root.organizationStore.quarry.auctions.length} {pluralize('Auction', root.organizationStore.quarry.auctions.length)}
            </div>
          )}
          {activeTab === 'closed' && (
            <div className="org-auction-count-label">
              {root.organizationStore.quarry.closedAuctions.length} {pluralize('Auction', root.organizationStore.quarry.closedAuctions.length)}
            </div>
          )}
          <div className="org-auctions-grid" style={activeTab === 'active' ? {"display": "flex"} : {"display": "none"}}>
            <div className="auctions-grid">
              {root.organizationStore.quarry.id && root.organizationStore.quarry.auctions.map((item, index) => (
                <AuctionItemAlt auction={item} key={index} disable={false} closedAuction={false} />
              ))}
            </div>
          </div>
          <div className="org-auctions-grid" style={activeTab === 'closed' ? {"display": "flex"} : {"display": "none"}}>
            <div className="auctions-grid">
              {root.organizationStore.quarry.id && root.organizationStore.quarry.closedAuctions.map((item, index) => (
                <AuctionItemAlt auction={item} key={index} disable={true} closedAuction={true} />
              ))}
            </div>
          </div>
        </div>
      )}
      <Footer upper_div={false} />
    </>
  )
})

export default OrgProfile;
