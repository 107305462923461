import React, { useEffect, useState } from "react";
import { RootContext } from "../..";
import Nav from "../../dashboard_components/Nav";
import OrgMemberSidebar from "../../dashboard_components/OrgMemberSidebar";
import "../../dashboard_styles/statement.css";
import OrganizationAuctionData from "../../models/organization-auction-data";
import { auctionStatementColumns, fetchAdminAuctionInfo } from "../../services/dashboardauctions";
import OrgMemberSettingsHeader from "./OrgMemberSettingsHeader";
import CustomTable from "../../components/CustomTable";

export default function OrgMemberDashboard(props: any) {
    const root = React.useContext(RootContext);
    const [auctionDataArray, setAuctionDataArray] = useState<OrganizationAuctionData[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        if (!isFetched) {
            fetchAuctionData();
        }
    })

    const fetchAuctionData = async () => {
        let data = await fetchAdminAuctionInfo(root.authStore.orgMember.organization.id);
        setAuctionDataArray(Object.values(data));
        setIsFetched(true);
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <OrgMemberSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <OrgMemberSettingsHeader title={`Hi ${root.authStore.orgMember.username}, Welcome back`}
                                subtitle={`Lorem ipsum dolor sit amet consectetur adipisicing elicit`} />
                            <div className="statement-cards">
                                <div className="statement-card">
                                    <h1> {auctionDataArray.length} </h1>
                                    <p> Auctions Started</p>
                                </div>
                                <div className="statement-card">
                                    <h1> {auctionDataArray.reduce((a, b) => { return a + b.bids_placed }, 0)} </h1>
                                    <p> Bids This Month</p>
                                </div>
                                <div className="statement-card">
                                    <h1>
                                        {`$${auctionDataArray.reduce((a, b) => { return a + b.amount_raised }, 0)}`}
                                    </h1>
                                    <p> Total Funds Raised</p>
                                </div>
                                <div className="statement-card">
                                    <h1>$0</h1>
                                    <p> Total Earnings</p>
                                </div>
                            </div>

                            <div className="statement-history-full">
                                <div style={{width:'100%', overflowX: 'scroll', height: '100%'}}>
                                    <CustomTable 
                                        columns={auctionStatementColumns}
                                        data={auctionDataArray}
                                        pagination={false}
                                        headerClasses={"dashboard-table-header"}
                                        classes={"dashboard-table"}
                                    />      
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}