import { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../styles/Organizations.css";
import { BiChevronDown } from "react-icons/bi";
import OrganizationItem from "../components/OrganizationItem";
import { RootContext } from "..";
import Ripple from "../components/Ripple";
import HelmetData from "../components/Helmet";

const Organizations = observer(() => {
    const root = useContext(RootContext);

    useEffect(() => {
        root.quietUpdateOrganizations();
    }, [root])

    return (
        <>
            <Nav />
            <HelmetData 
                title="Organizations Believe in Us"
                description="Find a list of all participating schools and Non-Profit organizations to support"
            />
            <div className="organization-header">
                <div className="organizations-title-div">
                    <h2 className="sm:text-[30px]">Organizations Believe in Us</h2>
                    <p>
                    You can find a list of all the participating schools and Non-Profit organizations to support.
                    </p>
                </div>
            </div>
            <div className="organization-content">
                {root.organizationStore.busy && (
                    <div className="activity-indicator">
                        <Ripple />
                    </div>
                )}
                {!root.organizationStore.busy && (
                    <div className="organization-grid">
                        {root.organizationStore.organizations.map((item, index) => (
                            <OrganizationItem organization={item} />
                        ))}
                    </div>
                )}
            </div>
            <Footer upper_div={false} />
        </>
    );
})

export default Organizations;
