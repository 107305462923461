import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { AiOutlineClose } from "react-icons/ai";
import React, { useEffect, useState, createRef } from "react";

import Nav from "../../dashboard_components/Nav";
import OrgSidebar from "../../dashboard_components/OrgSidebar";
import "../../dashboard_styles/teams.css";
import { addOrgMembers, addTeam, getPending, orgMembersColumn, pendingInvitesColumn, teamsColumn } from "../../services/dashboardteam";
import { RootContext } from "../..";
import DashboardPendingOrgMember from "../../models/DashboardPendingOrgMember";
import OrgMember from "../../models/OrgMember";
import CustomTable from '../../components/CustomTable';
import { toast } from "react-toastify";
import Ripple from "../../components/Ripple";

export default function OrgTeams() {
    const [teamName, setTeamName] = useState("");
    const [leader, setLeader] = useState<number>(0);
    const [teamNumber, setTeamNumber] = useState<number>(0);
    const [teamImage, setTeamImage] = useState("");
    const [teamImageSrc, setTeamImageSrc] = useState<string>("");
    const [emails, setEmails] = useState<any>([]);
    const [email, setEmail] = useState<string>("");
    const [pendingInvites, setPendingInvites] = useState<DashboardPendingOrgMember[]>([]);
    const [orgMembers, setOrgMembers] = useState<OrgMember[]>([]);
    const [hideTeamForm, setHideTeamForm] = useState<boolean>(true);
    const [hideMembersForm, setHideMembersForm] = useState<boolean>(true);
    const [busy, setBusy] = useState<boolean>(false);
    const root = React.useContext(RootContext);
    const fileInput = createRef<HTMLInputElement>();

    useEffect(() => {
        getPendingInvites();
    }, [])

    const handleChange = (newValue: any, actionMeta: any) => {
        setEmails(newValue)
    };

    const handleInputChange = (inputValue: string) => {
        setEmail(inputValue);
    }

    const handleKeyDown = (e: any) => {
        if(email.length === 0)
            return;
        switch (e.key) {
            case 'Enter':
            case 'Tab':
                setEmails((prevState: any) => [...prevState, createOption(email)]);
                setEmail(''); 
                e.preventDefault(); 
                break;
            default:
                break;
        }
    }

    const validateMembersForm = () => {
        if(emails.length === 0) {
            toast.info('Please enter an email address we can send the invite to')
            return false;
        }
        return true;
    }

    const generateMembers = () => {
        const data: string[] = []
        emails.forEach((element: any) => {
            data.push(element.value)
        });
        return data;
    }

    const sendNewOrgMemberRequest = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!validateMembersForm())
            return;
        setBusy(true)
        let emails = generateMembers();
        await addOrgMembers(
            emails, root.authStore.admin.organization.id, root.authStore.admin.organization.name
        ).then(() => {
            setBusy(false);
            getPendingInvites();
            toggleMemberForm();
            toast.success('The invite has been sent');
        }).catch(() => {
            setBusy(false);
            toggleMemberForm();
            toast.error("An error occurred while sending the invite. Please try again later.")
        })
    }

    const getPendingInvites = async () => {
        let { data } = await getPending(root.authStore.admin.organization.id);
        let pendingInvitesArray: DashboardPendingOrgMember[] = [];
        data.forEach((element: DashboardPendingOrgMember, i: number) => {
            let member: DashboardPendingOrgMember = { id: i + 1, status: element.status, email: element.email }
            pendingInvitesArray.push(member);
        });
        setPendingInvites(pendingInvitesArray)
        getOrgMembers();
    }

    const getOrgMembers = () => {
        let { organisation_members } = root.authStore.admin.organization;
        let orgMemberArray: OrgMember[] = [];
        organisation_members.forEach((el: any, i: number) => {
            let member: OrgMember = { id: i + 1, name: el.user.name, value: el.user_id, label: el.user.name }
            orgMemberArray.push(member);
        });
        setOrgMembers(orgMemberArray);
    }

    const addNewTeam = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!validateTeamForm())
            return;
        setBusy(true);
        await addTeam(teamName, teamImage, teamNumber, root.authStore.admin.organization.id, leader)
        root.autoLoginModule.autoSignIn();
        setBusy(false);
        toast.success('You have added a new team');
        toggleTeamForm();
    }

    const handleTeamImage = (e: any) => {
        if(e.target.files[0]) {
            setTeamImageSrc(URL.createObjectURL(e.target.files[0]));
            let files: any = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setTeamImage(e.target.result)
            };
            reader.readAsDataURL(files[0]);
        }
    }

    const toggleTeamForm = (e?: any) => {
        if (e != null) {
            e.preventDefault();
        }
        setHideTeamForm(prevState => !prevState);
        setTeamImage("");
        setTeamImageSrc("");
    }

    const toggleMemberForm = (e?: any) => {
        if (e != null) {
            e.preventDefault();
        }
        setHideMembersForm(prevState => !prevState);
        setEmails([]);
    }

    const openFileDialog = (e: any) => {
        e.preventDefault();
        fileInput.current?.click();
    }

    const validateTeamForm = () => {
        if(teamName.length === 0) {
            toast.info('Please enter a name for your team');
            return false;
        }
        if(leader === 0) {
            toast.info('Please pick a team leader');
            return false;
        }
        if(teamImage.length === 0) {
            toast.info('Please pick a display image for your new team');
            return false;
        }
        if(teamNumber <= 0) {
            toast.info('Please enter the number of people on your new team');
            return false;
        }
        return true;
    }

    const createOption = (label: string) => ({
        label,
        value: label,
        __isNew__: true
    });

    return (<React.Fragment>
        <div className="dashboard-popup-container" id="form-popup" hidden={hideTeamForm}>
            <div className="popup-form">
                <form onSubmit={addNewTeam}>
                    <div className="popup-heading">
                        <h2>Add a New Team</h2>
                        <button 
                            className="dashboard-button red-btn"
                            style={{padding: '5px', height: 'fit-content', borderRadius: '2px'}}
                            onClick={toggleTeamForm}
                        > <AiOutlineClose size={20}/></button>
                    </div>

                    <div className="shared-input">
                        <div className="form-input-box-2">
                            <label> Enter Team Name</label>
                            <input type="text" className="text-input-2" name="team_name"
                                id="" placeholder="Team name" onChange={e => setTeamName(e.target.value)} />
                        </div>
                        <div className="form-input-box-2">
                            <label> Select the Team leader</label>
                            <Select options={orgMembers} onChange={(e) => {
                                setLeader(e!.id);
                            }} />
                        </div>
                    </div>
                    <div className="shared-input">
                        <div className="form-input-box-2" style={{display: 'flex', flexDirection: 'column'}}>
                            <label> Select the Team Image</label>
                            <input 
                                type="file" 
                                name="Team Image" 
                                onChange={handleTeamImage} 
                                hidden
                                ref={fileInput}
                            />
                            <div 
                                className="upload-div" 
                                onClick={openFileDialog}
                                style={teamImageSrc?.length !== 0 ? {height: 'fit-content'}: {}}
                            >
                                Click to Upload
                                {teamImageSrc?.length !== 0 && (
                                    <img src={teamImageSrc} alt="team avatar"/>
                                )}
                            </div>
                        </div>
                        <div className="form-input-box-2">
                            <label> Number of Members</label>
                            <input type="number" className="text-input-2" name="number_of_members" onChange={e => setTeamNumber(parseInt(e.target.value))}
                                id="" placeholder="Number of Members" />
                        </div>
                    </div>
                    {busy ? (
                        <div>
                            <Ripple color={'#ff3366'}/>
                        </div>
                    ) : (
                        <button type="submit" className="dashboard-button green-btn" style={{marginTop: '20px'}}>
                            Submit
                        </button>
                    )}
                </form>
            </div>
        </div>

        <div className="dashboard-popup-container" id="form-popup-2" hidden={hideMembersForm}>
            <div className="popup-form">
                <form onSubmit={sendNewOrgMemberRequest} id="addNewMembers">
                    <div className="popup-heading">
                        <h2>Add a New Organization Members</h2>
                        <button 
                            className="dashboard-button red-btn" 
                            onClick={toggleMemberForm}
                            style={{padding: '5px', height: 'fit-content', borderRadius: '2px'}}
                        > 
                            <AiOutlineClose />
                        </button>
                    </div>

                    <div className="form-input-box-2">
                        <label> Add New Organization Members</label>
                        <CreatableSelect
                            isClearable
                            isMulti={true}
                            onChange={handleChange}
                            inputValue={email}
                            onInputChange={handleInputChange}
                            placeholder={'Type email address and press enter...'}
                            value={emails}
                            onKeyDown={handleKeyDown}
                            components={{DropdownIndicator: null}}
                            menuIsOpen={false}
                        />
                    </div>
                    {busy ? (
                        <div>
                            <Ripple color={'#ff3366'}/>
                        </div>
                    ) : (
                        <button type="submit" className="dashboard-button green-btn">
                            Submit
                        </button>
                    )}

                </form>
            </div>
        </div>
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <OrgSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">

                            <div className="flex settings-header">
                                <div>
                                    <h1>Teams & Members</h1>
                                </div>
                                <div className="button-box">
                                    <button className="dashboard-button blue-btn" onClick={toggleTeamForm}>Add Team</button>
                                    <button className="dashboard-button blue-btn" onClick={toggleMemberForm}>Add Members</button>
                                </div>

                            </div>
                            <div className="teams-split-pane">
                                <div className="flex">
                                    <div className="split-table">
                                        <h4> Current Organization Members</h4>
                                        <div style={{width: '100%', overflowX: 'scroll', height: '100%'}}>
                                            <CustomTable 
                                                data={root.authStore.admin.organization.organisation_members} 
                                                columns={orgMembersColumn}
                                                pagination={true}
                                                headerClasses={"dashboard-table-header"}
                                                classes={"dashboard-table"}
                                            />
                                        </div>
                                    </div>
                                    <div className="split-table">
                                        <h4> Pending Invites</h4>
                                        <div style={{width: '100%', overflowX: 'scroll', height: '100%'}}>
                                            <CustomTable 
                                                data={pendingInvites} 
                                                columns={pendingInvitesColumn} 
                                                pagination={true}
                                                headerClasses={"dashboard-table-header"}
                                                classes={'dashboard-table'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tables">
                                    <h4>List of Teams</h4>
                                    <div style={{width: '100%', overflowX: 'scroll', height: '100%'}}>
                                        <CustomTable 
                                            data={root.authStore.admin.organization.groups} 
                                            columns={teamsColumn}
                                            pagination={true}
                                            headerClasses={"dashboard-table-header"}
                                            classes={'dashboard-table'}
                                        />
                                    </div>
                                </div>
                                {/* <div className="info-cards"></div> */}
                            </div>
                            {/* <div className="statement-history-2">
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
}