const TermsAndConditions = (props: {hideHeader: boolean}) => (
  <div className="p-4 bg-white rounded">
    {!props.hideHeader && (
      <h1 className="font-bold text-[28px] mb-4">Terms & Conditions</h1>
    )}
    <h3 className="font-bold text-[22px]">Terms of Service Agreement</h3>
    <h3 className="font-bold text-[22px]">School Bidz Site</h3>
    <p className="mt-3 text-[16px]">Effective Date: Sept 22, 2021</p>
    <p className="mt-2">
      **Important** Please read these terms of service carefully before continuing.
      By entering your password or otherwise accepting these terms of service, 
      you agree to follow and be bound by the terms and conditions of these 
      Terms of Service. If you are entering into these Terms of Service on behalf of
      a company or other legal entity, you represent that you are authorized to do 
      so and have the authority to bind such entity to the terms and conditions of 
      these terms of service. If you do not have such authorization or authority, or 
      if you do not agree to all the terms and conditions in these terms of service, 
      you must not enter your password and may not use Burton Equity controlled 
      and School Bidz Sites.
    </p>
    <p className="mt-3">
      <span className="font-bold">1. Acceptance of Terms. </span>
      Welcome to SCHOOL BIDZ! By using SCHOOL 
      BIDZ Services, you agree to be bound by these “Terms of Service.”
    </p>
    <p className="mt-3">
      <span className="font-bold">2. Definitions. </span>
      In these Terms of Service, the following terms shall have the
      meanings set forth below: (a) “Confidential Information” means any business
      contact, potential business affiliation, information, technical data, or know-
      how, including by example and not limitation but not limited to, that which is
      related to research, products, services, customers, markets, software, 
      developments, inventions, processes, designs, drawings, engineering, 
      marketing or finances provided by one party. Confidential Information does 
      not include the fact that Burton Equity Group and you have entered into a 
      business relationship or information, technical data or know-how which (i) is 
      in possession of the receiving party at the time of disclosure as shown by the
      receiving party’s files and records immediately prior to the time of 
      disclosure; (ii) prior to or after the time of disclosure becomes part of the 
      public knowledge or literature, not as a result of any inaction or action of the 
      receiving party; (iii) is approved for release in writing by the disclosing party;
      (iv) information that qualifies as a Trade Secret.
    </p>
    <p className="mt-3">
      (b) “Cure Period” means the period of time given to a party to cure any 
      breach of a material term of the Terms of Service. The Cure Period is thirty 
      (30) days following receipt of the written notice specifying the breach. If such
      breach is not reasonably curable within such thirty (30) day period, the non-
      breaching party shall not unreasonably withhold approval of a longer Cure 
      Period provided the breaching party promptly commences to cure such 
      breach and continues to diligently pursue a cure of such breach. The 
      breaching party will provide written notice to the non-breaching party once 
      the breaching party believes it has substantially cured the breach that were 
      described in the breach notice to the breaching party. Within fourteen (14) 
      days of receipt of the notice of cure, the non-breaching party shall provide 
      written certification.
    </p>
    <p className="mt-3">
      (i) such breaches have been remedied or (ii) such breaches are not remedied
      and describe in reasonable detail the breaches that remain and whether or 
      not the Cure Period shall be extended ©Burton Equity Group, Inc. 2 or the 
      Terms of Service shall be terminated. The Cure Period ends upon the earlier 
      of: (1) such thirty (30) days or such longer cure period as the non-breaching 
      may approve as provided above; and (2) by written certification of cure or 
      termination.
    </p>
    <p className="mt-3">
      (c) “You” means the entity who agrees to the Terms of Service as the party 
      that will be granted a license herein.
    </p>
    <p className="mt-3">
      (d) “Related Parties” means the members, agents, employees, and/or 
      assigns of Burton Equity Group.
    </p>
    <p className="mt-3">
      (e) “SCHOOL BIDZ Services” means all the content and services offered in 
      conjunction with SCHOOL BIDZ Team and Campaign Application Sites.
    </p>
    <p className="mt-3">
      (f) “Terms of Service” means these terms and conditions that control your 
      use of SCHOOL BIDZ Services.
    </p>
    <p className="mt-3">
      (g) “SCHOOL BIDZ” means the web domain name www.schoolbidz.com and 
      the related cloud-based applications called www.schoolbidz.com.
    </p>
    <p className="mt-3">
      (h) “Burton Equity Group” means Burton Equity Group, LLC., the owner of 
      SCHOOL BIDZ and the provider of SCHOOL BIDZ Services.
    </p>
    <p className="mt-3">
      (i) “Trade Secret” means any and all documents or information relating to 
      Burton Equity Group or you that meet the definition of a Trade Secret under 
      Illinois Trade Secrets Act (P.A. 85-366, effective Jan. 1, 1988). Examples of 
      Trade Secrets may include, but are not limited to: software architecture, 
      formulas, patterns, compilations, programs, devices, methods, techniques or 
      processes, product costs and mark-up information, and service costs.
    </p>
    <p className="mt-3">
      <span className="font-bold">3. Description of Services.</span> Burton Equity Group provides information 
      services in the nature of resources and tools related to change leadership 
      and performance improvement. Any new features added to SCHOOL BIDZ 
      Services will also be subject to these Terms of Service, as they may be 
      occasionally modified. Please review these Terms of Service from time to 
      time so that you will be aware of any changes.
    </p>
    <p className="mt-3">
      Although Burton Equity Group will attempt to notify our users of significant 
      changes to SCHOOL BIDZ Services or Terms of Service, Burton Equity Group 
      may modify SCHOOL BIDZ Services for any reason, and without notice. 
      Burton Equity Group will notify you of any changes in the Terms of Service 
      via email to the email you provided to Burton Equity Group. Burton Equity 
      Group may also terminate SCHOOL BIDZ Services with or without notice, and
      without liability to you, any other user, or any third party.
    </p>
    <p className="mt-3">
      Burton Equity Group grants to you, and you hereby accept, a personal non-
      exclusive, object code license to use SCHOOL BIDZ in the United States, 
      according to the terms of these Terms of Service (“Grant of License”). Any 
      license granted under these Terms of Service is conditioned on you 
      remaining in compliance with all terms of these Terms of Service including 
      your full and continued payment of all fees and costs under these Terms of 
      Service.
    </p>
    <p className="mt-3">
      <span className="font-bold">4. Your Obligations.</span> You are responsible for obtaining access to SCHOOL 
      BIDZ and for paying any fees involved in obtaining that access (such as 
      Internet service provider or airtime charges and the cost of the equipment 
      you use to access SCHOOL BIDZ). You will also pay license fees to Burton 
      Equity Group that equal the amounts stated in the agreement to purchase 
      SCHOOL BIDZ signed by you and Burton Equity Group. If you choose to 
      multiple instances of use, you must obtain multiple subscriptions for 
      additional fees.
    </p>
    <p className="mt-3">
      It is your responsibility to notify Burton Equity Group in writing if you dispute 
      any billing entry or computation. All entries will be deemed to be fair and 
      correct by both Burton Equity Group and you if no written notice of dispute is
      received by Burton Equity Group within one (1) year of the date of the 
      invoice. You shall be responsible for all sales, use, value added, or other 
      similar taxes.
    </p>
    <p className="mt-3">
      As a user of SCHOOL BIDZ Services, you agree to not use either the SCHOOL 
      BIDZ Team or School Bidz Site to:
    </p>
    <p className="mt-3">
      A. Post, transmit or otherwise make available any material that is unlawful, 
      harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, 
      obscene, libelous, invasive of another's privacy, hateful, or racially, 
      ethnically, or otherwise objectionable;
    </p>
    <p className="mt-3">
      B. Harm minors in any way;
    </p>
    <p className="mt-3">
      C. Impersonate any person or entity, including, but not limited to, an official 
      of Burton Equity Group, or falsely state or otherwise misrepresent your 
      affiliation with a person or entity;
    </p>
    <p className="mt-3">
      D. Disguise the origin of any material posted on or transmitted by Burton 
      Equity Group;
    </p>
    <p className="mt-3">
      E. Post, transmit or otherwise make available any material that you do not 
      have a right to make available or that infringes any patent, trademark, trade 
      secret, copyright or other proprietary rights of any party;
    </p>
    <p className="mt-3">
      F. Post, transmit or otherwise make available any unsolicited or unauthorized
      advertising, promotional materials, "junk mail," "spam," "chain letters," 
      "pyramid schemes," or any other form of solicitation, except in those areas 
      (such as shopping rooms) that are designated for such purpose;
    </p>
    <p className="mt-3">
      G. Post, transmit or otherwise make available any material that contains 
      software viruses or any other computer code, files or programs designed to 
      interrupt, destroy or limit the functionality of any computer software or 
      hardware or telecommunications equipment;
    </p>
    <p className="mt-3">
      H. "Stalk" or otherwise harass another; or
    </p>
    <p className="mt-3">
      I. Collect or store personal data about other users.
    </p>
    <p className="mt-3">
      The rights granted you under these Terms of Service are limited to the 
      express terms hereof. Specifically, no right is granted to you to sell, 
      reproduce, publish, license, distribute, disseminate, sublicense, rent, and/or 
      lease any portion of SCHOOL BIDZ or SCHOOL BIDZ Services. Further, under 
      no circumstances does Burton Equity Group grant to you any right to reverse
      engineer, decompile, disassemble, modify, translate, or make any attempts 
      to discover the source code of SCHOOL BIDZ. The rights granted to you 
      herein are restricted for use solely by you.
    </p>
    <p className="mt-3">
      <span className="font-bold">Term of Services.</span> The term of the Grant of License under Section 3 shall 
      become effective upon your execution of these Terms of Service and full 
      payment of the fees under Section 4 and shall continue for a period of one 
      (1) year thereafter (“Term”). The Grant of License remains conditional and is 
      contingent on timely payment of all obligations by you to Burton Equity 
      Group. The parties may seek to renew these Terms of Service for additional 
      one or more one (1) year Terms. In order to renew, the party desiring 
      renewal must inform the other in writing of its desire to renew at least three 
      (3) months prior to expiration, and the other party must confirm such 
      renewal in writing at least one (1) month prior to expiration. Expiration 
      without renewal is termination.
    </p>
    <p className="mt-3">
      You may terminate these Terms of Service upon written notice by you 
      delivered to Burton Equity Group if Burton Equity Group breaches any 
      material term of these Terms of Service and fails to substantially correct the 
      breach within the Cure Period. Burton Equity Group shall refund any fees (if 
      any) already by you that relate to the part of the Term following the 
      termination.
    </p>
    <p className="mt-3">
      Burton Equity Group may terminate these Terms of Service upon written 
      notice to you if you breach a material term of these Terms of Service 
      (including the payment obligations under Section 4) and fails to cure the 
      breach within thirty (30) days following written notice specifying the breach. 
      Burton Equity Group may also, in its sole discretion and at any time, 
      discontinue providing SCHOOL BIDZ Services, or any part thereof, with or 
      without notice.
    </p>
    <p className="mt-3">
      Upon termination of these Terms of Service for any reason or upon the 
      expiration of the then current Term, all rights granted to you under these 
      Terms of Service cease including the Grant of License under Section 3, 
      including any perceived right you believe may remain to access SCHOOL 
      BIDZ Services after termination. Upon termination Burton Equity Group may 
      immediately deactivate or delete your account and all related information 
      and files in your account and/or bar any further access to such files or 
      SCHOOL BIDZ Services. Further, you agree that Burton Equity Group shall not
      be liable to you or any third-party for any termination of your access to 
      SCHOOL BIDZ Services. Notwithstanding anything else herein, SCHOOL BIDZ 
      may retain your data stored by SCHOOL BIDZ following termination for up to 
      twenty-four (24) months at Burton Equity Group’s discretion. Such data may 
      be provided in .pdf formatting to you upon your written request. After that 
      twenty-four (24) month period, the data will be destroyed without further 
      notice to you.
    </p>
    <p className="mt-3">
      <span className="font-bold">6. Privacy Policy.</span> Burton Equity Group’s Privacy Policy governs the 
      collection, use, and disclosure of all Personal Data (personally identifiable 
      information about you, such as your full name, birth date, e-mail address or 
      mailing address, phone number and any other information that can be used 
      to identify or contact you). For more information, see the full privacy policy 
      posted at www.schoolbidz.com/privacy.
    </p>
    <p className="mt-3">
      <span className="font-bold">7. Member Account, Password, and Security.</span> If you create an account 
      on SCHOOL BIDZ, you will receive a link to create a password. Your account 
      designation is your email address and is good for one instance of use for 
      SCHOOL BIDZ Services. You are responsible for maintaining the 
      confidentiality of the password and account and are fully responsible for all 
      activities that occur under your password or account. If you would like 
      multiple instances of use, you must obtain multiple subscriptions. You agree 
      to: (a) provide true, accurate, current and complete information about 
      yourself as prompted by the registration form (your “Registration Data”); (b) 
      maintain and promptly update the Registration Data to keep it true, 
      accurate, current and complete; (c) immediately notify Burton Equity Group 
      of any unauthorized use of your password or account or any other breach of 
      security; and (d) ensure that you exit from your account at the end of each 
      session. If you provide any information that is untrue, inaccurate, not 
      current, or incomplete, or Burton Equity Group has reasonable grounds to 
      suspect that such information is untrue, inaccurate, not current, or 
      incomplete, Burton Equity Group has the right to suspend or terminate your 
      account and refuse your use of SCHOOL BIDZ Services. Any Personal Data in 
      your Registration Data will be collected, used, and disclosed as provided in 
      the Privacy Policy.
    </p>
    <p className="mt-3">
      <span className="font-bold">
        8. Use of SCHOOL BIDZ Services in Other States and Countries. 
      </span> You agree to comply with all local rules regarding online conduct and content.
    </p>
    <p className="mt-3">
      <span className="font-bold">
      9. Use of SCHOOL BIDZ Services by Children under 13. 
      </span> SCHOOL BIDZ is not directed to or intended for users under 13 years of age.
    </p>
    <p className="mt-3">
      <span className="font-bold">
      10. Burton Equity Group Intellectual Property. 
      </span> As between you and 
      Burton Equity Group, Burton Equity Group is the owner of all content on 
      SCHOOL BIDZ Services —graphics, text and other elements— and all other 
      material posted, transmitted or otherwise made available through SCHOOL 
      BIDZ Services, including all copyrights and other intellectual property rights 
      embodied therein.
    </p>
    <p className="mt-3">
      Except in connection with your own use of SCHOOL BIDZ Services, Burton 
      Equity Group content and other materials may not be reproduced, 
      distributed, displayed, reprinted, or retransmitted in whole or in part without 
      the express written consent of Burton Equity Group. “Burton Equity 
      Group™”, “SCHOOL BIDZ™” and other marks used on SCHOOL BIDZ are 
      trademarks of Burton Equity Group and may not be used without the express
      written permission of Burton Equity Group.
    </p>
    <p className="mt-3">
      <span className="font-bold">11. Use and Storage of User Material.</span> Burton Equity Group may 
      establish general practices and limits concerning use of SCHOOL BIDZ 
      Services, including limits on: the time that user-posted material will be 
      available through SCHOOL BIDZ Services; the time that user-posted material 
      will be stored by Burton Equity Group; the maximum size of any single 
      posted event; the maximum disk space that will be allotted on Burton Equity 
      Group servers on your behalf; the maximum number of times you may 
      access SCHOOL BIDZ Services in a given period of time; and the maximum 
      duration of each access. You acknowledge that Burton Equity Group reserves
      the right to terminate accounts that are inactive for an extended period. You 
      further acknowledge that Burton Equity Group reserves the right to change 
      these general practices and limits at any time, in its sole discretion, with or 
      without notice.
    </p>
    <p className="mt-3">
      Burton Equity Group shall not own any right, title, or interest to the data as 
      inputted into SCHOOL BIDZ Services (“Inputted Data”). As between Burton 
      Equity Group and you, you shall own all such Inputted Data. However, Burton
      Equity Group may collect information and data regarding how SCHOOL BIDZ 
      Services and SCHOOL BIDZ are used, processes information, and otherwise 
      performs. Burton Equity Group has the right to access and monitor the use of
      the Inputted Data and other actions and data within SCHOOL BIDZ Services 
      and the use of SCHOOL BIDZ Services by you. All information collected by 
      Burton Equity Group will be used by Burton Equity Group either to better 
      SCHOOL BIDZ Services or to help provide SCHOOL BIDZ Services to you. 
      Burton Equity Group will not share with third parties other than in the 
      aggregate without any identifying information.
    </p>
    <p className="mt-3">
      Any information or data produced by Burton Equity Group regarding the 
      performance of SCHOOL BIDZ Services or analysis and compilations of 
      aggregate data collected by Burton Equity Group is considered proprietary 
      and Confidential Information owned by Burton Equity Group.
    </p>
    <p className="mt-3">
      <span className="font-bold">12. Third Party Intellectual Property.</span> Burton Equity Group respects the 
      intellectual property rights of others. Burton Equity Group will respond 
      promptly to remove material that infringes another person’s copyright or 
      other intellectual property right.
    </p>
    <p className="mt-3">
      <span className="font-bold">13. Indemnity.</span> You agree to indemnify and hold harmless Burton Equity 
      Group, and its subsidiaries, affiliates, officers, agents, and employees, from 
      any claim or demand by any third party arising out of material you post, 
      transmit or otherwise make available through SCHOOL BIDZ Services, your 
      use of SCHOOL BIDZ Services, your connection to SCHOOL BIDZ Services, 
      your violation of these Terms of Service, or your violation of any rights of 
      another person or entity.
    </p>
    <p className="mt3">
      <span className="font-bold">14. Use of Services.</span> You agree to use SCHOOL BIDZ Services for your use 
      only. You agree not to reproduce, duplicate, copy, sell, resell, or exploit for 
      any commercial purposes, any portion of SCHOOL BIDZ Services, access to 
      SCHOOL BIDZ, or any material available through SCHOOL BIDZ Services. If 
      you want to make commercial use of SCHOOL BIDZ, you must enter into an 
      express written agreement with Burton Equity Group to do so. Please contact
      us at rtucci@leapacademy.net for more information.
    </p>
    <p className="mt-3 font-bold">
      14 .DISCLAIMER OF WARRANTIES. YOU EXPRESSLY UNDERSTAND 
      AND AGREE THAT:
    </p>
    <p className="mt-3">
      YOUR USE OF SCHOOL BIDZ SERVICES IS AT YOUR SOLE RISK. SCHOOL BIDZ 
      SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. 
      BURTON EQUITY GROUP EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY 
      KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR 
      PURPOSE AND NON- INFRINGEMENT.
    </p>
    <p className="mt-3">
      BURTON EQUITY GROUP MAKES NO WARRANTY THAT (I) SCHOOL BIDZ 
      SERVICES WILL MEET YOUR REQUIREMENTS, (II) SCHOOL BIDZ SERVICES 
      WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE 
      RESULTS THAT MAY BE OBTAINED FROM THE USE OF SCHOOL BIDZ 
      SERVICES WILL BE ACCURATE OR RELIABLE, (IV) THE QUALITY OF ANY 
      PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR 
      OBTAINED BY YOU THROUGH SCHOOL BIDZ SERVICES WILL MEET YOUR 
      EXPECTATIONS, AND (V) ANY ERRORS IN THE SOFTWARE WILL BE 
      CORRECTED
    </p>
    <p className="mt-3">
      ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
      OF SCHOOL BIDZ SERVICES IS USED AT YOUR OWN DISCRETION AND RISK 
      AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR 
      COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE 
      DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, 
      WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM BURTON EQUITY 
      GROUP OR THROUGH OR FROM SCHOOL BIDZ SERVICES SHALL CREATE ANY 
      WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF SERVICE.
    </p>
    <p className="mt-3">
      YOU UNDERSTAND AND AGREE THAT SCHOOL BIDZ SERVICES ARE 
      PROVIDED “AS IS” AND THAT BURTON EQUITY GROUP ASSUMES NO 
      RESPONSIBILITY FOR THE ACCURACY, TIMELINESS, DELETION OR MIS-
      DELIVERY OF ANY USER COMMUNICATION, INFORMATION, OR 
      CONFIGURATION.
    </p>
    <p className="mt-3">
      You understand that you, and not Burton Equity Group, are entirely 
      responsible for all material that you post, transmit or otherwise make 
      available through SCHOOL BIDZ Services. Burton Equity Group does not 
      review or control the material that others post, transmit or otherwise make 
      available through SCHOOL BIDZ Services, and accordingly Burton Equity 
      Group does not guarantee the accuracy, integrity, or quality of such 
      material. You acknowledge that although Burton Equity Group does not 
      review material posted on, transmitted, or otherwise made available through
      SCHOOL BIDZ Services by others, Burton Equity Group and its designees 
      shall have the right (but not the obligation) in their sole discretion to refuse 
      or remove any material that is available through SCHOOL BIDZ Services.
    </p>
    <p className="mt-3">
      <span className="font-bold">15. LIMITATION OF LIABILITY. </span> YOU EXPRESSLY UNDERSTAND AND AGREE
      THAT BURTON EQUITY GROUP SHALL NOT BE LIABLE FOR ANY : (I) DIRECT 
      DAMAGES IN EXCESS OF THE ACTUAL FEE(S) PAID BY YOU FOR THE THEN 
      CURRENT TERM UNDER SECTION 5 OR, (II), INDIRECT, INCIDENTAL, SPECIAL, 
      CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED 
      TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER 
      INTANGIBLE LOSSES (EVEN IF BURTON EQUITY GROUP HAS BEEN ADVISED 
      OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR 
      THE INABILITY TO USE SCHOOL BIDZ SERVICES; (II) THE COST OF 
      PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM 
      ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED 
      OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR 
      FROM SCHOOL BIDZ SERVICES; (III) UNAUTHORIZED ACCESS TO OR 
      ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR 
      CONDUCT OF ANY THIRD PARTY ON SCHOOL BIDZ SERVICES; OR (V) ANY 
      OTHER MATTER RELATING TO SCHOOL BIDZ SERVICES.
    </p>
    <p className="mt-3">
      <span className="font-bold">16. EXCLUSIONS AND LIMITATIONS.</span> SOME JURISDICTIONS DO NOT 
      ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR 
      EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. 
      ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTIONS 14 AND 15 
      MAY NOT APPLY TO YOU.
    </p>
    <p className="mt-3">
      <span className="font-bold">17. Non-Disclosure Confidential Information.</span> Burton Equity Group and you 
      agree not to use the Confidential Information disclosed to it by the other 
      party for its own use or for any purpose except to carry out the intent of 
      SCHOOL BIDZ Services. Neither will disclose the Confidential Information of 
      the other to third parties or to the first party’s employees or agents without 
      the written consent of the other party, except to those employees or agents 
      who are required to have the information in order to carry out the 
      contemplated business. Each agrees that it will take all reasonable steps, 
      including all steps that it would take to protect its own Confidential 
      Information, to protect the secrecy of and avoid disclosure or use of 
      Confidential Information of the other in order to prevent it from falling into 
      the possession of unauthorized persons. Each agrees to immediately notify 
      the other in writing of any misuse or misappropriation of such Confidential 
      Information.
    </p>
    <p className="mt-3">
      <span className="font-bold">Trade Secrets.</span> Burton Equity Group and you agree that except as required 
      by the scope of these Terms of Service or expressly agreed upon in writing 
      by the parties, neither will use, directly or indirectly disclose, or publish any 
      of the other’s Trade Secrets. Both parties acknowledge that any unpermitted
      use or disclosure of the other’s Trade Secret will cause the Trade Secret 
      holder irreparable damage for which remedies other than injunctive relief will
      be inadequate and that the Trade Secret holder shall be entitled to injunctive
      relief or other equitable relief enjoining such use or disclosure, without the 
      posting of a bond or other security, in addition to any other remedies 
      available by law or under these Terms of Service. The term of this covenant 
      not to disclose Trade Secrets is unlimited and survives the termination of 
      these Terms of Service.
    </p>
    <p className="mt-3 font-bold">18. Miscellaneous</p>
    <p className="mt-3">
      <span className="font-bold">Governing Law, Venue, and Jurisdiction.</span> These Terms of Service shall be
      governed by and construed in accordance with the laws of the State of 
      Illinois, without reference to conflict of law principles. Venue and jurisdiction 
      for any federal or state court litigation or any alternative dispute resolution 
      including mediation and arbitration shall be in Cook County, State of Illinois.
    </p>
    <p className="mt-3">
      <span className="font-bold">Entire Agreement.</span> These Terms of Service contain the entire 
      understanding of the parties with respect to the subject matter hereof, and 
      supersedes any prior agreement, understanding and communication 
      between the parties, whether written or oral, with respect to such subject 
      matter. These Terms of Service can be amended only in writing signed by 
      the parties.
    </p>
    <p className="mt-3">
      <span className="font-bold">Transfer and Assignment.</span> Except as otherwise provided in these Terms of
      Service, you may not assign or transfer SCHOOL BIDZ Services or these 
      Terms of Service to a third party without the prior written consent of Burton 
      Equity Group, which may not be unreasonably withheld or delayed. Any 
      merger that involves you, any sale or transfer of more than fifty percent 
      (50%) of its voting stock or any sale of all or substantially all of the assets of 
      you shall be considered a prohibited assignment or transfer of SCHOOL BIDZ 
      or SCHOOL BIDZ Services, and consent from Burton Equity Group would be 
      required hereunder. Burton Equity Group is permitted to assign or otherwise 
      transfer these Terms of Service to any third party without your consent. 
      Subject to the foregoing, these Terms of Service shall be binding upon and 
      shall inure to the benefit of the parties hereto, and their respective heirs, 
      representatives, successors, and permitted assignees.
    </p>
    <p className="mt-3">
      <span className="font-bold">Severability; No Waiver.</span> In the event that any term or condition of these 
      Terms of Service is determined to be invalid, illegal, or otherwise 
      unenforceable, such determination shall have no effect on the other terms 
      and conditions, which shall continue to be binding upon the parties hereto. 
      Lack of enforcement of any term or condition in these Terms of Service shall 
      not be construed as a waiver of any rights conferred by such term or 
      condition.
    </p>
    <p className="mt-3">
      <span className="font-bold">Relationship. </span>
      No joint venture, partnership, employment, or agency 
      relationship exists between you and Burton Equity Group as a result of these 
      Terms of Service or use of or access to SCHOOL BIDZ Services. Each party 
      shall have sole responsibility for payment to its employees and its 
      subcontractors, including all tax payments and report obligations and shall 
      indemnify the other for all such expenses and obligations.
    </p>
    <p className="mt-3">
      <span className="font-bold">Written Permission Required for Consultants.</span> Before you are permitted
      to hire a consultant to render assistance in the setup or operation of SCHOOL
      BIDZ Services, you must receive prior written approval from Burton Equity 
      Group. In order to request approval, you must first provide Burton Equity 
      Group with the name and address of the consultant, along with a description 
      of the actions said consultant will take on your behalf. Burton Equity Group 
      reserves the right to require that the consultant sign a Non-Disclosure 
      Agreement, reasonably refuse to allow a specific consultant to render 
      assistance to you or allow a consultant access to SCHOOL BIDZ Services.
    </p>
    <p className="mt-3">
      <span className="font-bold">Survival Clause.</span> All duties and responsibilities of any party, which, either 
      expressly or by their nature, extend into the future, shall extend beyond and 
      survive the end of the Term or cancellation of these Terms of Service. In 
      addition, the expiration or earlier termination of these Terms of Service shall 
      not relieve either party of obligations incurred prior to the termination date.
    </p>
    <p className="mt-3">
      <span className="font-bold">Notices Clause.</span> All notices or communications required or permitted as a 
      part of these Terms of Service shall be in writing (unless another verifiable 
      medium is expressly authorized) and shall be deemed delivered when: (1) 
      Actually received, or; (2) E-Mailed to the receiving party, (3) With a duplicate 
      communication mailed to that party’s last known address, which will be 
      deemed received (10) days after deposit with the United States postal 
      service authorized mail center with proper postage (certified mail, return 
      receipt requested) affixed and addressed to the respective other party if not 
      actually received.
    </p>
    <p className="mt-3">
      <span className="font-bold">Alternative Dispute Resolution.</span> In the event of a dispute between the 
      parties arising under or in connection with these Terms of Service, both 
      parties agree to engage in mediation for the outstanding issues prior to any 
      lawsuit being filed by either party. Mediation will take place in Cook County, 
      State of Illinois. The failure of Burton Equity Group to exercise or enforce any
      right or provision of the Terms of Service shall not constitute a waiver of 
      such right or provision or any other right or provision
    </p>
  </div>
);

export default TermsAndConditions;;