import Cookies from "js-cookie";

import CreditPack from "../models/credit-pack";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;
let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("customerToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
        "Authorization": `Bearer ${token}`
    }
  })
}

const fetchCreditPacks = async() => {
  setAxiosInstance();

  try {
    const { data } = await axiosInstance.get('/api/credits');
    return parseCreditPackJson(data);
  } catch (error) {
    throw error
  }
}

const purchaseCreditPack = async(packId: number, packName: string, authorizeResponse: any) => {
  setAxiosInstance();
 
  const pack = { 
      credit_name: packName, credit_id: packId, 
      opaqueDataDescriptor: authorizeResponse.opaqueData.dataDescriptor,
      opaqueDataValue: authorizeResponse.opaqueData.dataValue 
    }
  try {
    return await axiosInstance.post('/api/transactions', pack);
  } catch (error) {
    throw error;
  }
}

const parseCreditPackJson = (json: any): CreditPack[] => {
  let creditPacks: CreditPack[] = [];
  json.forEach((element: any) => {
    const item: CreditPack = {
      id: element.id, name: element.name, numberOfCredits: element.number_of_credits,
      price: element.price, stripePrice: element.stripe_price, label: element.label
    }
    creditPacks.push(item);
  });
  return creditPacks;
}

export {
  fetchCreditPacks,
  purchaseCreditPack
}