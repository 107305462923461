import React, { createRef, useEffect, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { observer } from "mobx-react-lite";
import Cookies from 'js-cookie';


import Crop from "../../dashboard_components/Crop";
import "../../dashboard_styles/dashboard.css";
import "../../dashboard_styles/account_settings.css";
import Nav from '../../dashboard_components/Nav';
import OrgSidebar from '../../dashboard_components/OrgSidebar';
import OrgSettingsHeader from './OrgSettingsHeader';
import { RootContext } from '../..';
import { updateAdminDetails } from '../../services/dashboardaccountsettings';
import OrgDashboardSettingsForm from '../../models/org-dashboard-settings-form';
import Ripple from '../../components/Ripple';
import { updateAvatar, deleteUser } from '../../services/auth';
import DeleteModal from '../../components/DeleteModal';
import history from '../../components/History';



const { REACT_APP_SERVER } = process.env;

const OrgAccountSettings = observer(() => {
    const root = React.useContext(RootContext);
    const fileInput = createRef<HTMLInputElement>();
    const [webAddress, setWebAddress] = useState<string>(root.authStore.admin.organization.website);
    const [facebookAddress, setFacebookAddress] = useState<string>(root.authStore.admin.organization.facebook_address);
    const [twitterAddress, setTwitterAddress] = useState<string>(root.authStore.admin.organization.twitter_address);
    const [about, setAbout] = useState<string>(root.authStore.admin.organization.about);
    const [busy, setBusy] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [newAvatar, setNewAvatar] = useState<any>(null);
    const [showCrop, setShowCrop] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [cropImage, setCropImage] = useState<string>(''); 
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [street, setStreet] = useState('');


    const splitLocationInfo = () => {
        const locationInfo = root.authStore.admin.organization.location.split(',');
        setStreet(locationInfo[0]);
        setCity(locationInfo[1]);
        setState(locationInfo[2]);
        setZip(locationInfo[3]);
    }

    useEffect(() => {
        if(root.authStore.admin.organization.location)
            splitLocationInfo();
    }, [])
 
    const generateAvatarForm = () => (
        {
            'profile_image1': file,
            'userId': root.authStore.admin.id,
            'reason': 'update_user_details'
        }
    )

    const generateAccountForm = () => {
        const location = `${street},${city},${state},${zip}`;
        return {
            webAddress, location, facebookAddress, twitterAddress, about, profile_photo1: file,
        }
    }

    const validateWebAddress = () => {
        if(!webAddress)
            return false;
        return true;
    }

    const validateLocation = () => {
        if(!street)
            return false;
        else if(!city)
            return false;
        else if(!state)
            return false;
        else if(!zip)
            return false;
        return true;
    }
    
    const validateAbout = () => {
        if(!about)
            return false;
        return true;
    }

    const validateTwitter = () => {
        if(!twitterAddress)
            return false;
        return true;
    }

    const validateFacebook = () => {
        if(!facebookAddress)
            return false;
        return true;
    }

    const validateInfoForm = () => {
        if(!validateAbout() && !validateFacebook() && 
            !validateLocation() && !validateTwitter() && !validateWebAddress())
            return false;
        return true;
    }

    const updateProfileInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setBusy(true);
        let orgForm: OrgDashboardSettingsForm = generateAccountForm();
        let avatarForm = generateAvatarForm()
        const token = Cookies.get('adminToken');

        if(file)
            await updateAvatar(token, avatarForm)
                .then(() => {
                    toast.success("Your profile photo has been updated");
                    setFile(null);
                })
                .catch(() => {
                    toast.error("An error occurred while updating your profile photo. Please try again later")
                })
        if(validateInfoForm())
            await updateAdminDetails(orgForm, root.authStore.admin.organization.id)
                .then((res) => {
                    toast.success(res)
                })
                .catch(() => {
                    setBusy(false);
                    toast.error("An error occured while updating your information. Please try again later.")
                });
        root.authStore.refreshAdmin();
        setBusy(false);
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files: FileList = e.currentTarget.files!;
        setShowCrop(true);
        setCropImage(URL.createObjectURL(files[0]));
    }

    const hideCrop = () => {
        setShowCrop(false);
    }

    const saveCroppedImage = async(canvas: HTMLCanvasElement) => {
        canvas.toBlob(file => {
            setNewAvatar(URL.createObjectURL(file));
            handleFile(file!);
        }, 'image/jpeg')
    }

    const handleFile = (file: Blob) => {
        if (validateFile(file)) {
            let reader = new FileReader();
            reader.onload = e => { setFile(e.target?.result) }
            reader.readAsDataURL(file);
        }
    }

    const validateFile = (file: Blob): boolean => {
        if (file.type === 'application/pdf') {
            return false;
        }
        else if (file.size > 100000000) {
            return false;
        }
        return true;
    }

    const handleUploadPress = (e: React.MouseEvent) => {
        e.preventDefault();
        fileInput.current?.click();
    }

    const deleteAccount = async() => {
        const token = Cookies.get("customerToken");
        try {
            setBusy(true)
            await deleteUser(root.authStore.user.id, token)
                .then(() => {
                    history.push('/');
                    root.authStore.signOut();
                });
        } catch (error) {
            setBusy(false);
            toast.error("An error occurred while attempting to delete your account. Please try again later")
        }
    }

    return (
        <div className="page-container">
            <Nav />
            <Crop 
                hidden={showCrop} 
                hideCrop={hideCrop} 
                imageSrc={cropImage}
                saveCroppedImage={saveCroppedImage}
            />
            <div className="page-content-box">
                <OrgSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <OrgSettingsHeader
                                title="Account Settings"
                                subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elicit" />

                            <div className="settings-boxes">
                                <div className="form">
                                    <div className="setting-box-1">
                                        <div className="settings-user-profile" style={{background: 'linear-gradient(to right, #ffab28, #ffc728)'}}>
                                            {!file && root.authStore.admin.image_url === 'avatar_01.jpg' && (
                                                <p>{root.authStore.admin.username.substring(0, 1)}</p>
                                            )}
                                            {!file && root.authStore.admin.image_url !== 'avatar_01.jpg' &&
                                                <img src={`${REACT_APP_SERVER}/images/profileimages/im${root.authStore.admin.image_url}`} alt="avatar" />
                                            }
                                            {file && <img src={newAvatar} alt="avatar" />}
                                            <button
                                                className="upload-btn"
                                                onClick={handleUploadPress}
                                            >
                                                <BsPencil size={20} />
                                            </button>
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleFileSelect}
                                                ref={fileInput}
                                            />
                                        </div>
                                        <div className="form-input-box">
                                            <input type="text" className="text-input username" name="Organization Name"
                                                defaultValue={root.authStore.admin.organization.name} disabled />
                                        </div>
                                        <div className="form-input-box">
                                            <input type="text" className="text-input" name="email"
                                                defaultValue={root.authStore.admin.username} disabled />
                                        </div>
                                        {root.authStore.admin.organization.location && (
                                            <div className="form-input-box">
                                                <input type="text" className="text-input" name="location"
                                                    defaultValue={root.authStore.admin.organization.location} disabled />
                                            </div>
                                        )}
                                        <button 
                                            className="delete-btn" 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            Delete Account
                                        </button>
                                    </div>
                                    <div className="setting-box-2">
                                        <h2>Organization Information</h2>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input
                                                    id="web_address"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="web_address"
                                                    placeholder="Website (https://someorganization.com)"
                                                    autoComplete={"off"}
                                                    defaultValue={root.authStore.admin.organization.website}
                                                    onChange={e => setWebAddress(e.target.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div">
                                                <input
                                                    type="text"
                                                    className="text-input-2"
                                                    name="location"
                                                    id="location"
                                                    placeholder="Street information"
                                                    defaultValue={street}
                                                    onChange={e => setStreet(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input
                                                    id="city"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="city"
                                                    placeholder="City"
                                                    defaultValue={city}
                                                    onChange={e => setCity(e.target.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div" >
                                                <input
                                                    id="state"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="state"
                                                    placeholder="State"
                                                    defaultValue={state}
                                                    onChange={e => setState(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div" style={{width: '48%'}}>
                                                <input
                                                    id="zip_code"
                                                    type="text"
                                                    className="text-input-2"
                                                    name="zip_code"
                                                    placeholder="Zipcode"
                                                    defaultValue={zip}
                                                    onChange={e => setZip(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-input-div">
                                            <textarea
                                                placeholder="Enter your About information"
                                                rows={5}
                                                defaultValue={root.authStore.admin.organization.about}
                                                onChange={e => setAbout(e.target.value)}
                                            />
                                        </div>
                                        <div className="shared-input">
                                            <div className="form-input-div">
                                                <input
                                                    type="text"
                                                    className="text-input-2"
                                                    placeholder="Twitter (https://twitter.com/someorg/)"
                                                    defaultValue={root.authStore.admin.organization.twitter_address}
                                                    onChange={e => setTwitterAddress(e.target.value)}
                                                />
                                            </div>
                                            <div className="empty-space"></div>
                                            <div className="form-input-div">
                                                <input
                                                    type="text"
                                                    className="text-input-2"
                                                    placeholder="Facebook (https://facebook.com/someorg/)"
                                                    defaultValue={root.authStore.admin.organization.facebook_address}
                                                    onChange={e => setFacebookAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {busy ? (
                                            <div className="loader">
                                                <Ripple color="#ff3366" />
                                            </div>
                                        ) : (
                                            <button className="submit-btn" onClick={updateProfileInfo}>Save Changes</button>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                deleteAccount={deleteAccount}
                busy={busy}
            />
        </div>
    )
})

export default OrgAccountSettings;