import { observer } from "mobx-react-lite";
import * as React from "react";
import {
    Route, Redirect, RouteProps
} from "react-router-dom";
import { RootContext } from "..";

interface PrivateRouteProps extends RouteProps {
    component: any;
}

const AdminAuthRoute = observer((props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    const root = React.useContext(RootContext);
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                root.authStore.isSignedIn && root.authStore.admin.role === "organisation_admin" ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: routeProps.location }
                        }} />
                )
            }
        />
    );
});

export default AdminAuthRoute;