import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "@material-ui/core";
import { usePaymentInputs, PaymentInputsWrapper } from "react-payment-inputs";
import Ripple from "../components/Ripple";
import images from 'react-payment-inputs/images';

import "../dashboard_styles/paymentModal.css";

interface Props {
  open: boolean;
  close: () => void;
  cvc: string;
  number: string;
  expiry: string;
  setCVC: Dispatch<SetStateAction<string>>;
  setExpiry: Dispatch<SetStateAction<string>>;
  setNumber: Dispatch<SetStateAction<string>>;
  makePayment: () => void;
  loading: boolean;
}

const PaymentModal = ({ open, close, number, cvc, expiry, setCVC, setExpiry, setNumber, makePayment, loading }: Props) => {
  const cardImages: any = { ...images };

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();

  const handleChangeCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(e.target.value);
  }

  const handleChangeExpiryDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpiry(e.target.value);
  }

  const handleChangeCVC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCVC(e.target.value);
  }

  return (
    <Modal
      open={open}
      onClose={close}
      className="flex items-center justify-center"
    >
      <div
        className="payment-body"
      >
        <p className="text-center font-medium text-[20px] mb-8">Enter your card details</p>
        <PaymentInputsWrapper {...wrapperProps}>
          <svg {...getCardImageProps({ images: cardImages })} />
          <input {...getCardNumberProps({ onChange: handleChangeCardNumber })} value={number} />
          <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiry} />
          <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvc} />
        </PaymentInputsWrapper>
        {loading ? (
          <div className="flex flex-row items-center justify-end w-full">
            <Ripple color="#ff3366" />
          </div>
        ) : (
          <div className="flex flex-row items-center justify-end mt-4 space-x-4">
            <button
              onClick={close}
              className="border border-[#ff5a89] w-[120px] h-[40px] rounded"
            >Cancel</button>
            <button
              className="bg-[#96C864] rounded text-white w-[120px] h-[40px]"
              onClick={makePayment}
            >Pay</button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default PaymentModal
