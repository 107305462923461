import Cookies from "js-cookie";
import { MessageBox } from "../models/message-box";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("adminToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
        "Authorization": `Bearer ${token}`
    }
  })
}


const fetchOrgMessages = async(admin_id: number) => {
    setAxiosInstance();
    try {
        let  { data }  = await axiosInstance.get(`${REACT_APP_SERVER}/api/web_conversation/${admin_id}`)
        let  parsedData =  parseMessages(data.messages);
        return parsedData;
    } catch (error) {
        throw error;
    }
}

const sendUserMessage = async(sender_id: number, receiver_id: number, text: string) => {
    setAxiosInstance();
    let formData = {
        from: sender_id, to: receiver_id, text
    };

    try {
        let {data} = await axiosInstance.post(`${REACT_APP_SERVER}/api/conversation/send`, formData);
        return data;

    } catch (error) {
        throw error;
    }
}

const parseMessages = (json: any) => {
    let message_box_array: MessageBox[] = [];

    for (const user_name in json) {
            let message_box: MessageBox = {name: "", messages: []}; 
                message_box.name = user_name;
                message_box.messages = json[user_name];
            message_box_array.push(message_box);
    }

    return { message_box_array, unread: json.unread_messages } ;
}

const sendGroupMessage = async() => {

}

export { fetchOrgMessages, sendUserMessage, sendGroupMessage };