import Organization from "../models/organization";

const { REACT_APP_SERVER } = process.env;

interface NewChatTileProps {
  handlePress: any,
  data: Organization
}

const NewChatTile = (props: NewChatTileProps) => {
  return (
    <div
      className="user-message-preview"
      onClick={() => props.handlePress(props.data)}
    >
      <div className="user-profile-image">
        {props.data?.imageUrl.substring(0, 6) === 'avatar' ? (
            <p>{props.data.name.substring(0, 1)}</p>
        ): (
            <img src={`${REACT_APP_SERVER}/images/profileimages/im${props.data.imageUrl}`} alt="avatar"/>
        )}
      </div>
      <div className="user-info">
        <h4>{props.data.name}</h4>
      </div>
    </div>
  )
}

export default NewChatTile;