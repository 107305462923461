import { observer } from "mobx-react-lite";
import { FaFacebook, FaTwitterSquare } from "react-icons/fa";

import Organization from "../models/organization";
import "../styles/Organizations.css";
import History from "./History";

const { REACT_APP_SERVER } = process.env;

const OrganizationItem = observer((props: { organization: Organization}) => {
  const openProfile = () => {
    History.push(`/organization/${props.organization.id}`);
  }


  return (
    <div className="org-card">
      <div className="org-card-image-container" style={props.organization.imageUrl !== 'avatar_09.jpg' ? {background: 'transparent'} : {background: '#f9f8fd'}} onClick={openProfile}>
        {props.organization.imageUrl !== 'avatar_09.jpg' ? (
          <img src={`${REACT_APP_SERVER}/images/profileimages/im${props.organization.imageUrl}`} alt="organization" />
        ) : (
          <h1>{props.organization.name.substring(0, 1)}</h1>
        )}
      </div>
      <h6 className="org-card-name line-clamp-2" onClick={openProfile}>{props.organization.name}</h6>
      <p className="line-clamp-3">
          {props.organization.about}
      </p>
      <div className="org-card-social-links">
          {props.organization.facebookAddress && (
            <a href={`${props.organization.facebookAddress}`} target="blank">
              <FaFacebook size={20} />
            </a>
          )}
          {props.organization.twitterAddress && (
            <a href={`${props.organization.twitterAddress}`} target="blank" >
            <FaTwitterSquare size={20} />
            </a>
          )}
      </div>
    </div>
  )
})

export default OrganizationItem;
