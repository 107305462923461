const PrivacyPolicy = () => (
  <div className="p-4">
    <h1 className="font-bold text-[22px]">
      SCHOOL BIDZ Application Sites and Informational Website
    </h1>
    <p className="mt-3 text-[16px]">Effective Date: Sept 22, 2021</p>
    <p className="mt-3">
      This Privacy Policy describes the current policies and practices of Burton 
      Equity Group, LLC. an Illinois corporation with regard to Personal Data 
      collected by Burton Equity Group through its web applications and 
      informational website located at all urls associated with www.schoolbidz.com
      ("SCHOOL BIDZ”). The term "Personal Data" refers to personally identifiable 
      information about you, such as your full name, birth date, e-mail address or 
      mailing address, phone number and any other information that can be used 
      to identify or contact you.
    </p>
    <p className="mt-3">
      This Privacy Policy is periodically updated. Please check back on a regular 
      basis for any changes. If there are changes to this Privacy Policy, we will 
      notify you via email.
    </p>
    <p className="mt-3">
      <span className="font-bold">Personal Data Collected.</span> The only Personal Data Burton Equity Group 
      currently collects through SCHOOL BIDZ is the Personal Data that is 
      voluntarily provided to Burton Equity Group by users. For example, users 
      may register to use the SCHOOL BIDZ Team and Campaign Application Sites 
      or request more information or consultation using the SCHOOL BIDZ 
      Information Website. To register, a user must enter full name, company, 
      telephone number and email address. Your Personal Data is not accessible to
      third parties, and third parties may not access or collect your Personal Data 
      from any SCHOOL BIDZ site.
    </p>
    <p className="mt-3">
      <span className="font-bold">Non-Personal Data Collected.</span> Other data that does not qualify as 
      Personal Data because it cannot be used to contact or identify users (“Non-
      Personal Data”) may also be automatically collected about the user. For 
      example, Burton Equity Group may use technology to automatically collect 
      Non-Personal Data about the use of SCHOOL BIDZ. This technology may be 
      used to track how many users access SCHOOL BIDZ, the date and time of 
      their visits, the length of their stays, and which pages they view. Burton 
      Equity Group may also use technology to determine which web browsers 
      users use and the address from which they accessed the Website. The 
      information collected using this technology does not identify any user 
      personally and is thus all Non-Personal Data that simply enables Burton 
      Equity Group to compile statistics about users and their use of SCHOOL BIDZ.
      Burton Equity Group may use this Non-Personal Data and may share it with 
      third parties to improve the content and functionality of SCHOOL BIDZ, to 
      better understand our customers and markets, and to improve our products 
      and services.
    </p>
    <p className="mt-3">
      <span className="font-bold">Cookies.</span> We may store certain information from your web browser using 
      “cookies.” A cookie is a piece of data stored on the user’s computer tied to 
      information about the user. We may use session-ID cookies to confirm that 
      registered users are logged in. These cookies would terminate once the user 
      closes the web browser. We may also use persistent cookies that store 
      certain Personal Data you already provided to us to make it easier for you to 
      log back in to SCHOOL BIDZ. Cookies are used as a convenience feature for 
      the user. You can remove or block cookies using the settings in your browser
      if you want to disable them.
    </p>
    <p className="mt-3">
      <span className="font-bold">Do Not Track Signals.</span> SCHOOL BIDZ does not track its customers over 
      time and across third party websites to provide targeted advertising and, 
      therefore, does not respond to Do Not Track (DNT) signals. However, some 
      third party sites do keep track of your browsing activities when they serve 
      you content, which enables them to tailor what they present to you. If you 
      are visiting such sites, your browser may allow you to set the DNT signal on 
      your browser so that third parties (particularly advertisers) know you do not 
      want to be tracked.
    </p>
    <p className="mt-3">
      <span className="font-bold">Use of Personal Data Collected.</span> Burton Equity Group uses the Personal 
      Data collected from SCHOOL BIDZ to provide users with their requested 
      services or products. For example, if you register on the SCHOOL BIDZ 
      marketing website and request additional information on SCHOOL BIDZ, 
      Burton Equity Group will use your Personal Data to contact you and provide 
      the additional information you requested. Burton Equity Group may also use 
      the Personal Data to contact you for a variety of reasons, such as, but not 
      limited to notifying you about recent case studies, offering you additional 
      products or services, or requesting your feedback on the Website.
    </p>
    <p className="mt-3">
      Burton Equity Group will use your information to respond to you, regarding 
      the reason you contacted Burton Equity Group. Burton Equity Group will not 
      share your information with any third party outside of our organization, other
      than as necessary to fulfill your request.
    </p>
    <p className="mt-3">
      <span className="font-bold">Disclosure of Personal Data Collected.</span> Burton Equity Group may disclose
      Personal Data to third parties under certain circumstances. Burton Equity 
      Group may disclose Personal Data to third parties if a user has requested 
      that Burton Equity Group do so. If Burton Equity Group is acquired by or 
      merged into another entity, or all or a portion of Burton Equity Group’s 
      assets are sold or otherwise transferred to another entity, Burton Equity 
      Group may disclose Personal Data to that entity. Burton Equity Group may 
      also disclose Personal Data if required or authorized to do so by law.
    </p>
    <p className="mt-3">
      <span className="font-bold">Security Measures.</span> Burton Equity Group uses industry standard security 
      measures to protect your Personal Data. While such security measures 
      provide reasonable protections against unauthorized access, they provide no
      guarantee that your Personal Data will not be compromised.
    </p>
    <p className="mt-3">
      <span className="font-bold">Links to Other Website.</span> This Privacy Policy applies only to Personal Data 
      collected on urls associated with www.schoolbidz.com. SCHOOL BIDZ may 
      contain hyperlinks to websites that are not operated by Burton Equity Group.
      These hyperlinks are provided for your reference and convenience only and 
      do not imply any endorsement of the activities of these third-party websites 
      or any association with their operators. Burton Equity Group does not control
      these websites and is not responsible for their data practices. We urge you to
      review the privacy policy posted on any website you visit before using the 
      website or providing any Personal Data about yourself.
    </p>
    <p className="mt-3 font-bold">
      Your Access to and Control over Information.
    </p>
    <p className="mt-3">
      You may opt out of any future contacts from us at any time. You can do the 
      following at any time by contacting Burton Equity Group via 
      info@schoolbidz.com.
    </p>
    <ul className="mt-2 list-disc pl-10">
      <li>See what data we have about you, if any.</li>
      <li>Change/correct any data we have about you.</li>
      <li>Have us delete any data we have about you.</li>
      <li>Express any concern you have about our use of your data</li>
    </ul>
    <p className="mt-3">
      <span className="font-bold">Security.</span> Burton Equity Group takes precautions to protect your 
      information. When you submit sensitive information via any 
      www.schoolbidz.com sites, your information is protected both online and 
      offline.
    </p>
    <p className="mt-3">
      Wherever Burton Equity Group collects sensitive information (such as 
      confidential business information) that information is encrypted and 
      transmitted to Burton Equity Group in a secure way. You can verify this by 
      looking for a closed lock icon in your web browser, or looking for "https" at 
      the beginning of the address of the web page.
    </p>
    <p className="mt-3">
      While Burton Equity Group uses encryption to protect sensitive information 
      transmitted online, Burton Equity Group also protects your information 
      offline. Only employees who need the information to perform a specific job 
      (e.g. billing or customer service) are granted access to personally identifiable
      information. The computers/servers on which Burton Equity Group stores 
      personally identifiable information are kept in a secure environment.
    </p>
    <p className="mt-3">
      <span className="font-bold">Questions about Our Privacy Policy.</span> If you have any questions about this
      Privacy Policy or concerns about the way Burton Equity Group processes your
      Personal Data, please contact our School Bidz team at info@schoolbidz.com.
    </p>
  </div>
)

export default PrivacyPolicy