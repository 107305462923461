import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { formatMessageDate } from "../utils";
import { RootContext } from "..";
import { GroupConversation } from "../models/group-conversation";


interface GroupChatTileProps {
    handlePress: any,
    conversation: GroupConversation
}

const GroupChatTile = observer((props: GroupChatTileProps) => {
    const root = useContext(RootContext);

    return (
        <div
            className="user-message-preview"
            onClick={() => props.handlePress(props.conversation.id)}
        >
            <div className="user-profile-image">
                <p>{props.conversation.name.substring(0, 1)}</p>
            </div>
            <div className="user-info">
                <h4>{props.conversation.name}</h4>
                <p>{props.conversation.group_messages[props.conversation.group_messages.length - 1].text}</p>
            </div>
            <div className="message-info">
                <p>{formatMessageDate(props.conversation.group_messages[props.conversation.group_messages.length - 1].created_at)}</p>
                {/* {props.conversation.group_messages[props.conversation.group_messages.length - 1].to === root.authStore.user.id
                    && !props.conversation.group_messages[props.conversation.group_messages.length - 1].read_by_receiver &&
                    <div className="notification-count">NEW</div>} */}
            </div>
        </div>
    )
})

export default GroupChatTile;
