import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { Message } from "../models/message-box";
import { formatMessageDate } from "../utils";
import { RootContext } from "..";
import { GroupMessage } from "../models/group-conversation";

interface GroupChatBubbleProps {
  data: GroupMessage
}

const GroupChatBubble = observer((props: GroupChatBubbleProps) => {
  const root = useContext(RootContext);

  return (
    <div className={props.data.sender_id === root.authStore.user.id ? "message-box right-user" : "message-box left-user"}>
      <div className={props.data.sender_id === root.authStore.user.id ? "right-text" : "left-text"}>
        <p className="bubble-text">{props.data.text}</p>
        <p className="date">{formatMessageDate(props.data.created_at)}</p>
      </div>
    </div>
  )
})

export default GroupChatBubble;