import { Modal } from "@material-ui/core";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

import "../styles/shareModal.css";

interface ShareModalProps {
  isOpen: boolean,
  link: string,
  message: string,
  onClose: any
}

const ShareModal = (props: ShareModalProps) => {
  return(
    <Modal
      open={props.isOpen}
      className="share-modal"
      onClose={props.onClose}
    >
      <div className="share-body">
        <div className="share-link">
          <FacebookShareButton
              url={props.link}
              quote={props.message}
          >
              <FacebookIcon size={35} />
          </FacebookShareButton>
        </div>
        <div className="share-link">
          <TwitterShareButton
              url={props.link}
              title={props.message}
          >
              <TwitterIcon size={35} />
          </TwitterShareButton>
        </div>
        <div className="share-link">
          <WhatsappShareButton
              url={props.link}
              title={props.message}
          >
              <WhatsappIcon size={35} />
          </WhatsappShareButton>
        </div>
        <div className="share-link">
          <TelegramShareButton
              url={props.link}
              title={props.message}
          >
              <TelegramIcon size={35} />
          </TelegramShareButton>
        </div>
        <div className="share-link">
          <EmailShareButton
            url={props.link}
            title={props.message}
          >
            <EmailIcon size={35} />
          </EmailShareButton>
        </div>
      </div>
    </Modal>
  )
}

export default ShareModal;