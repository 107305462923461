import React from "react";
import { Link } from 'react-router-dom';
import { useContext } from 'react';

import "../dashboard_styles/sidebar.css";
import {
    AiOutlineSetting, AiOutlineForm, AiOutlineMessage
    , AiOutlineBell, AiOutlineLogout
} from 'react-icons/ai';
import "../dashboard_styles/sidebar.css";
import { RootContext } from '..';
import History from '../components/History';
import { RiAuctionLine } from "react-icons/ri";

interface OrgMemberSidebarProps {
  unreadMessages?: number
}
  

export default function OrgMemberSidebar(props: OrgMemberSidebarProps) {
    const root = useContext(RootContext);

    const handleLogout = () => {
        root.authStore.signOut();
        History.push('/');
    }

    return (
        <div className="sidebar">
            <nav>
                <Link to="/orgmemberdashboard" className="side-bar-nav-link"> <AiOutlineSetting size={20} /> Dashboard </Link>
                <Link to="/orgmemberstatement" className="side-bar-nav-link"> <AiOutlineForm size={20} /> Organization Statement </Link>
                <Link to="/orgmemberinbox" className="side-bar-nav-link">
                    <AiOutlineMessage size={20} /> Messages
                    {props.unreadMessages! > 0 && (
                        <span className="messages-bubble">{props.unreadMessages}</span>
                    )}
                </Link>
                <Link to="/orgmembernotifications" className="side-bar-nav-link"> 
                    <AiOutlineBell size={20} /> Notifications 
                </Link>
                <Link to="/orgmemberauctions" className="side-bar-nav-link">
                    <RiAuctionLine size={20} /> Auctions
                </Link>
                <Link to="/orgmemberaccountsettings" className="side-bar-nav-link">
                    <AiOutlineSetting size={20} /> Account Settings 
                </Link>
                <button className="side-bar-nav-link" onClick={handleLogout}>
                    <AiOutlineLogout size={20} /> Logout 
                </button>
            </nav>
        </div>
    );
}