import React, { useState, useEffect, createRef } from 'react';
import { MdClose } from "react-icons/md";
import ReCaptcha from "react-google-recaptcha";
import { toast } from "react-toastify";
import { ImInfo } from "react-icons/im";
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';

import "../styles/Register.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import img from "../assets/images/register-img.png";
import Ripple from "../components/Ripple";
import BidderRegisterForm from '../models/bidder-register-form';
import { register } from '../services/auth';
import AdminRegistrationForm from '../models/admin-register-form';
import { validateRecaptcha } from '../services/auth';
import HelmetData from '../components/Helmet';

const Register = () => {
  const [bidderRegister, setBidderRegister] = useState<boolean>(true);
  const [orgRegister, setOrgRegister] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [vPassword, setVPassword] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const fileInput = createRef<HTMLInputElement>();

  useEffect(() => {
    if(bidderRegister)
      setOrgRegister(false);
  }, [bidderRegister])
  
  useEffect(() => {
    if(orgRegister)
      setBidderRegister(false)
  }, [orgRegister])

  const generateBidderForm = (): BidderRegisterForm => {
    return {
      email: email,
      name: username,
      password: password,
      password_confirmation: vPassword
    }
  }

  const generateAdminForm = (): AdminRegistrationForm => {
    return {
      email: email,
      name: username,
      password: password,
      password_confirmation: vPassword,
      organisation_name: orgName,
      organisation_file: file!
    }
  }

  const handleSubmit = async(e: any) => {
    e.preventDefault();
    if(!validateForm())
      return;
    
    setBusy(true);
    let form = null;
    let role = "";

    if(bidderRegister) {
      form = generateBidderForm();
      role = "customer";
    } else {
      form = generateAdminForm();
      role = "admin"
    }
      
    try {
      await register(form, role);
      setBusy(false);     
      handleSuccessAlert();
      resetForm();
    } catch (error) {
      setBusy(false);
      showAlert(error, "info", 5000, true);
      resetForm();
    }
  }

  const validateEmail = (): boolean => {
    if(email.length === 0) {
      toast.info("Please enter you email address");
      return false;
    }
    return true;
  }

  const validateUsername = (): boolean => {
    if(username.length === 0) {
      toast.info("Please enter a username");
      return false
    }
    return true;
  }

  const validatePassword = (): boolean => {
    if(password.length === 0) {
      toast.info("Please enter a password");
      return false;
    } else if (password !== vPassword) {
      toast.info("Your passwords do not match");
      return false;
    }

    return true;
  }

  const validateOrgName = (): boolean => {
    if(orgName.length === 0) {
      toast.info("Please enter your organization name");
      return false;
    }
    return true;
  }

  const validateOrgFile = (): boolean => {
    if(!file) {
      toast.info("Please upload your identification document (EIN)");
      return false;
    }
    return true;
  }

  const validateForm =(): boolean => {
    if(!validateEmail())
      return false;
    
    if(!validateUsername())
      return false;

    if(!validatePassword())
      return false;

    if(orgRegister) {
      if(!validateOrgName())
        return false;
      
      if(!validateOrgFile())
        return false;
    }
    
    return true
  }

  const showAlert = (message: string | any, color: string, delay: number = 5000, server: boolean = false): void => {
    if(server) {
      let keys = Object.keys(message.response.data.errors);
      message = message.response.data.errors[keys[0]];
    }
    toast.error(message);
  }

  const handleSuccessAlert = () => {
    if(bidderRegister) 
      toast.success("Click the link in the email we sent to verify your email address");
    else 
      toast.success("We are working on verifying your organisation. We will send you an email to verify your account soon.")
  }

  const openDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    fileInput.current?.click()
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files: FileList = e.currentTarget.files!;
    handleFile(files);
  }

  const handleFile = (files: FileList) => {
    if(validateFile(files[0])) {
      let reader = new FileReader();
      reader.onload = e => { setFile(e.target?.result) }
      reader.readAsDataURL(files[0])
    }
  }

  const validateFile = (file: File): boolean => {
    if(file.type !== 'application/pdf') {
      toast.info("File type not permitted");
      return false;
    }
    else if(file.size > 100000000) {
      toast.info("File it too large");
      return false;
    }
    return true;
  }

  const clearFile = () => {
    setFile(null);
  }

  const resetForm = () => {
    setFile(null);
    setUsername("");
    setPassword("");
    setEmail("");
    setVPassword("");
    setOrgName("");
  }

  const handleOrgTabPress = (e:any) => { 
    e.preventDefault();
    setOrgRegister(true); 
  }

  const handleBidderTabPress = (e: any) => { 
    e.preventDefault(); 
    setBidderRegister(true);
  }

  const toggleTooltip = () => {
    setShowTooltip(prevState => !prevState);
  }

  const handleRecaptchaEvent: Function = (event: string): void => {
    const response = validateRecaptcha(event);
    if(response)
      setDisabled(false)
    else
      setDisabled(true);
  }

  console.log(windowWidth)

  return(
    <>
      <Nav />
      <HelmetData 
        title="Sign Up for School Bidz"
        description="They need your help! Fundraising for schools"
      />
      <div className="register-header" style={orgRegister && windowWidth < 700 ? {marginBottom: '140vh'} : {}}>
        <div className="register-title-div">
          <h3>They Need Your Help!</h3>
          <h3>Fundraising For Schools</h3>
        </div>
      </div>
      <div className="form-div">
        <div className="form-container">
          <img src={img} alt="child learning" />
          <form className="input-div" onSubmit={handleSubmit}>
            <div className="input-tabs">
              <button 
                className={`input-tab ${bidderRegister && 'active-tab'}`}
                onClick={handleBidderTabPress}
              >
                <p>Bidder</p>
                <span></span>
              </button>
              <button 
                className={`input-tab ${orgRegister && 'active-tab'}`}
                onClick={handleOrgTabPress}
              >
                <p>Organization</p>
                <span></span>
              </button>
            </div>
            <div className="input-group">
              {orgRegister && (
                <>
                  <p>Organization</p>
                  <input 
                    value={orgName}
                    onChange={(e) => setOrgName(e.currentTarget.value)}
                    placeholder="Some Organization"
                  />
                </>
              )}
              <p className="mt-2">Email</p>
              <input 
                placeholder="someone@example.com"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                type="email"
              />
            </div>
            <div className="input-group">
              <p>Username</p>
              <input 
                placeholder="someone"
                value={username}
                onChange={(e) => setUsername(e.currentTarget.value)}
              />
            </div>
            <div className="input-group">
              <div className="form-row" style={{marginLeft: '0px'}}>
                <p>Password</p>
                <div className="tooltip-container">
                  <ImInfo className="info-tooltip" id="PasswordTooltip"/>
                  <Tooltip 
                    placement="right" 
                    isOpen={showTooltip} 
                    target="PasswordTooltip" 
                    toggle={toggleTooltip}
                    innerClassName="custom-tooltip"
                  >
                    <p className="tooltip-text">At least 8 characters</p>
                    <p className="tooltip-text">At least 1 capital letter</p>
                    <p className="tooltip-text">At least 1 number</p>
                  </Tooltip>
                </div>
              </div>
              <input 
                placeholder="***********"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type="password"
              />
            </div>
            <div className="input-group">
              <p>Verify Password</p>
              <input 
                value={vPassword}
                onChange={(e) => setVPassword(e.currentTarget.value)}
                type="password"
                placeholder="***********"
              />
            </div>
            {orgRegister && (
              <>
                <div className="input-group">
                  <p>Identification Document (EIN)</p>
                  <input hidden ref={fileInput} type="file" onChange={(e) => handleFileSelect(e)}/>
                  <div className="file-upload-div">
                    <button 
                      className="upload-btn"
                      onClick={(e) => openDialog(e)}
                    >Upload Document</button>
                    <span className={`${file && "file-selected"} progress-bar`}>{file ? "100%" : "0%"}</span>
                    <button className="cancel-upload-btn" onClick={clearFile}><MdClose/></button>
                  </div>
                  <h5>PDF only</h5>
                </div>
              </>
            )}
            {disabled && (
              <div style={{marginTop: '20px'}}>
                <ReCaptcha 
                  sitekey="6LcgHRkaAAAAAPZv5aQ_rCXd6QpkMO3ji4l2eGYr"
                  onChange={(e) => handleRecaptchaEvent(e)}
                />
              </div>
            )}
            {busy ? (
              <div className="loader">
                <Ripple color="#ff3366" />
              </div>
            ) : (
              <button 
                className="form-action"
                onClick={handleSubmit}
                disabled={disabled}
                style={{backgroundColor: disabled ? "#616161" : ""}}
              >
                Sign Up
              </button>
            )}
            <p className="sign-up-sentence">Already have an account? <Link to="/login"><span>SIGN IN</span></Link></p>
          </form>
        </div>
      </div>
      <Footer upper_div={false}/>
    </>
  )
}

export default Register;