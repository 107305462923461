import Cookies from "js-cookie";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("adminToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
      "accept": "Application/json",
      "Authorization": `Bearer ${token}`
    }
  });
}


const markAdminNotificationAsRead = async(id: string) => {
  setAxiosInstance();
    try {
      await axiosInstance.patch(`/api/auth/mark_notification/${id}`)
    } catch (error) {
      throw error;
    } 
}

export {markAdminNotificationAsRead};