import { makeAutoObservable } from "mobx";
import Alert from "../models/alert";
import OrganizationModel from "../models/organization-model";

class OrgMember {
    id: number = null!;
    username: string = null!;
    image_url: string = null!;
    role: string = null!;
    read_notifications: Alert[] = null!;
    unread_notifications: Alert[] = null!;
    token: string = null!;
    profile_image: string = null!;
    status: string = null!;
    organization: OrganizationModel = null!

  constructor(
    adminId: number,
    username: string, 
    image_url: string, 
    role: string,
    read_notifications: Alert[],
    unread_notifications: Alert[],
    token: string,
    organization: OrganizationModel
    ) {

    makeAutoObservable(this, {
      id: false
    })

    let {
      id, name, profile_photo, website, location, facebook_address, twitter_address,
      instagram_address, pinterest_address, about, cover_image, state, city_town, zipcode,
      status, verified, admin_id, organisation_members, groups
    } = organization;
    
    this.id = adminId;
    this.username = username;
    this.image_url = image_url;
    this.role = role;
    this.read_notifications = read_notifications;
    this.unread_notifications = unread_notifications;
    this.token = token;
    this.organization = 
     <OrganizationModel> {
      id, name, profile_photo, website, location, facebook_address, twitter_address, instagram_address,
      pinterest_address, about, cover_image, state, city_town, zipcode, status, verified, admin_id,
      organisation_members, groups
    };
  }
}

export default OrgMember;