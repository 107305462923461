import { UncontrolledPopover } from "reactstrap";
import WheelPicker from "react-wheelpicker";

interface CustomWheelPickerProps {
  id: string,
  data: any,
  value: string,
  valueIndex: number
  onChange: any
}

const CustomWheelPicker = (props: CustomWheelPickerProps) => {
  return (
    <UncontrolledPopover
      target={props.id}
      placement="bottom"
      trigger="focus"
    >
      <div className="w-[150px]">
        <WheelPicker 
          scrollerId={'bid-wheelpicker'}
          animation="flat"
          data={props.data}
          height={40}
          parentHeight={200}
          fontSize={13}
          defaultSelection={props.valueIndex}
          updateSelection={props.onChange}
        />
      </div>
    </UncontrolledPopover>
  )
}

export default CustomWheelPicker;