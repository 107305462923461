import LoginForm from "../models/login-form";
import BidderRegisterForm from "../models/bidder-register-form";
import ShippingForm from "../models/shipping-form";
import OrgMemberRegisterForm from "../models/org-member-register-form";
import AdminRegistrationForm from "../models/admin-register-form";
import NewPasswordForm from "../models/new-password-form";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;
const axiosInstance = axios.create({
  baseURL: REACT_APP_SERVER,
  headers: {
    "accept": "Application/json"
  }
})

const login: Function = async (form: LoginForm) => {
  let api = form.role === "customer" ? "login" : "adminlogin"; 
  try {
    return await axiosInstance.post(`/api/auth/${api}`, form);
  } catch (error) {
    throw error;
  }
}

const register: Function = async (form: BidderRegisterForm | AdminRegistrationForm, role: string) => {
  let api = "";
  if(role === "customer")
    api = "register"
  else
    api = "register_admin"
  try {
    return await axiosInstance.post(`/api/auth/${api}`, form);
  } catch (error) {
    throw error
  }
}

const deleteUser: any = async (id: number, token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.baseURL = REACT_APP_SERVER;
  try {
    return await axios.delete(`/api/auth/delete_user/${id}`);
  } catch (error) {
    throw error;
  }
}

const registerOrgMember: Function = async(form: OrgMemberRegisterForm) => {
  try {
    return await axiosInstance.post('api/accept_organization_invitation', form)
  } catch (error) {
    throw error
  }
}

const validateRecaptcha: Function = (event: string): boolean => {
  try {
    axiosInstance.post('/api/auth/validate_recaptcha', {recaptcha: event});
    return true;
  } catch (error) {
    return false;
  }
}

const fetchAdmin: Function = async (token: string) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.baseURL = REACT_APP_SERVER
    return await axios.post('api/auth/admin_me');
  } catch (error) {
    throw error
  }
}

const fetchCustomer: Function = async (token: string) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.baseURL = REACT_APP_SERVER
    return await axios.post('/api/auth/me');
  } catch (error) {
    throw error;
  }
}

const updateAvatar: Function = async (token: string, form: any) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.baseURL = REACT_APP_SERVER;
    return await axios.patch('/api/auth/update', form)
  } catch (error) {
    throw error
  }
}

const updateShipping: Function = async (token: string, form: ShippingForm, id: number) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.baseURL = REACT_APP_SERVER;
    return await axios.patch(`/api/shippings/${id}`, form)
  } catch (error) {
    throw error;
  }
}

const forgotPassword: Function = async(data: { email: string }) => {
  try {
    await axiosInstance.post('/api/auth/forgot', data);
  } catch (error) {
    throw error;
  }
}

const changePassword: Function = async(data: NewPasswordForm) => {
  try {
    return await axiosInstance.post('/api/auth/doReset', data);
  } catch (error) {
    throw error;
  }
}

const acceptTerms: Function = async(token: String) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.baseURL = REACT_APP_SERVER;
  try {
    return await axios.patch('/api/auth/accept_terms')
  } catch (error) {
    throw error
  }
}

const acceptCookies: Function = async(token: String) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.baseURL = REACT_APP_SERVER;
  try {
    return await axios.patch('/api/auth/accept_cookies')
  } catch (error) {
    throw error
  }
}

export {
  login,
  validateRecaptcha,
  fetchAdmin,
  fetchCustomer,
  register,
  updateAvatar,
  registerOrgMember,
  forgotPassword,
  changePassword,
  updateShipping,
  deleteUser,
  acceptTerms,
  acceptCookies
}