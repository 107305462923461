const axios = require('axios');
const { REACT_APP_SERVER } = process.env;
let axiosInstance: any;

const setAxiosInstance = () => {
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
    }
  })
}

const fetchOrganizations = async() => {
  setAxiosInstance();
  try {
    return await axiosInstance.get('/api/organisations')
  } catch (error) {
    throw error
  }
}

const fetchOrganization = async(id: string) => {
  setAxiosInstance();
  try {
    return await axiosInstance.get(`/api/organisations/${id}`)
  } catch (error) {
    throw error;
  }
}

export {
  fetchOrganizations,
  fetchOrganization
}