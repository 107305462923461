import { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";

import "../styles/Auctions.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import AuctionItem from "../components/AuctionItem";
import { RootContext } from "..";
import Ripple from "../components/Ripple";
import { pluralize } from "../utils";
import useQuery from "../components/useQuery";
import { toast } from "react-toastify";
import noAuctions from "../assets/images/no-auctions.png";
import HelmetData from "../components/Helmet";

const Auctions = observer(() => {
  const [active, setActive] = useState<boolean>(true);
  const [almostEnded, setAlmostEnded] = useState<boolean>(false);
  const root = useContext(RootContext);
  const query = useQuery();

  const handleParams = () => {
    let purchaseStatus = query.get('purchase_successful')
    if(purchaseStatus === 'true')
      toast.success('Your credits purchase is successful');
    else if(purchaseStatus === 'false')
      toast.error('Your credit purchase failed');
  }

  useEffect(() => {
    handleParams();
  }, [])

  useEffect(() => {
    root.quietUpdateAuctions()
  }, [root])
  
  useEffect(() => {
    if(active)
      setAlmostEnded(false)
  }, [active])

  useEffect(() => {
    if(almostEnded)
      setActive(false)
  }, [almostEnded])

  return(
    <>
      <Nav />
      <HelmetData 
        title="Auctions"
        description="Find an Active or Almost Ending Auction to support."
      />
      <div className="auctions-header">
        <div className='auctions-title-div'>
          <h2 className="sm:text-[30px]">Support an Auction</h2>
          <p>Find an Active or Almost Ending Auction to support below.</p>
        </div>
      </div>
      <div className="auctions">
        <div className="auctions-tabs">
          <div className={`auctions-tab ${active && "selected-tab"}`} onClick={() => setActive(true)}>
            <p>Active Auctions</p>
            <span className="auctions-tab-indicator"></span>
          </div>
          <div className="auctions-tab">
            <span className="auctions-tab-indicator"></span>
          </div>
          <div className={`auctions-tab ${almostEnded && "selected-tab"}`} onClick={() => setAlmostEnded(true)}>
            <p>Almost Ending Auctions</p>
            <span className="auctions-tab-indicator"></span>
          </div>
          <div className="auctions-tab placeholder-tab">
            <span className="auctions-tab-indicator"></span>
          </div>
        </div>
        <div className="auctions-count">
          {active && <p>{`${root.auctionStore.auctions.length} ${pluralize('auction', root.auctionStore.auctions.length)} found`}</p>}
          {almostEnded && <p>{`${root.auctionStore.almostEndingAuctions.length} ${pluralize('auction', root.auctionStore.almostEndingAuctions.length)} found`}</p>}
        </div>
        {root.auctionStore.busy && (
          <div className="activity-indicator">
            <Ripple />
          </div>
        )}
        <div className="auctions-grid" style={active ? {"display": "flex"} : {"display": "none"}}>
          {!root.auctionStore.busy && root.auctionStore.auctions.map((item, index) => (
            <AuctionItem auction={item} key={index} />
          ))}
          {!root.auctionStore.busy && root.auctionStore.auctions.length === 0 && (
            <div className="no-auctions-container">
              <img src={noAuctions} alt='no auctions'/>
              <h1>Whoops! There are no active auctions</h1>
            </div>
          )}
        </div>
        <div className="auctions-grid" style={almostEnded ? {"display": "flex"} : {"display": "none"}}>
          {!root.auctionStore.busy && root.auctionStore.almostEndingAuctions.map((item, index) => (
            <AuctionItem auction={item} key={index} />
          ))}
          {!root.auctionStore.busy && root.auctionStore.almostEndingAuctions.length === 0 && (
            <div className="no-auctions-container">
              <img src={noAuctions} alt='no auctions'/>
              <h1>Whoops! There are no almost ending auctions</h1>
            </div>
          )}
        </div>
      </div>
      <Footer upper_div={false} />
    </>
  )
})

export default Auctions;