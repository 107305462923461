import { makeAutoObservable, action, runInAction } from "mobx";

import Organization from "../models/organization";
import Team from "../models/team";
import Prize from "../models/prize";
import Auction from "../models/auction";
import { fetchOrganizations, fetchOrganization } from "../services/organizations";
import RootStore from "./root";

class OrganizationStore {
  rootStore: RootStore;
  busy: boolean = true;
  organizations: Organization[] = [];
  quarry: Organization = Organization.EmptyInstance();

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      loadOrganizations: action,
      quietUpdate: action
    })
    this.rootStore = rootStore;
  }

  async loadOrganizations() {
    runInAction(() => {
      this.busy = true;
    })
    try {
      const { data } = await fetchOrganizations();
      runInAction(() => {
        this.updateOrganizations(data);
        this.busy = false;
      })
    } catch (error) {
      runInAction(() => {
        this.busy = false;
      })
      throw error;
    }
  }

  async findOrganisation(id: string) {
    runInAction(() => {
      this.busy = true;
    })
    try {
      const { data } = await fetchOrganization(id);
      if(data.length === 0)
        throw new Error('Organisation not found');
      runInAction(() => {
        this.updateQuarry(data);
        this.busy = false;
      })
    } catch (error) {
      runInAction(() => {
        this.busy = false;
      })
      throw error;
    }
  }

  async quietUpdate() {
    try {
      const { data } = await fetchOrganizations();
      runInAction(() => {
        this.updateOrganizations(data);
      })
    } catch (error) {}
  }
  
  getOrganization(id: string): Organization {
    return this.organizations.find(element => element.id === parseInt(id))!;
  }

  private updateOrganizations(json: any) {
    let newOrganizations: Organization[] = [];
    json.forEach((element: any) => {
      newOrganizations.push(new Organization(element.id, element.about, element.admin_id, element.name, element.profile_photo,
        element.website, element.country, element.facebook_address, element.twitter_address,
        element.instagram_address, element.cover_image, this.extractTeamsFromJson(element.groups),
        this.extractAuctionsFromJson(element.auctions), this.extractAuctionsFromJson(element.closed_auctions), element.created_at))
    });
    this.organizations = newOrganizations;
  }

  private extractTeamsFromJson(json: any): Team[] {
    let teams: Team[] = [];
    json.forEach((element: any) => {
      teams.push({
        id: element.id, name: element.name, organizationId: element.organization_id, 
        groupImage: element.group_image, numberOfMembers: element.number_of_members,
        leader: element.leader
      })
    });
    return teams;
  }

  private extractAuctionsFromJson(json: any): Auction[] {
    let auctions: Auction[] = [];
    json.forEach((element: any) => {
      auctions.push(new Auction(element.id, element.name, element.description, element.number_of_bids,
        this.extractPrizeFromJson(element.prizes[0]), this.extractOrganizationFromJson(element.organization),
        element.created_at))
    });
    return auctions;
  }

  private extractAuctionsFromJsonAlt(json: any): Auction[] {
    let auctions: Auction[] = [];
    json.forEach((element: any) => {
      auctions.push(new Auction(element.id, element.name, element.description, element.number_of_bids,
        this.extractPrizeFromJson(element.prizes[0]), null!,
        element.created_at))
    });
    return auctions;
  }

  private extractPrizeFromJson(json: any): Prize {
    return {
      id: json.id, name: json.name, value: json.value, costPerBid: json.cost_per_bid,
      maxNumberOfBids: json.max_number_of_bids, specification: json.specification.specification_text,
      images: json.images
    }
  }

  private extractOrganizationFromJson(json: any): Organization {
    return new Organization(
      json.id, json.about ,json.admin_id, json.name, json.profile_photo, json.website, json.country, json.facebook_address, 
      json.twitter_address, json.instagram_address, "", [], [], [], json.created_at)
  }

  private updateQuarry(json: any) {
    this.quarry = new Organization(json.id, json.about, json.admin_id, json.name, json.profile_photo, 
      json.website, json.country, json.facebook_address, json.twitter_address, 
      json.instagram_address, "", this.extractTeamsFromJson(json.groups), 
      this.extractAuctionsFromJsonAlt(json.auctions), this.extractAuctionsFromJsonAlt(json.closed_auctions), json.created_at)
  }
}

export default OrganizationStore;