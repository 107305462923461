import { useState, useCallback } from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { withStyles } from '@material-ui/core/styles';
import { FaArrowLeft } from "react-icons/fa";

import "../dashboard_styles/crop.css";

const CustomSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: 'currentColor',
    border: '1px solid #fff',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 2,
  },
  rail: {
    height: 6,
    borderRadius: 2,
  },
})(Slider);

interface CropProps {
  hidden: boolean,
  hideCrop: any,
  imageSrc: string,
  saveCroppedImage: any,
}

const Crop = (props: CropProps) => {
  const [crop, setCrop] = useState<Point>({x: 0, y:0});
  const [zoom, setZoom] = useState(1);
  const [pixelCrop, setPixelCrop] = useState<Area>();

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setPixelCrop(croppedAreaPixels);
  }, []);

  const recreateImage = () => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = props.imageSrc
    })
  }

  const cropImage = async() => {
    const image: any = await recreateImage();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // // set each dimensions to double largest dimension to allow for a safe area for the
    // // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    ctx?.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx?.getImageData(0, 0, safeArea, safeArea)
      
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop?.width!
    canvas.height = pixelCrop?.height!

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx?.putImageData(
      data!,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop!.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop!.y)
    )

    props.saveCroppedImage(canvas);
    props.hideCrop();
  }

  return (
    <div className="crop-container" hidden={!props.hidden}>
      <div className="crop-header">
        <span>
          <button className="close-crop-btn" onClick={props.hideCrop}>
            <FaArrowLeft color={'#2143d7'}/>
          </button>
          <p>Upload Image</p>
        </span>
        <button className="apply-crop-btn" onClick={cropImage}>
          Apply
        </button>
      </div>
      <div className="crop-body">
        <Cropper 
          image={props.imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
        />
      </div>
      <div className="crop-slider">
        <CustomSlider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby={"zoom"}
          onChange={(e, zoom) => setZoom(Number(zoom))}
          classes={{root: "slider"}}
        />
      </div>
    </div>
  )
}

export default Crop;