import React, { useContext, useState, useEffect, useCallback, createRef } from 'react';
import { AiOutlineSend, AiOutlineSearch } from "react-icons/ai";
import { observer } from "mobx-react-lite";
import Cookies from "js-cookie";
import Echo from "laravel-echo";

import "../../dashboard_styles/inbox.css";
import "../../dashboard_styles/dashboard.css";
import Nav from '../../dashboard_components/Nav';
import Sidebar from '../../dashboard_components/Sidebar';
import { RootContext } from '../..';
import { MessageBox, Message, Receiver } from '../../models/message-box';
import { loadMessages, sendMessage, markMessageRead, sendGroupMessage, loadGroupMessages } from "../../services/bidder";
import Organization from '../../models/organization';
import NewChatTile from '../../components/NewChatTile';
import ChatTile from '../../components/ChatTile';
import ChatBubble from '../../components/ChatBubble';
import useQuery from '../../components/useQuery';
import { BiArrowBack } from 'react-icons/bi';
import { GroupConversation, GroupMessage } from '../../models/group-conversation';
import GroupChatBubble from '../../components/GroupChatBubble';
import GroupChatTile from '../../components/GroupChatTile';

const { REACT_APP_SERVER, REACT_APP_PUSHER_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;


const BidderInbox = observer(() => {
    const root = useContext(RootContext);
    const query = useQuery();
    const [conversations, setConversations] = useState<MessageBox[] | undefined>([]);
    const [groupConversations, setGroupConversations] = useState<GroupConversation[] | undefined>([]);
    const [chatHistory, setChatHistory] = useState<Message[] | undefined>([]);
    const [groupChatHistory, setGroupChatHistory] = useState<GroupMessage[] | undefined>([]);
    const [text, setText] = useState<string>("");
    const [recipient, setRecipient] = useState<Receiver>();
    const [unreadMessages, setUnreadMessages] = useState<number>(0);
    const [searchResults, setSearchResults] = useState<MessageBox[] | undefined>([]);
    const [newChats, setNewChats] = useState<Organization[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const searchContainerRef = createRef<HTMLDivElement>();
    const chatHeadRef = createRef<HTMLDivElement>();
    const chatsRef = createRef<HTMLDivElement>();
    const historyRef = createRef<HTMLDivElement>();

    const fetchMessages = useCallback(async () => {
        try {
            let { conversations, unread } = await loadMessages(root.authStore.user.id);
            let groupconversations = await loadGroupMessages(root.authStore.user.id);

            setConversations(conversations);
            setUnreadMessages(unread);
            setGroupConversations(groupconversations);
        } catch (error) { }
    }, [root.authStore.user.id])

    const handleParams = () => {
        const organisation = query.get('organisation');
        if (organisation) {
            let newChat = root.organizationStore.organizations.filter(org => org.id === parseInt(organisation));
            if (!checkForExistingChat(newChat[0].name)) {
                setNewChats(newChat)
                const recipient = { name: newChat[0].name, id: newChat[0].adminId, profile_image: newChat[0].imageUrl };
                setRecipient(recipient);
                setChatHistory([]);
            }
        }
    }

    const checkForExistingChat = (name: string): boolean => {
        let isExisting = false;
        conversations?.forEach(element => {
            if (element.name === name)
                isExisting = true;
        });
        return isExisting;
    }

    const handleConversationPress = (name: string) => {
        let recipient: Receiver;
        let history: MessageBox | undefined = conversations?.find(message => message.name === name);
        setChatHistory(history?.messages);
        if (window.innerWidth <= 900) {
            searchContainerRef.current?.setAttribute("style", "display: none;")
            chatHeadRef.current?.setAttribute("style", "display: flex");
            chatsRef.current?.setAttribute("style", "display: none");
            historyRef.current?.setAttribute("style", "display: flex; width: 100%");
        }
        if (root.authStore.user.id === history?.messages[0].from) {
            recipient = {
                name: history?.name, id: history.messages[0].to,
                profile_image: history.messages[0].receiver.profile_image
            };
            setRecipient(recipient);
        } else if (root.authStore.user.id === history?.messages[0].to) {
            recipient = {
                name: history.name, id: history.messages[0].from,
                profile_image: history.messages[0].sender.profile_image
            };
            setRecipient(recipient);
        }

        if (!history?.messages[history?.messages.length - 1].read_by_receiver
            && history?.messages[history.messages.length - 1].to === root.authStore.user.id)
            markAsRead();
    }

    const handleGroupConversationPress = (id: number) => {
        let recipient: Receiver;
        let history: GroupConversation | undefined = groupConversations?.find(message => message.id === id);
        setGroupChatHistory(history?.group_messages);
        if (window.innerWidth <= 900) {
            searchContainerRef.current?.setAttribute("style", "display: none;")
            chatHeadRef.current?.setAttribute("style", "display: flex");
            chatsRef.current?.setAttribute("style", "display: none");
            historyRef.current?.setAttribute("style", "display: flex; width: 100%");
        }

        recipient = {
            name: history!.name, id: 1, profile_image: history!.org_image
        }
        setRecipient(recipient);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (text.length === 0)
            return
        setBusy(true);
        let data;
        if (recipient!.id == 1) {
            data = await sendGroupMessage(1, text);
            let newChatHistory: GroupMessage[] = [...groupChatHistory!, data];
            if (groupChatHistory?.length === 0) {
                //new conversation
                let newConversation: GroupConversation = {
                    name: recipient?.name!, id: 1, org_image: recipient!.profile_image,
                    group_messages: newChatHistory, created_at: `${Date.now()}`
                }
                setGroupChatHistory(groupChatHistory);
                setGroupConversations((prevState) => [newConversation, ...prevState!]);
                setSearch('');
                setNewChats([]);
                setSearchResults([]);
            } else {
                let newConversations: GroupConversation[] = groupConversations!;
                let conversationIndex = newConversations.findIndex(mes => mes.name === 'SchoolBidz');
                newConversations[conversationIndex].group_messages = newChatHistory;
                setGroupChatHistory(newChatHistory);
                setGroupConversations(newConversations);
            }
            setText('');
            setBusy(false);
        } else {
            data = await sendMessage(root.authStore.user.id, recipient!.id, text);
            let newChatHistory: Message[] = [...chatHistory!, data];
            if (chatHistory?.length === 0) {
                // new conversation
                let newConversation: MessageBox = { name: recipient?.name!, messages: newChatHistory }
                setChatHistory(newChatHistory);
                setConversations((prevState) => [newConversation, ...prevState!])
                setSearch('');
                setNewChats([]);
                setSearchResults([]);
            } else {
                let newConversations: MessageBox[] = conversations!;
                let conversationIndex = newConversations.findIndex(messages => recipient?.name === messages.name);
                newConversations[conversationIndex].messages = newChatHistory;
                setChatHistory(newChatHistory);
                setConversations(newConversations);
            }
            setText('');
            setBusy(false);
        }
    }

    const handleConversationUpdates = useCallback((message: Message) => {
        if (recipient && message.from === recipient!.id) {
            let newChatHistory: Message[] = [...chatHistory!, message];
            let newConversations: MessageBox[] = conversations!;
            let conversationIndex = newConversations.findIndex(conversation => recipient.name === conversation.name);
            newConversations[conversationIndex].messages = newChatHistory;
            setChatHistory(newChatHistory);
            setConversations(newConversations);
        } else {
            let newConversations: MessageBox[] = conversations!;
            let conversationIndex = newConversations.findIndex(conversation => message.sender.name === conversation.name);
        }
    }, [chatHistory, conversations, recipient])

    const initializeEcho = useCallback(() => {
        let token = Cookies.get('customerToken');

        let localEchoConfig = {
            broadcaster: "pusher",
            key: REACT_APP_PUSHER_KEY,
            cluster: REACT_APP_PUSHER_CLUSTER,
            forceTLS: false,
            encrypted: false,
            wsHost: window.location.hostname,
            wsPort: 6001,
            enabledTransport: ['ws', 'wss'],
            authEndpoint: `${REACT_APP_SERVER}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: "application/json"
                }
            },
        };

        let prodEchoConfig = {
            broadcaster: "pusher",
            key: REACT_APP_PUSHER_KEY,
            cluster: REACT_APP_PUSHER_CLUSTER,
            forceTLS: true,
            encrypted: true,
            wsHost: "admin.schoolbidz.com",
            wssPort: 443,
            enabledTransport: ['ws', 'wss'],
            authEndpoint: `${REACT_APP_SERVER}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: "application/json"
                }
            },
        }
        let echoConfig = window.location.hostname != "www.schoolbidz.com" ? localEchoConfig : prodEchoConfig;

        let echo: Echo | any = new Echo(echoConfig)
        console.log(root.authStore.user.id, echoConfig);

        try {
            echo.private(`messages.${root.authStore.user.id}`).listen(
                "NewMessage",
                (e: any) => {
                    handleConversationUpdates(e.message);
                }
            )
        } catch (e) {
            console.log(e)
        }
    }, [handleConversationUpdates, root.authStore.user.id]);

    useEffect(() => {
        fetchMessages();
        handleParams();
    }, [])

    useEffect(() => {
        if (root.authStore.user) {
            initializeEcho();
        }
    }, [root.authStore.user])

    const updateReadConversation = () => {
        const conversationIndex = conversations?.findIndex(conversation => conversation.name === recipient?.name)!;
        const newConversations: MessageBox[] = conversations!;
        newConversations[conversationIndex].messages[newConversations[conversationIndex].messages.length - 1].status = 'read';
        setConversations(newConversations);
    }

    const markAsRead = async () => {
        try {
            await markMessageRead(recipient?.id);
            updateReadConversation();
            fetchMessages()
        } catch (error) { }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        if (event.target.value.length === 0) {
            setSearchResults([]);
            setNewChats([]);
            return;
        }
        const query = event.target.value.toLowerCase();
        let results = conversations?.filter((conversation: MessageBox) => conversation.name.toLowerCase().includes(query));
        setSearchResults(results);
        let newChats = root.organizationStore.organizations.filter((organization) => organization.name.toLowerCase().includes(query));
        sanitizeNewChats(results!, newChats);
    }

    // prevent duplicates
    const sanitizeNewChats = (chats: MessageBox[], newChats: Organization[]) => {
        for (let i = 0; i < newChats.length; i++) {
            for (let z = 0; z < chats.length; z++) {
                if (newChats[i].name === chats[z].name)
                    setNewChats(newChats.slice(i, i + 1))
            }
        }
        setNewChats(newChats);
    }

    const handleNewChatPress = (item: Organization) => {
        const recipient = { name: item.name, id: item.adminId, profile_image: item.imageUrl };
        setRecipient(recipient);
        setChatHistory([]);
    }

    const leaveChat = () => {
        searchContainerRef.current?.setAttribute("style", "display: flex;")
        chatHeadRef.current?.setAttribute("style", "display: none");
        chatsRef.current?.setAttribute("style", "display: block");
        historyRef.current?.setAttribute("style", "display: none; width: 70%");
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <Sidebar unreadMessages={unreadMessages} />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="settings-header">
                                <h1>Inbox</h1>
                                <p> Come here to hold conversations with other users on the platform as well as ask any customer service questions </p>
                            </div>
                            <div className="search-and-chat-title-bar">
                                <div className="search-bar" ref={searchContainerRef}>
                                    <span className="search-span">
                                        <AiOutlineSearch />
                                        <input
                                            type="text"
                                            className="search-input"
                                            placeholder="Search or start new chat"
                                            onChange={handleSearch}
                                            value={search}
                                        />
                                    </span>
                                </div>
                                <div className="chat-title-bar" ref={chatHeadRef}>
                                    <button className="leave-chat" onClick={leaveChat}>
                                        <BiArrowBack size={20} />
                                    </button>
                                    {recipient && (
                                        <div className="chat-avatar">
                                            <div className="chat-profile-image">
                                                {recipient?.profile_image.substring(0, 6) === 'avatar' ? (
                                                    <p>{recipient.name.substring(0, 1)}</p>
                                                ) : (
                                                    <img src={`${REACT_APP_SERVER}/images/profileimages/im${recipient?.profile_image}`} alt="avatar" />
                                                )}
                                            </div>
                                            <p className="chat-user-name">
                                                {recipient?.name}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="inbox-boxes">
                                <div className="users-box" ref={chatsRef}>
                                    {conversations!.length || groupConversations!.length > 0 && (
                                        <p>Chats</p>
                                    )}
                                    {searchResults?.length === 0 && conversations?.map((conversation, index) => (
                                        <ChatTile
                                            handlePress={handleConversationPress}
                                            conversation={conversation}
                                            key={index}
                                        />
                                    ))}
                                    {searchResults?.length === 0 && groupConversations?.map((groupconv, index) => (
                                        <GroupChatTile
                                            handlePress={handleGroupConversationPress}
                                            conversation={groupconv}
                                            key={index}
                                        />
                                    ))}
                                    {searchResults?.map((conversation, index) => (
                                        <ChatTile
                                            handlePress={handleConversationPress}
                                            conversation={conversation}
                                            key={index}
                                        />
                                    ))}
                                    {newChats.length !== 0 && (
                                        <p>New Chats</p>
                                    )}
                                    {newChats?.map((item, index) => (
                                        <NewChatTile
                                            handlePress={handleNewChatPress}
                                            data={item}
                                            key={index}
                                        />
                                    ))}
                                </div>
                                <div className="message-history" ref={historyRef}>
                                    <div className="message-history-container">
                                        {chatHistory?.map((message: Message, index) => (
                                            <ChatBubble data={message} key={index} />
                                        ))}
                                        {groupChatHistory?.map((message: GroupMessage, index) => (
                                            <GroupChatBubble data={message} key={index} />
                                        ))}
                                    </div>
                                    <div className="send-message-container">
                                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                            <span className="send-message-span">
                                                <input
                                                    type="text"
                                                    className="send-message-input"
                                                    placeholder="Type your message"
                                                    value={text}
                                                    onChange={e => setText(e.target.value)}
                                                />
                                                {busy ? (
                                                    <div className="chat-loader"></div>
                                                ) : (
                                                    <button className="send-message-btn">
                                                        <AiOutlineSend size={20} color="#424242" />
                                                    </button>
                                                )}
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
})

export default BidderInbox;