import Cookies from "js-cookie";

import AuctionStatement from "../models/auction-statement";
import TransactionHistory from "../models/transaction-history";
import { MessageBox } from "../models/message-box";
import { GroupConversation, GroupMessage } from "../models/group-conversation";
import { stringify } from "querystring";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;


const setAxiosInstance = () => {
  const token = Cookies.get("customerToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
        "Authorization": `Bearer ${token}`
    }
  })
}

const getTransactionHistory = async(id: number) => {
  setAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/data/usertransactiondata/${id}`);
    return parseTransactionJson(response.data);
  } catch (error) {
    throw error;
  }
}

const getAuctionStatement = async(id: number) => {
  setAxiosInstance();
  try {
    const response = await axiosInstance.get(`/api/data/userauctiondata/${id}`);
    return parseStatementJson(response.data);
  } catch (error) {
    throw error;
  }
}

const markNotificationRead = async(id: string) => {
  setAxiosInstance();
  try {
    await axiosInstance.patch(`/api/auth/mark_notification/${id}`)
  } catch (error) {
    throw error;
  }
}

const loadMessages = async(id: number) => {
  setAxiosInstance();
  try {
    let { data } = await axiosInstance.get(`/api/web_conversation/${id}`)
    let parsedData = parseConversations(data);
    return parsedData;
  } catch (error) {
    throw error;
  }
}

const loadGroupMessages = async(id: number) => {
  setAxiosInstance();
  try {
    let { data } = await axiosInstance.get(`api/web_group_conversation_for_user/${id}`);
    let parsedData = parseGroupConversation(data);
    return parsedData;
  } catch (error) {
    throw error;
  }
}

const sendMessage = async(senderId: number, recipientId: number, text: string) => {
  setAxiosInstance();
  let formData = { from: senderId, to: recipientId, text };
  try {
    let { data } = await axiosInstance.post(`${REACT_APP_SERVER}/api/conversation/send`, formData);
    return data;
  } catch (error) {
    throw error;
  }
}

const sendGroupMessage = async(organization_id: number, text: string) => {
  setAxiosInstance();
  let formData = { organization_id, text};
  try {
    let { data } = await axiosInstance.post(`${REACT_APP_SERVER}/api/group_conversation_user_send`, formData);
    return data;
  } catch (error) {
    throw error;
  }
}

const markMessageRead = async(id: any) => {
  setAxiosInstance();
  try {
    await axiosInstance.patch(`${REACT_APP_SERVER}/api/mark_read/${id}`);
  } catch (error) {
    throw error
  }
} 

const fetchWins = async(id: number) => {
  setAxiosInstance();
  try {
    return await axiosInstance.get(`${REACT_APP_SERVER}/api/data/get_user_wins/${id}`);
  } catch (error) {
    throw error;
  }
}

const parseStatementJson = (json: any): AuctionStatement[] => {
  let statements: AuctionStatement[] = [];
  json.forEach((element: any) => {
    const item: AuctionStatement = {
      id: element.id, auction: element.name, status: element.status, start: element.created_at,
      bids: element.bids_placed, lub: element.lub.name, prize: element.prizes[0].name
    }
    statements.push(item);
  });
  return statements;
}

const parseTransactionJson = (json: any): TransactionHistory[] => {
  let history: TransactionHistory[] = [];
  json.forEach((element: any) => {
    const item: TransactionHistory = {
      id: element.id, status: element.status, amount: element.amount, credits: element.added_credits,
      date: element.created_at, paymentMethod: element.payment_method, type: element.transaction_type
    }
    history.push(item);
  });
  return history;
}

const parseConversations = (json: any) => {
  let conversations: MessageBox[] = [];
  
  for (const username in json.messages) {
    let conversation: MessageBox = { name: "", messages: []};
    conversation.name = username;
    conversation.messages = json.messages[username];
    conversations.push(conversation);
  }
  
  return { conversations, unread: json.unread_messages }
}

const parseGroupConversation = (json: any) => {
  let groupconversations: GroupConversation[] = [];

  json.forEach((el: any) => {
    let group_messages: GroupMessage[] = [];

    el.group_messages.forEach((el2: any)=> {
      let group_message: GroupMessage = {
        id: el2.id, text: el2.text,  created_at: el2.created_at, read_by_sender: el2.read_by_sender, 
        sender_id: parseInt(el2.sender_id)
      } 
      group_messages.push(group_message);
    });
    let conversation: GroupConversation = { 
      id: el.id, name: el.name, org_image: el.org_image ,created_at: el.created_at, group_messages
    };
    groupconversations.push(conversation);
  });
  
  return groupconversations;
}

export {
  getAuctionStatement,
  getTransactionHistory,
  markNotificationRead,
  loadMessages,
  loadGroupMessages, 
  sendMessage,
  markMessageRead,
  fetchWins as getWins,
  sendGroupMessage
}