import PrivacyPolicy from "../components/PrivacyPolicy";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const PrivacyPage = () => {
  return (
    <>
      <Nav />
      <div className="auction-header">
        <div className="auction-title-div">
          <h3>Privacy Policy</h3>
        </div>
      </div>
      <div className="lg:px-[15%] sm:px-0 pb-20 pt-10">
        <PrivacyPolicy />
      </div>
      <Footer upper_div={false}/>
    </>
  )
}

export default PrivacyPage