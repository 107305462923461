import AuthStore from "./auth";
import AutoLoginModule from "./autoLoginModule";
import AuctionStore from "./auction";
import OrganizationStore from "./organization";

export default class RootStore {
  authStore: AuthStore;
  autoLoginModule: AutoLoginModule;
  auctionStore: AuctionStore;
  organizationStore: OrganizationStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.autoLoginModule = new AutoLoginModule(this);
    this.auctionStore = new AuctionStore(this);
    this.organizationStore = new OrganizationStore(this);
  }

  initialize() {
    this.autoLoginModule.autoSignIn();
    this.auctionStore.loadAuctions();
    this.organizationStore.loadOrganizations();
  }

  quietUpdateAuctions() {
    this.auctionStore.quietUpdate();
  }

  quietUpdateOrganizations() {
    this.organizationStore.quietUpdate();
  }
}

