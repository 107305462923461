import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

interface CustomTableProps {
  data: Array<any>,
  columns: Array<any>,
  pagination: boolean,
  headerClasses: string,
  classes: string
}

const CustomTable = (props: CustomTableProps) => {
  return(
    <BootstrapTable 
      keyField="id"
      data={props.data}
      columns={props.columns}
      pagination={props.pagination ? paginationFactory({}) : undefined}
      headerClasses={props.headerClasses}
      classes={props.classes}
    />
  )
}

export default CustomTable;