import React, { useEffect, useState } from 'react';
import { RootContext } from '../..';
import Nav from '../../dashboard_components/Nav';
import OrgMemberSidebar from '../../dashboard_components/OrgMemberSidebar';
import "../../dashboard_styles/statement.css";
import { auctionTransactionColumns, fetchAdminTransactionInfo } from '../../services/dashboardstatement';
import OrgMemberSettingsHeader from './OrgMemberSettingsHeader';
import CustomTable from "../../components/CustomTable";



interface TransactionModel {
    id: number, amount: number, status: string, method: string
}

export default function OrgMemberStatement(props: any) {
    const root = React.useContext(RootContext);
    const [trasanctionDataArray, setTrasactionDataArray] = useState<TransactionModel[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);

    useEffect(() => {
        if (!isFetched) {

        }
    })

    const fetchAdminTransactionData = async () => {
        let data = await fetchAdminTransactionInfo(root.authStore.orgMember.id);
        setIsFetched(true);
        setTrasactionDataArray(data);
    }

    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <OrgMemberSidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <OrgMemberSettingsHeader title="Organization Statement"
                                subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elicit" />

                            <div className="statement-history-2">
                                <div style={{width:'100%', overflowX: 'scroll', height: '100%'}}>
                                    <CustomTable 
                                        columns={auctionTransactionColumns}
                                        data={[]}
                                        pagination={false}
                                        headerClasses={"dashboard-table-header"}
                                        classes={"dashboard-table"}
                                    />
                                </div>                            
                            </div>
                            {/* <div className="statement-history-2">
                                <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}