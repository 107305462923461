import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";

import "../styles/SearchResults.css";
import history from "../components/History";
import Auction from "../models/auction";
import Organization from "../models/organization";


const { REACT_APP_SERVER } = process.env;

interface SearchResultsProps {
  auctions: Auction[],
  organizations: Organization[],
  query: string,
  closeDialog: any,
  ref: React.Ref<HTMLDivElement>
}

const SearchResults = (props: SearchResultsProps) => {
  const [tab, setTab] = useState('all');

  const changeTab = (newTab: string) => {
    setTab(newTab);
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if(!e.currentTarget.contains(e.relatedTarget as Node))
      props.closeDialog();
  }

  const openAuction = (id: number) => {
    props.closeDialog();
    history.push(`/auction/${id}`);
  }

  const openOrganisation = (id: number) => {
    props.closeDialog();
    history.push(`/organization/${id}`);
  }

  return(
    <div className="search-results-container" ref={props.ref} onBlur={handleBlur} tabIndex={3}>
      {(props.auctions.length > 0 || props.organizations.length > 0) ? (
        <>
          <div className="results-tabs">
            <button
              className={`result-tab ${tab === 'all' ? 'active-result-tab' : ''}`} 
              onClick={() => changeTab('all')}>
              All
            </button>
            <button 
              className={`result-tab ${tab === 'auctions' ? 'active-result-tab' : ''}`} 
              onClick={() => changeTab('auctions')}>Auctions</button>
            <button 
              className={`result-tab ${tab === 'organisations' ? 'active-result-tab' : ''}`} 
              onClick={() => changeTab('organisations')}>Organisations</button>
          </div>
          <ul className="results-list">
            {(tab === 'all' || tab === 'auctions') && (
              <p className="results-section-title">Auctions</p>
            )}
            {(tab === 'all' || tab === 'auctions') && props.auctions.map((item, index) => (
              <li className="auction-result" key={index} onClick={() => openAuction(item.id)}>
                <img src={`${REACT_APP_SERVER}/images/prizeimages/im${item.prize.images[0].name}`} alt="prize" />
                <div>
                  <p className="result-title">{item.prize.name}</p>
                  <p className="result-subtitle">{item.organization.name}</p>
                </div>
              </li>
            ))}
            {(tab === 'all' || tab === 'organisations') && (
              <p className="results-section-title">Organizations</p>
            )}
            {(tab === 'all' || tab === 'organisations') && props.organizations.map((item, index) => (
              <li className="organisation-result" key={index} onClick={() => openOrganisation(item.id)}>
                {item.imageUrl === 'avatar_09.jpg' ? (
                  <div className="default-avatar">
                    {item.name.substring(0, 1)}
                  </div>
                ): (
                  <img src={`${REACT_APP_SERVER}/images/profileimages/im${item.imageUrl}`} alt="organisation logo" />
                )}
                <p className="result-title">{item.name}</p>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="empty-search-view">
          <span>
            <BiSearch size={25} color={'#a6b0c3'}/>
          </span>
          <h5>No results for '{props.query}'</h5>
          <p>We couldn't find anything matching your search.</p>
          <p>Try again with a different term.</p>
        </div>
      )}
      
    </div>
  )
}

export default SearchResults;