import Cookies from "js-cookie";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("adminToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
      "accept": "Application/json",
      "Authorization": `Bearer ${token}`
    }
  })
}

const fetchAdminTransactionInfo = async(admin_id: number) => {
    setAxiosInstance();
    try {
        let {data} = await axiosInstance.get(`api/data/admintransaction/${admin_id}`)
        return data;
    } catch (error) {
        throw error;
    }
}

const auctionTransactionColumns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'amount',  text: 'AMOUNT' },
    { dataField: 'status', text: 'STATUS' },
    { dataField: 'method', text: 'PAYMENT METHOD' }
  ];

export {fetchAdminTransactionInfo,  auctionTransactionColumns};