import React, { useState, createRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";

import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Ripple from "../components/Ripple";
import { forgotPassword, changePassword } from "../services/auth";
import NewPasswordForm from "../models/new-password-form";
import history from "../components/History";
import HelmetData from "../components/Helmet";

const ResetPassword = () => {
  const { token } = useParams<{token: string}>();
  const [disabled] = useState(false);
  const [busy, setBusy] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const newPasswordInput = createRef<any>();
  const confirmPasswordInput = createRef<any>();

  const toastOptions = {
    onClose: () => history.push('/login') 
  }

  const validateEmail = (): boolean => {
    if(email.length === 0) {
      toast.info("Please enter your email address");
      return false;
    }
    if(!email.includes('@') || !email.includes('.')) {
      toast.info("Please enter a valid email address");
      return false;
    }
    return true;
  }

  const handleEmailSubmit = async(e: any) => {
    e.preventDefault();
    if(!validateEmail())
      return;
    
    setBusy(true);
    let form = { email: email};

    await forgotPassword(form)
      .then(() => {
        setBusy(false);
        toast.success("We've sent a link to the email address you provided. Follow the link to reset your password.", toastOptions);
      })
      .catch((e: any) => {
        setBusy(false);
        console.log(e);
        toast.error(e.response.data.errors.email[0])
      })
  }

  const validateNewPasswordForm = ():boolean => {
    if(email.length === 0) {
      toast.info("Please enter your email address");
      return false;
    }
    if(!email.includes('@') || !email.includes('.')) {
      toast.info("Please enter a valid email address");
      return false;
    }
    if(newPassword.length === 0) {
      toast.info("Please enter a password");
      return false;
    } else if (newPassword !== confirmPassword) {
      toast.info("Your passwords do not match");
      return false;
    }
    return true;
  }

  const handleNewPasswordSubmit = async(e: any) => {
    e.preventDefault();
    if(!validateNewPasswordForm())
      return;
    
    setBusy(true);
    const form = generatePasswordForm();

    await changePassword(form)
      .then(() => {
        setBusy(false);
        toast.success("Your new password has been saved", toastOptions)
      })
      .catch((e: any) => {
        console.log(e)
        setBusy(false)
        toast.error(e.response.data.errors.email[0])
      })
  }
  
  const generatePasswordForm = (): NewPasswordForm => (
    {
      email: email,
      password: newPassword,
      password_confirmation: confirmPassword,
      token: token
    }
  )

  return (
    <>
      <Nav />
      <HelmetData title="Reset Your Password" description="Enter your email and we will send you a link"/>
        <NewPassword
          handleSubmit={handleNewPasswordSubmit}
          busy={busy}
          disabled={disabled}
          handleNewPasswordChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
          handleConfirmPasswordChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
          newPasswordRef={newPasswordInput}
          confirmPasswordRef={confirmPasswordInput}
          handleEmailChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
        />
      <Footer upper_div={false}/>
    </>
  )
}

export default ResetPassword


interface EmailFormProps {
  handleSubmit: any,
  handleEmailChange: any,
  disabled: boolean,
  busy: boolean,
  emailRef: React.RefObject<any>
}

const Email = (props: EmailFormProps) => (
  <>
    <div className="login-header" style={{height: '50vh', marginBottom: '40vh'}}>
      <div className="login-title-div" style={{paddingTop: '230px'}}>
        <h3>Reset Your Password</h3>
        <h5>Enter your email and we will send you a password reset link</h5>
      </div>
    </div>
    <div className="form-div column-center">
      <form className="form-container" style={{width: '400px'}} onSubmit={props.handleSubmit}>
        <div className="input-div" style={{width: '100%', paddingLeft: 0}}>
          <div className="input-group">
            <p>Email</p>
            <input 
              placeholder="someone@example.com"
              onChange={props.handleEmailChange}
            />
          </div>
          {props.busy ?
            (
              <div className="loader">
                <Ripple color="#ff3366" />
              </div>
            ) : (
              <button
                className="form-action"
                onClick={props.handleSubmit}
                disabled={props.disabled}
                style={{ backgroundColor: props.disabled ? "#616161" : "", margin: '20px 0 20px 0'}}
              >SEND LINK</button>
            )}
        </div>
      </form>
    </div>
  </>
)

interface NewPasswordFormProps {
  handleSubmit: any,
  busy: boolean,
  disabled: boolean,
  handleNewPasswordChange: any
  handleConfirmPasswordChange: any,
  newPasswordRef: React.RefObject<any>
  confirmPasswordRef: React.RefObject<any>
  handleEmailChange: any
}

const NewPassword = (props: NewPasswordFormProps) => (
  <>
    <div className="login-header" style={{height: '50vh'}}>
      <div className="login-title-div" style={{paddingTop: '230px'}}>
        <h3>Reset Your Password</h3>
        <h5>Enter your new password</h5>
      </div>
    </div>
    <div className="form-div column-center">
      <form className="form-container" style={{width: '400px'}} onSubmit={props.handleSubmit}>
        <div className="input-div" style={{width: '100%', paddingLeft: 0}}>
          <div className="input-group">
            <p>Email</p>
            <input 
              placeholder="someone@example.com"
              onChange={props.handleEmailChange}
            />
          </div>
          <div className="input-group">
            <p>New Password</p>
            <input 
              placeholder="8+ Characters, 1 Capital letter, 1 number"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={props.handleNewPasswordChange}
              ref={props.newPasswordRef}
              type="password"
            />
          </div>
          <div className="input-group">
            <p>Confirm Password</p>
            <input 
              placeholder="***********"
              onChange={props.handleConfirmPasswordChange}
              ref={props.confirmPasswordRef}
              type="password"
            />
          </div>
          {props.busy ?
            (
              <div className="loader">
                <Ripple color="#ff3366" />
              </div>
            ) : (
              <button
                className="form-action"
                onClick={props.handleSubmit}
                disabled={props.disabled}
                style={{ backgroundColor: props.disabled ? "#616161" : "", margin: '20px 0 20px 0'}}
              >SUBMIT</button>
            )}
        </div>
      </form>
    </div>
  </>
)