import { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import "../../styles/Auctions.css";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import AuctionItem from "../../components/AuctionItem";
import { RootContext } from "../..";
import Ripple from "../../components/Ripple";
import { pluralize } from "../../utils";
import noAuctions from "../../assets/images/no-auctions.png";

const CreditsPurchaseFail = observer(() => {
  const [active, setActive] = useState<boolean>(true);
  const [almostEnded, setAlmostEnded] = useState<boolean>(false);
  const root = useContext(RootContext);

  const showToast = () => {
    toast.error('Your credit purchase failed');
  }

  useEffect(() => {
    showToast();
  }, [])

  useEffect(() => {
    root.quietUpdateAuctions()
  }, [root])
  
  useEffect(() => {
    if(active)
      setAlmostEnded(false)
  }, [active])

  useEffect(() => {
    if(almostEnded)
      setActive(false)
  }, [almostEnded])

  return(
    <>
      <Nav />
      <div className="auctions-header">
        <div className='auctions-title-div'>
          <h2>We Have Stories To Tell</h2>
          <p>At vero eos et accusamus et iusto odio dignissimos ducimus 
            qui blanditiis praesentium voluptatum deleniti atque corrupti 
            quos dolores et quas  molestias excepturi sint occaecati quos 
            dolores et quas  molestias.
          </p>
        </div>
      </div>
      <div className="auctions">
        <div className="auctions-tabs">
          <div className={`auctions-tab ${active && "selected-tab"}`} onClick={() => setActive(true)}>
            <p>Active Auctions</p>
            <span className="auctions-tab-indicator"></span>
          </div>
          <div className="auctions-tab">
            <span className="auctions-tab-indicator"></span>
          </div>
          <div className={`auctions-tab ${almostEnded && "selected-tab"}`} onClick={() => setAlmostEnded(true)}>
            <p>Almost Ending Auctions</p>
            <span className="auctions-tab-indicator"></span>
          </div>
          <div className="auctions-tab placeholder-tab">
            <span className="auctions-tab-indicator"></span>
          </div>
        </div>
        <div className="auctions-count">
          {active && <p>{`${root.auctionStore.auctions.length} ${pluralize('auction', root.auctionStore.auctions.length)} found`}</p>}
          {almostEnded && <p>{`${root.auctionStore.almostEndingAuctions.length} ${pluralize('auction', root.auctionStore.almostEndingAuctions.length)} found`}</p>}
        </div>
        {root.auctionStore.busy && (
          <div className="activity-indicator">
            <Ripple />
          </div>
        )}
        <div className="auctions-grid" style={active ? {"display": "flex"} : {"display": "none"}}>
          {!root.auctionStore.busy && root.auctionStore.auctions.map((item, index) => (
            <AuctionItem auction={item} key={index} />
          ))}
          {!root.auctionStore.busy && root.auctionStore.auctions.length === 0 && (
            <div className="no-auctions-container">
              <img src={noAuctions} alt='no auctions'/>
              <h1>Whoops! There are no active auctions</h1>
            </div>
          )}
        </div>
        <div className="auctions-grid" style={almostEnded ? {"display": "flex"} : {"display": "none"}}>
          {!root.auctionStore.busy && root.auctionStore.almostEndingAuctions.map((item, index) => (
            <AuctionItem auction={item} key={index} />
          ))}
          {!root.auctionStore.busy && root.auctionStore.almostEndingAuctions.length === 0 && (
            <div className="no-auctions-container">
              <img src={noAuctions} alt='no auctions'/>
              <h1>Whoops! There are no almost ending auctions</h1>
            </div>
          )}
        </div>
      </div>
      <Footer upper_div={false} />
    </>
  )
})

export default CreditsPurchaseFail;