import { makeAutoObservable, action, observable, runInAction } from "mobx";
import Cookies from "js-cookie";

import User from "./userModel";
import { login, fetchCustomer, fetchAdmin } from "../services/auth";
import LoginForm from "../models/login-form";
import Alert from "../models/alert";
import RootStore from "./root";
import Admin from "./adminModel";
import OrgMember from "./orgMemberModel";

interface TokenObject {
  role: string,
  token: string
}

interface ShippingInfo {
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  notes: string
}

class AuthStore {
  rootStore: RootStore;
  busy: boolean = true;
  user: User = User.EmptyInstance();
  admin: Admin =  null!;
  orgMember: OrgMember = null!;
  isSignedIn: boolean = false;
  isAdmin: boolean = false;
  role: string = "";

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      signIn: action,
      signOut: action,
      isSignedIn: observable,
      user: observable,
      admin: observable,
      orgMember: observable,
      role: observable,
      busy: observable,
      isAdmin: observable
    });
    this.rootStore = rootStore;
  }


  async signIn(form: LoginForm) {
    runInAction(() => {
      this.busy = true;
    })
    try {
      const { data } = await login(form);
      runInAction(() => {
        let role = data.user.roles[0].name;
        switch (role) {
          case "customer":
            this.user = new User(data.user.id, data.user.name, data.user.email,
              data.user.profile_image, data.user.number_of_credits, 
              data.user.roles[0].name,
              this.getAlertsFromServer(data.user.read_notifications), 
              this.getAlertsFromServer(data.user.unread_notifications), 
              data.access_token, this.getShippingInfoFromServer(data.user.shipping_address), this.parseAcceptedTerms(data.accepted_terms))
            
            this.isSignedIn = true;
            this.busy = false;
            this.role = data.user.roles[0].name
            break;
          case "organisation_admin":
            this.admin = new Admin(data.user.id, data.user.name, 
              data.user.profile_image, 
              data.user.roles[0].name,
              this.getAlertsFromServer(data.user.read_notifications), 
              this.getAlertsFromServer(data.user.unread_notifications), 
              data.access_token, data.user.organization)
            this.isSignedIn = true;
            this.busy = false;  
            this.isAdmin = true;
            this.role = data.user.roles[0].name
            break;
          case "organisation_member":
            this.orgMember = new OrgMember(data.user.id, data.user.name, 
              data.user.profile_image, 
              data.user.roles[0].name,
              this.getAlertsFromServer(data.user.read_notifications), 
              this.getAlertsFromServer(data.user.unread_notifications), 
              data.access_token, data.user.organization)
            this.isSignedIn = true;
            this.busy = false;  
            this.isAdmin = true;
            this.role = data.user.roles[0].name
            break
          default:
            break;
        }
        
      })
      this.storeToken(form.role, data.access_token);
    } catch (error) {
      console.log(error)
      runInAction(() => {
        this.busy = false;
      })
      throw error;
    }
  }

  signOut() {
    this.deleteToken();
    this.user = User.EmptyInstance();
    this.admin = null!;
    this.role = ""
    this.isSignedIn = false;
    this.isAdmin = false;
  }

  async refreshUser() {
    runInAction(() => {
      this.busy = true;
    })
    const tokenObject: TokenObject = this.getToken();
    try {
      if(this.user.role === 'customer') {
        const { data } = await fetchCustomer(tokenObject.token);
        runInAction(() => {
          this.user = new User(data.id, data.name, data.email,
            data.profile_image, data.number_of_credits, 
            data.roles[0].name,
            this.getAlertsFromServer(data.read_notifications), 
            this.getAlertsFromServer(data.unread_notifications), 
            data.access_token, this.getShippingInfoFromServer(data.shipping_address), this.parseAcceptedTerms(data.accepted_terms))
  
          this.busy = false;
        })
      }
    } catch (error) {
      throw error;
    }
  }

  async refreshAdmin() {
    runInAction(() => {
      this.busy = true;
    })
    const tokenObject: TokenObject = this.getToken();
    try {
      if(this.isAdmin) {
        const { data } = await fetchAdmin(tokenObject.token);
        runInAction(() => {
          if (data.roles[0].name === "organisation_admin") {
            this.rootStore.authStore.admin = new Admin(data.id, data.name, data.profile_image, data.roles[0].name, 
              this.getAlertsFromServer(data.read_notifications), 
              this.getAlertsFromServer(data.unread_notifications),
               data.access_token, data.organization)
          } else if(data.roles[0].name === "organisation_member") {
            this.rootStore.authStore.orgMember = new OrgMember(data.id, data.name, data.profile_image, data.roles[0].name, 
              this.getAlertsFromServer(data.read_notifications), 
              this.getAlertsFromServer(data.unread_notifications),
               data.access_token, data.organization)
          }
          this.isSignedIn = true;
          this.busy = false;  
          this.isAdmin = true;
        })
      }
    } catch (error) {
      throw error;
    }
  }

  async markNotificationRead() {
    runInAction(() => {
      this.busy = true;
    })
    
    try {
    } catch (error) {
      throw error
    }
  }

  private getAlertsFromServer(json: any): Alert[] {
    let notifications: Alert[] = [];
    json.forEach((element: any) => {
      const notification: Alert = {id: element.id, 
        message: element.data.message, date: element.created_at, read: element.read_at ? true:false}
      notifications.push(notification);
    });
    return notifications;
  }

  private getShippingInfoFromServer(json: any): ShippingInfo {
    if(!json)
      return {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        notes: ''
      }
    return {
      firstName: json.first_name ? json.first_name : '',
      lastName: json.last_name ? json.last_name : '',
      address: json.full_address ? json.full_address : '',
      city: json.city ? json.city : '',
      state: json.state ? json.state : '',
      zip: json.zip_code ? json.zip_code : '',
      notes: json.additional_notes ? json.additional_notes : ''
    }
  }

  private parseAcceptedTerms(json: any): boolean {
    if(!json)
      return false;

    if(json === '0')
      return false
    else
      return true
  }

  private storeToken(role: string, token: string): void {
    if(role === "customer") 
      Cookies.set("customerToken", token)
    else 
      Cookies.set("adminToken", token)
  }

  private getToken():TokenObject {
    let customerToken = Cookies.get("customerToken");
    let adminToken = Cookies.get("adminToken");
    if(customerToken)
      return { role: "customer", token: customerToken };
    else if(adminToken)
      return { role: "organization", token: adminToken }
    
    return { role: "", token: "" };
  }

  private deleteToken() {
    Cookies.remove("customerToken");
    Cookies.remove("adminToken");
  }
}

export default AuthStore;