import moment from 'moment';

const pluralize = (word: string, value: number) => {
  if(value !== 1)
    return `${word}s`
  return word
}

const formatNotificationDate = (dateString: string) => {
  let date = moment(dateString).toDate(),
    month = '' + date.getMonth(),
    day = '' + date.getDate();

  let options: Intl.DateTimeFormatOptions = { month: 'short' };
  month = new Intl.DateTimeFormat('en-US', options).format(date);
  return [day, month].join(' ');
}

const formatAuctionDate = (dateString: string) => {
  let date = moment(dateString, 'mm-dd-YYYY').toDate(),
    month = '' + date.getMonth(),
    day = '' + date.getDate(),
    year = '' + date.getFullYear();
  
  let options: Intl.DateTimeFormatOptions = { month: 'short' };
  month = new Intl.DateTimeFormat('en-US', options).format(date);
  const dayMonth = [day, month].join(' ');
  return [dayMonth, year].join(', ');
}

const formatMessageDate = (dateString: string) => {
  let date = moment(dateString).toDate(),
    month = '' + date.getMonth(),
    day = '' + date.getDate(),
    hours = date.getHours(),
    minutes: any = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let options: Intl.DateTimeFormatOptions = { month: 'short' };
  month = new Intl.DateTimeFormat('en-US', options).format(date);
  const dayMonth = [day, month].join(' ');
  const time = [hours, minutes].join(':');
  return `${dayMonth}, ${time} ${ampm}`
}

const formatDate = (dateString: string) => {
  let date = moment(dateString).toDate(),
    year = ''+ date.getFullYear(),
    month = '' + date.getMonth(),
    day = '' + date.getDate();

  let options: Intl.DateTimeFormatOptions = { month: 'long' };
  month = new Intl.DateTimeFormat('en-US', options).format(date);

  return [day, month, year].join(' ');
}

const bidRange = () => {
  let array = [];
  for (let index = 1; index < 1500; index++) {
    array.push(index)
  }
  return array.map(number => number / 100)
}

export {
  formatDate,
  pluralize,
  formatNotificationDate,
  formatAuctionDate,
  formatMessageDate,
  bidRange
}