import { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoIosShareAlt } from "react-icons/io";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify';
import { Editor, EditorState, convertFromHTML, ContentState, ContentBlock } from 'draft-js';

import "../App.css";
import "../styles/AuctionDetail.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { RootContext } from '..';
import Ripple from '../components/Ripple';
import { formatAuctionDate, bidRange } from '../utils';
import { getLowestUniqueBidder, getBidResponses, bid } from "../services/auctions";
import history from '../components/History';
import ShareModal from '../components/ShareModal';
import CustomWheelPicker from '../components/CustomWheelPicker';
import HelmetData from '../components/Helmet';

const { REACT_APP_SERVER } = process.env;

let bidResponses: any = {}

const createBidsArray = () => {
  return bidRange().map((element) => `$${element.toFixed(2)}`);
}

let bidWheelValue = '0.01';
let defaultSelection = 0;

const AuctionDetail = observer(() => {
  const { id } = useParams<{ id: string }>();
  const root = useContext(RootContext);
  const [showCase, setShowcase] = useState("");
  const [prizeSpecs, setPrizeSpecs] = useState<EditorState>(EditorState.createEmpty());
  const [startDate, setStartDate] = useState<string>('');
  const [lub, setLub] = useState<string>('');
  const [remainingBids, setRemainingBids] = useState<number>(0);
  const [responses, setResponses] = useState<any>({});
  const [hideBidAlert, setHideBidAlert] = useState<boolean>(true);
  const [bidResponseText, setBidResponseText] = useState<string>('');
  const [bidResponseImage, setBidResponseImage] = useState<string>('');
  const [bidAmount, setBidAmount] = useState<string>('');
  const [busy, setBusy] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [bidValues] = useState<string[]>(createBidsArray())
  

  useEffect(() => {
    const interval = setInterval(()=> {
      setShowcase(root.auctionStore.quarry.prize.images[Math.random() * root.auctionStore.quarry.prize.images.length].name)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const handleWheelChange = (index: number) => {
    bidWheelValue = bidValues[index];
    defaultSelection = index;
    setBidAmount(bidWheelValue.substring(1))
  }

  const getLUB = useCallback(async () => {
    try {
      const { data } = await getLowestUniqueBidder(root.auctionStore.quarry.id);
      setLub(data.name);
    } catch (error) { }
  }, [root.auctionStore.quarry.id])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    console.log(responses)
  }, [responses])

  const fetchBidResponses = async () => {
    try {
      const { data } = await getBidResponses();
      setResponses(data);
      bidResponses = data;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchBidResponses();
  }, [])

  const parsePrizeSpecification = useCallback(() => {
    const markup = root.auctionStore.quarry.prize.specification;
    const blocksFromHTML = convertFromHTML(markup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setPrizeSpecs(EditorState.createWithContent(state));
  }, [root.auctionStore.quarry.prize.specification])

  const getAuctionStartDate = useCallback(() => {
    if (!root.auctionStore.quarry.createdAt)
      return;
    const startDate = formatAuctionDate(root.auctionStore.quarry.createdAt);
    setStartDate(startDate);
  }, [root.auctionStore.quarry.createdAt])

  useEffect(() => {
    parsePrizeSpecification();
  }, [parsePrizeSpecification, root.auctionStore.quarry])

  useEffect(() => {
    getAuctionStartDate();
  }, [getAuctionStartDate, root.auctionStore.quarry])

  useEffect(() => {
    if(root.auctionStore.quarry.id)
      getLUB();
  }, [getLUB, root.auctionStore.quarry])

  const getAuction = useCallback(async () => {
    await root.auctionStore.findAuction(id)
      .then(() => {
        setShowcase(root.auctionStore.quarry.prize.images[0].name);
        setRemainingBids(root.auctionStore.quarry.numberOfBids);
      })
      .catch((e) => {
        history.push('/404')
      });

  }, [id, root.auctionStore])

  useEffect(() => {
    getAuction()
  }, [getAuction])

  const handleThumbnailClick = (name: string) => {
    setShowcase(name);
  }

  const blockStyleFn = (_: ContentBlock) => {
    return 'prize-detail';
  }

  const showBidAlert = useCallback((responseText: any, responseImage: any, plainText: string = '', percentText: string = '') => {
    if (responseText.length > 0) {
      setHideBidAlert(false);
      setBidResponseText(`${responseText[
        ~~(Math.random() * responseText.length)
      ].data}. ${percentText}`)
      setBidResponseImage(`${responseImage[~~(Math.random() * responseImage.length)].data
        }`)
      return;
    }

    setHideBidAlert(false);
    setBidResponseText(plainText);
    setBidResponseImage(`${REACT_APP_SERVER}/images/oops.png`);
  }, [])

  const handleBidNotification = (bidderId: string, percentOff: string, scenario: string, similarBids: string) => {
    if (parseInt(bidderId) !== root.authStore.user.id)
      return;
    switch (scenario) {
      case 'lowest_unique_bid':
        showBidAlert(bidResponses.lub_text_responses, bidResponses.lub_image_responses);
        break;
      case 'unique_bid':
        showBidAlert(bidResponses.unique_bid_text_responses, bidResponses.unique_bid_image_responses, '',
          `You were ${percentOff}% away from the lowest unique bid`);
        break;
      case 'not_lowest_unique_bid':
        if (parseInt(similarBids) === 2)
          showBidAlert(bidResponses.not_lub_text_responses, bidResponses.not_lub_image_responses, '',
            `You were ${percentOff}% away from the lowest unique bid. ${parseInt(similarBids) - 1} other person placed the same bid as you.`);
        else if (parseInt(similarBids) > 2)
          showBidAlert(bidResponses.not_lub_text_responses, bidResponses.not_lub_image_responses, '',
            `You were ${percentOff}% away from the lowest unique bid. ${parseInt(similarBids) - 1} other people placed the same bid as you.`);
        else
          showBidAlert(bidResponses.not_lub_text_responses, bidResponses.not_lub_image_responses, '',
            `You were ${percentOff}% away from the lowest unique bid`);
        break;
      case 'new_lowest_unique_bid_by_same_bidder':
        showBidAlert([], [], 'Hey! you just set a new lowest unique bid',);
        break;
      case 'already_lowest_unique_bid':
        showBidAlert([], [], 'You still have the lowest unique bid. Stick around, that could change at any moment!');
        break;
      default:
        break;
    }
  }

  const initializeEcho = useCallback(() => {
    window.Echo.channel(`auctions.${root.auctionStore.quarry.id}`).listen(
      'NewAuctionInfo', (event: any) => {
        setLub(event.auctionInfo.name)
        setRemainingBids(parseInt(event.auctionInfo.remaining_auction_bids))
        handleBidNotification(event.auctionInfo.bidder_id, event.auctionInfo.percent_away, event.auctionInfo.scenario, event.auctionInfo.similar_bids)
      }
    )
  }, [root.auctionStore.quarry.id])

  useEffect(() => {
    initializeEcho();
  }, [initializeEcho])

  const placeBid = async () => {
    if (bidAmount === '' || bidAmount === '0') {
      toast.info('Please enter a valid bid amount')
      return;
    }

    if (parseInt(bidAmount) > 15) {
      toast.info("You can't place a bid above 15 dollars");
      return;
    }

    setBusy(true);

    try {
      await bid(root.auctionStore.quarry.id, bidAmount)
        .then(res => {
          if (res.data.ended) {
            setBusy(false);
            toast.info(res.data.info);
          }
        })
      setBidAmount('');
      root.authStore.refreshUser();
      setBusy(false)
    } catch (error) {
      let values: any = Object.values(error)[2];
      toast.info(values['data']['info'])
      setBusy(false)
      setBidAmount('');
    }
  }

  const openNewChat = () => {
    if (root.authStore.user.id)
      history.push(`/bidderinbox?organisation=${root.auctionStore.quarry.organization.id}`)
    else if (root.authStore.isAdmin)
      history.push(`/orginbox?organisation=${root.auctionStore.quarry.organization.id}`)
    else
      toast.info('You need to login to be able to send messages');
  }

  const openProfile = () => {
    history.push(`/organization/${root.auctionStore.quarry.organization.id}`);
  }

  return (
    <>
      {!hideBidAlert && (
        <div className="auction-alert-modal" onClick={() => setHideBidAlert(true)}>
          <div className="alert-container">
            <div className="auction-alert auction-alert-card">
              <img
                src={bidResponseImage} alt="bid response"
                className="alert-image" />
              <p className="alert-message">{bidResponseText}</p>
            </div>
          </div>
        </div>
      )}
      <Nav />
      <HelmetData 
        title={`${root.auctionStore.quarry.name} | School Bidz`}
        description={`Help raise money for this auction, and stand the chance of winning this ${root.auctionStore.quarry.prize.name}`}
        image={root.auctionStore.quarry.prize.images[0]}
      />
      <div className="auction-header">
        <div className="auction-title-div">
          <h3>{root.auctionStore.quarry && root.auctionStore.quarry.name}</h3>
        </div>
      </div>
      <div className="auction-container">
        <div className="breadcrumbs">
          <Link to='/'>
            <div className="breadcrumb">Home</div>
          </Link>
          <p>&gt;</p>
          <Link to='/auctions'>
            <div className='breadcrumb'>Auctions</div>
          </Link>
          <p>&gt;</p>
          <div className='breadcrumb'>{root.auctionStore.quarry && root.auctionStore.quarry.prize.name}</div>
        </div>
        {root.auctionStore.busy && (
          <div className="activity-indicator" style={{ height: '60vh' }}>
            <Ripple />
          </div>
        )}
        {!root.auctionStore.busy && (
          <div className='auction-detail'>
            <div>
              <div className="image-carousel">
                <div className='thumbnails max-h-[350px]'>
                  {root.auctionStore.quarry && root.auctionStore.quarry.prize.images.slice(0, 4).map((item, index) => (
                    <button className='thumbnail' onClick={() => handleThumbnailClick(item.name)}>
                      <img src={`${REACT_APP_SERVER}/images/prizeimages/im${item.name}`} alt="prize" key={index} />
                    </button>
                  ))}
                </div>
                <img
                  src={`${REACT_APP_SERVER}/images/prizeimages/im${showCase}`}
                  alt='auction prize' className='prize-image' />
              </div>
              <div className="auction-description mb-[100px]">
                <div className="auction-title">
                  <p className="max-w-[600px]">{root.auctionStore.quarry && root.auctionStore.quarry.name}</p>
                  <button onClick={() => setShowShareModal(true)}>
                    <IoIosShareAlt color="#BDBDBD" size={24} className="share-icon" />
                    Share
                  </button>
                </div>
                <p className="mb-8 max-w-[600px]">{root.auctionStore.quarry && root.auctionStore.quarry.description}</p>
                <p className="text-[2rem] font-medium">{root.auctionStore.quarry && root.auctionStore.quarry.prize.name}</p>
                <p className="prize-price">${root.auctionStore.quarry && Number(root.auctionStore.quarry.prize.value).toLocaleString('en')}</p>
                <Editor
                  editorState={prizeSpecs}
                  readOnly={true}
                  onChange={() => { }}
                  blockStyleFn={blockStyleFn}
                />
              </div>
            </div>
            <div className="metadata-card">
              {root.authStore.user.id ? (
                <>
                  <p className="card-title mt-[10px] text-center ml-0">Bid Amount <span className="italic font-normal">(Max $15)</span></p>
                  <div className="relative bid-container">
                    <input
                      id="bid-input"
                      className="font-bold text-[#00C853] text-[26px] bid-input ml-4"
                      value={bidAmount}
                      onChange={(e) => setBidAmount(e.target.value)}
                      type="number"
                      min={0}
                      step={0.01}
                      max={15}
                      placeholder={"0.01"}
                    />
                    <p className={`${bidAmount.length === 0 ? "text-gray-400" : 'text-[#00C853]'} text-[26px] font-bold absolute top-8 left-16`}>$</p>
                    {/* <div className="tooltip-div">
                      <ImInfo className="info-tooltip" id="PasswordTooltip" />
                      <Tooltip
                        placement="right"
                        isOpen={showTooltip}
                        target="PasswordTooltip"
                        toggle={toggleTooltip}
                        innerClassName="custom-tooltip"
                      >
                        <li className="tooltip-text">The minimum bid amount is a penny(0.01)</li>
                        <li className="tooltip-text">The maximum bid amount is 15 dollars</li>
                      </Tooltip>
                    </div> */}
                    <CustomWheelPicker                       
                      data={bidValues}
                      id={"bid-input"}
                      value={bidAmount}
                      valueIndex={defaultSelection}
                      onChange={handleWheelChange}
                    />
                    {busy ? (
                      <div className="loader">
                        <Ripple />
                      </div>
                    ) : (
                      <button onClick={placeBid} hidden={remainingBids === 0}>
                        PLACE BID NOW
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div className="login-as-bidder">
                  <Link to="/login">Login to Place a bid</Link>
                  <Link to="/signup">Sign Up</Link>
                </div>
              )}
              <div className="bidder-info">
                <p className="card-title">Lowest Unique Bidder</p>
                <p className="bidder-name">{lub}</p>
              </div>
              <div className="stats-container">
                <p className="card-title">Stats</p>
                <div className="stats-row">
                  <p className="remaining-bids">{remainingBids}</p>
                  <p style={{ fontSize: '1rem', color: 'black', margin: 0, marginRight: '10px' }}>of</p>
                  <p className="remaining-bids">{root.auctionStore.quarry && root.auctionStore.quarry.prize.maxNumberOfBids}</p>
                  <p className="bids-label">Bids Remaining</p>
                </div>
              </div>
              <div className="org-info">
                <p className="card-title">Organization</p>
                {root.auctionStore.quarry.organization && root.auctionStore.quarry.organization.imageUrl !== 'avatar_09.jpg' ? (
                  <img
                    src={`${REACT_APP_SERVER}/images/profileimages/im${root.auctionStore.quarry.organization.imageUrl}`}
                    alt="organization logo"
                  />
                ) : (
                  <p className="auction-org-name">{root.auctionStore.quarry.organization && root.auctionStore.quarry.organization.name}</p>
                )}
                <p className="App-link" onClick={openProfile}>View Profile</p>
                <p className="App-link" onClick={openNewChat}>Send Private Message</p>
              </div>
              <div className="miscellaneous">
                <p className="card-title">Shipping</p>
                <p className="misc-value">All products are purchased via a third party vendor so all shipments are subject to the inventory of the retail outlet chosen for the purchase</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer upper_div={false} />
      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        link={`https://schoolbidz.com/auction/${root.auctionStore.quarry.id}`}
        message={`Help raise money for this auction, and stand the chance of winning this ${root.auctionStore.quarry.prize.name}`}
      />
    </>
  );
})

export default AuctionDetail;