import { useState } from "react";
import { toast } from "react-toastify";
import { IoLogoInstagram } from "react-icons/io"
import { SiTwitter } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

import "../styles/Footer.css";
import compactLogo from "../assets/images/compact-logo.png";
import Ripple from "./Ripple";
import { registerForNewsletter } from "../services/email";

type FooterProps = {
  upper_div: boolean,
}

function Footer({ upper_div }: FooterProps) {
  const [email, setEmail] = useState<string>('');
  const [busy, setBusy] = useState<boolean>(false);

  const validateEmail = () => {
    if(email.length === 0) {
      toast.info("Please enter you email address");
      return false;
    }
    if(!email.includes('@')) {
      toast.info("Please enter a valid email address");
      return false;
    }
    return true;
  }

  const onRegisterClick = async() => {
    if(!validateEmail())
      return;
    setBusy(true);
    try {
      await registerForNewsletter(email);
      setBusy(false);
      toast.success("You have been registered to receive newsletters from us");
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later")
      setBusy(false);
    }
  }

  return (
    <div className="mobile-footer-container">
      { upper_div && (
        <div className="upper-footer-div">
          <div className="newsletters">
            <h2>
              News & Updates
          </h2>

            <p>School Bidz is constantly improving, so please provide us with your email address so that we can update you on all the new happenings on the platform.</p>
            <input
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {busy ? (
              <div className="activity-indicator">
                <Ripple />
              </div>
            ):(
              <button onClick={onRegisterClick}>
                SUBSCRIBE NOW
              </button>
            )}
          </div>
        </div>
      )}
      <div className="lower-footer-div" style={upper_div ?  {}: {"paddingTop": "0"}}>
        <img src={compactLogo} alt="logo" style={upper_div ?  {}: {"marginTop": "60px"}}/>
        <div className="footer-column" style={upper_div ?  {}: {"marginTop": "60px"}}>
          <p className="footer-column-title">CONTACT US</p>
          <p className="footer-column-paragraph" style={{marginBottom: '40px'}}>info@schoolbidz.com</p>
          <span className="footer-social">
            <a href="https://www.instagram.com/schoolbidz/" target="blank">
              <IoLogoInstagram size={30} style={{fill: "url(#svg_painter)"}} />
              <svg key="svg_painter" width="0" height="0">
                <linearGradient
                  id="svg_painter"
                  x1="12.8917"
                  y1="7.57877"
                  x2="12.8917"
                  y2="21.9488"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E09B3D" offset="0%" />
                  <stop stopColor="#C74C4D" offset="20%"/>
                  <stop stopColor="#C21975" offset="60%"/>
                  <stop stopColor="#7024C4" offset="100%" />
                </linearGradient>
              </svg>
            </a>
            <a href="https://twitter.com/SchoolBidz" target="blank">
              <SiTwitter size={25} color={'rgb(29, 155, 240)'} />
            </a>
            <a href="https://web.facebook.com/SchoolBidz-107911484963959/" target="blank">
              <FaFacebook size={25} color={'blue'} />
            </a>
          </span>
        </div>
      </div>
      <div className="footer-copyright">
        <p>COPYRIGHT © 2021 SCHOOLBIDZ  - ALL RIGHTS RESERVED</p>
        <p><Link to="/terms">TERMS & CONDITIONS</Link>  &nbsp;&nbsp; | &nbsp;&nbsp; <Link to="/privacy">PRIVACY POLICY</Link></p>
      </div>
    </div>
  )
}

export default Footer;