import Cookies from 'js-cookie';
const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("customerToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
        "Authorization": `Bearer ${token}`
    }
  })
}

const registerForNewsletter = async (email: string) => {
  setAxiosInstance();
  try {
    return await axiosInstance.post('/api/newsletters', {email: email})
  } catch (error) {
    throw error;
  }
}

export {
  registerForNewsletter
}