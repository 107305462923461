import { useContext, useEffect, useState, useCallback } from 'react';
import { observer } from "mobx-react-lite";

import Nav from '../../dashboard_components/Nav';
import Sidebar from '../../dashboard_components/Sidebar';
import "../../dashboard_styles/statement.css";
import { RootContext } from '../..';
import { getTransactionHistory, getAuctionStatement } from "../../services/bidder";
import { formatNotificationDate } from "../../utils";
import CustomTable from '../../components/CustomTable';
import { toast } from 'react-toastify';


const columns = [
    { dataField: 'id', text: 'NO.' },
    { dataField: 'date', text: 'TRANSACTION DATE' },
    { dataField: 'type', text: 'TYPE' },
    { dataField: 'amount', text: 'AMOUNT' },
    { dataField: 'paymentMethod', text: 'PAYMENT METHOD' },
    { dataField: 'status', text: 'STATUS' },
    { dataField: 'credits', text: 'CREDITS' }
];

const BidderDashboard = observer(() => {
    const root = useContext(RootContext);
    const [transactionHistory, setTransactionHistory] = useState<any>([]);
    const [auctionStatement, setAuctionStatement] = useState<any>([]);
    const [totalBids, setTotalBids] = useState<number>(0);
    const [openBids, setOpenBids] = useState<number>(0);
    const [bidsWon, setBidsWon] = useState<number>(0);

    const fetchHistory = useCallback(async () => {
        try {
            const history = await getTransactionHistory(root.authStore.user.id);
            setTransactionHistory(history);
        } catch (error) {}
    }, [root.authStore.user.id])

    const fetchStatement = useCallback(async () => {
        try {
            const statement = await getAuctionStatement(root.authStore.user.id);
            setAuctionStatement(statement);
        } catch (error) {}
    }, [root.authStore.user.id])

    useEffect(() => {
        fetchHistory();
        fetchStatement();
    }, [fetchHistory, fetchStatement])


    const determineTotalBids = useCallback(() => {
        let total: number = 0;
        auctionStatement.forEach((element: any) => {
            total += element.bids;
        });
        setTotalBids(total);
    }, [auctionStatement])

    const determineOpenBids = useCallback(() => {
        let openBids: number = 0;
        auctionStatement.forEach((element: any) => {
            if(element.status === 'active')
                openBids += element.bids;
        });
        setOpenBids(openBids);
    }, [auctionStatement])

    const determineBidsWon = useCallback(() => {
        let bidsWon: number = 0;
        auctionStatement.forEach((element: any) => {
            if(element.status === 'finished' && element.lub === root.authStore.user.username)
                bidsWon += element.bids;
        });
        setBidsWon(bidsWon);
    }, [auctionStatement, root.authStore.user.username])


    const checkShippingInfo = useCallback(() => {
        const shippingInfo: {[key: string]: string} = root.authStore.user.shippingInfo;
        for (const key in shippingInfo) {
            if(shippingInfo[key] === '') {
                toast.info("Complete your account setup by filling out the shipping form in your account settings.")
                return;
            }
        }
    }, [root.authStore.user.shippingInfo])

    useEffect(() => {
        checkShippingInfo();
    }, [])

    useEffect(() => {
        determineBidsWon();
        determineOpenBids();
        determineTotalBids();
    }, [determineBidsWon, determineOpenBids, determineTotalBids])

    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <Sidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="settings-header">
                                <h1>Hi {root.authStore.user.username},</h1>
                                <p> Welcome to your Dashboard where you can see your basic account information.</p>
                            </div>
                            <div className="statement-cards">
                                <div className="statement-card">
                                    <h1>{openBids}</h1>
                                    <p> Open Bids</p>
                                </div>
                                <div className="statement-card">
                                    <h1>{bidsWon}</h1>
                                    <p> Bids You Won</p>
                                </div>
                                <div className="statement-card">
                                    <h1>{totalBids}</h1>
                                    <p> Your Total Bids</p>
                                </div>
                                <div className="statement-card">
                                    <h1>{root.authStore.user.credits}</h1>
                                    <p> Your Credits</p>
                                </div>
                            </div>

                            <div className="statement-page-body">
                                <div className="statement-history-2">
                                    <div className="statement-title-div">
                                        <p>Transaction History</p>
                                        <button>
                                            View all
                                        </button>
                                    </div>
                                    <div style={{width: '100%', overflowX: 'scroll', height: '100%'}}>
                                        <CustomTable 
                                            columns={columns}
                                            data={transactionHistory}
                                            pagination={false}
                                            headerClasses={"dashboard-table-header"}
                                            classes={"dashboard-table"}
                                        />
                                    </div>
                                </div>
                                <div className="quick-glance-div">
                                    <div className="quick-glance-card">
                                        <div className="statement-title-div-2">
                                            <p>Notifications</p>
                                            <button>
                                                View all
                                            </button>
                                        </div>
                                        <hr className='divider' />
                                        {root.authStore.user.unread_notifications.map((item, index) => (
                                            <div className="statement-page-notification" key={index}>
                                                <div className="notification-date"><p>{formatNotificationDate(item.date)}</p></div>
                                                <p>{item.message}</p>
                                            </div>
                                        ))}
                                        {root.authStore.user.unread_notifications.length === 0 && (
                                            <p className="empty-text">No unread notifications</p>
                                        )}
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="dashboard-copyright-div">
                                <p>COPYRIGHT © 2021 SCHOOLBIDZ  |  ALL RIGHTS RESERVED</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
})

export default BidderDashboard;