import Cookies from "js-cookie";

const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

let axiosInstance: any;

const setAxiosInstance = () => {
  const token = Cookies.get("adminToken");
  axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
        "Authorization": `Bearer ${token}`
    }
  })
}

const addOrgMembers = async(emails: string[], organisation_id: number, organisation_name: string) => {
    setAxiosInstance();
    const data = {
        organisation_id,
        organisation_name,
        emails
    };
    return axiosInstance.post(`api/send_organization_member_invitation`, data);
}

const getPending = async (organisationId: number) => {
    setAxiosInstance();
    return axiosInstance.get(`api/get_pending_invites/${organisationId}`)
};

const pendingInvitesColumn = [
    {dataField: 'id', text: 'ID'},
    {dataField: 'status', text: 'STATUS'},
    {dataField: 'email', text: 'EMAIL'}
]

const orgMembersColumn = [
    {dataField: 'id', text: 'ID'},
    {dataField: 'user.name', text: 'USERNAME',
    filterValue: (cell: any, row: any) => {
        return cell.user.name;
    },

    }
]

const teamsColumn = [
    {dataField: 'id', text: 'ID'},
    {dataField: 'name', text: 'TEAM NAME'},
    {dataField: 'leader_name', text: 'LEADER NAME',
        filterValue: (cell: any, row: any) => {
            return cell.leader.user.name
        }
    },
    {dataField: 'number_of_members',  text: 'NUMBER OF MEMBERS'}
]

const addTeam = async(team_name: string, team_image: string, number_of_members: number,organization_id: number ,leader_id: number ) => {
    setAxiosInstance();
    const data = {
        name: team_name, group_image: team_image, organization_id,
        leader_id, number_of_members
    }

    return axiosInstance.post('api/groups', data)
}



export { addOrgMembers, getPending, pendingInvitesColumn, orgMembersColumn, addTeam, teamsColumn };
