import { useContext, useEffect } from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./pages/Home";
import HowToBid from './pages/HowToBid';
import Organizations from './pages/Organizations';
import Stories from './pages/Stories';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Auctions from "./pages/Auctions";
import AuctionDetail from "./pages/AuctionDetail";
import BidderAccountSettings from "./pages/bidder_dashboard/BidderAccountSettings";
import { RootContext } from ".";
import BidderInbox from "./pages/bidder_dashboard/BidderInbox";
import BidderCredits from "./pages/bidder_dashboard/BidderCredits";
import BidderNotification from "./pages/bidder_dashboard/BidderNotification"
import BidderStatement from "./pages/bidder_dashboard/BidderStatement";
import BidderDashboard from "./pages/bidder_dashboard/BidderDashboard";
import OrgDashboard from "./pages/org_dashboard/OrgDashboard";
import OrgInbox from "./pages/org_dashboard/OrgInbox";
import OrgNotification from "./pages/org_dashboard/OrgNotification";
import OrgStatement from "./pages/org_dashboard/OrgStatement";
import OrgAuctions from "./pages/org_dashboard/OrgAuctions";
import OrgTeams from "./pages/org_dashboard/OrgTeams";
import UserAuthRoute from "./routes/UserAuthRoute";
import AdminAuthRoute from "./routes/AdminAuthRoute";
import OrgAccountSettings from "./pages/org_dashboard/OrgAccountSettings";
import OrgMemberRegister from "./pages/OrgMemberRegister";
import OrgMemberInbox from "./pages/org_member_dashboard/OrgMemberInbox";
import OrgMemberNotification from "./pages/org_member_dashboard/OrgMemberNotification";
import OrgMemberStatement from "./pages/org_member_dashboard/OrgMemberStatement";
import OrgMemberAccountSettings from "./pages/org_member_dashboard/OrgMemberAccountSettings";
import OrgMemberDashboard from "./pages/org_member_dashboard/OrgMemberDasboard";
import OrgMemberAuctions from "./pages/org_member_dashboard/OrgMemberAuctions";
import OrgMemberAuthRoute from "./routes/OrgMemberAuthRoute";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/404"
import OrgProfile from "./pages/OrgProfile";
import CookiesAlert from "./components/CookiesAlert";
import EmailVerified from "./pages/redirects.tsx/EmailVerified";
import CreditsPurchaseSuccess from "./pages/redirects.tsx/CreditsPurchaseSuccess";
import CreditsPurchaseFail from "./pages/redirects.tsx/CreditsPurchaseFail";
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
import TermsPage from "./pages/Terms";
import PrivacyPage from "./pages/Privacy";
import HelmetData from "./components/Helmet";
import FAQ from "./pages/faq";

function App() {
  const root = useContext(RootContext);

  useEffect(() => {
    root.initialize()
  }, [root])

  return (
    <div>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <CookiesAlert />
      <HelmetData />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/organizations" component={Organizations} />
        <Route exact path="/how_to_bid" component={HowToBid} />
        <Route exact path="/stories" component={Stories} />
        <Route path="/login" component={Login} />
        <Route exact path="/signup" component={Register} />
        <Route exact path="/auctions" component={Auctions} />
        <Route exact path="/auction/:id" component={AuctionDetail} />
        <Route exact path="/resetPassword/:token" component={ResetPassword} />
        <Route exact path="/organization/:id" component={OrgProfile} />
        <Route exact path="/neworganisationmember/:slug"
          render={({ match }) => {
            return <OrgMemberRegister organizationId={parseInt(match.params.slug)} />
          }} />
        <Route exact path="/email_verified" component={EmailVerified}/>
        <Route exact path="/credits_purchase_successful" component={CreditsPurchaseSuccess} />
        <Route exact path="/credits_purchase_fail" component={CreditsPurchaseFail} />
        <Route exact path="/resetPasswordEmail" component={ResetPasswordEmail} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <UserAuthRoute exact path="/bidderdashboard" component={BidderDashboard} />
        <UserAuthRoute exact path="/bidderdashboard_accountsettings" component={BidderAccountSettings} />
        <UserAuthRoute exact path="/bidderinbox" component={BidderInbox} />
        <UserAuthRoute exact path="/biddercredits" component={BidderCredits} />
        <UserAuthRoute exact path="/biddernotification" component={BidderNotification} />
        <UserAuthRoute exact path="/bidderstatement" component={BidderStatement} />
        <AdminAuthRoute exact path="/orgdashboard" component={OrgDashboard} />
        <AdminAuthRoute exact path="/orginbox" component={OrgInbox} />
        <AdminAuthRoute exact path="/orgnotification" component={OrgNotification} />
        <AdminAuthRoute exact path="/orgstatement" component={OrgStatement} />
        <AdminAuthRoute exact path="/orgauctions" component={OrgAuctions} />
        <AdminAuthRoute exact path="/orgteams" component={OrgTeams} />
        <AdminAuthRoute exact path="/orgaccountsettings" component={OrgAccountSettings} />
        <OrgMemberAuthRoute exact path="/orgmemberdashboard" component={OrgMemberDashboard} />
        <OrgMemberAuthRoute exact path="/orgmemberaccountsettings" component={OrgMemberAccountSettings} />
        <OrgMemberAuthRoute exact path="/orgmemberstatement" component={OrgMemberStatement} />
        <OrgMemberAuthRoute exact path="/orgmemberinbox" component={OrgMemberInbox} />
        <OrgMemberAuthRoute exact path="/orgmembernotifications" component={OrgMemberNotification} />
        <OrgMemberAuthRoute exact path="/orgmemberauctions" component={OrgMemberAuctions} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;