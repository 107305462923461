import Cookies from "js-cookie";
import OrgDashboardSettingsForm from "../models/org-dashboard-settings-form"
const { REACT_APP_SERVER } = process.env;

const axios = require('axios');
let axiosInstance: any;

const setAxiosInstance = () => {
    const token = Cookies.get("adminToken");
    axiosInstance = axios.create({
        baseURL: REACT_APP_SERVER,
        headers: {
            "accept": "Application/json",
            "Authorization": `Bearer ${token}`
        }
    })
}

const updateAdminDetails = async(orgform: OrgDashboardSettingsForm, org_id: number) => {
    setAxiosInstance();
    let orgdata = {
        about: orgform.about,
        facebook_address: orgform.facebookAddress,
        website: orgform.webAddress,
        twitter_address: orgform.twitterAddress,
        location: orgform.location,
        reason: "update_organisation_details",
        profile_photo1: orgform.profile_photo1
    }
    
    try {
        let {data} = await axiosInstance.patch(`api/organisations/${org_id}`, orgdata); 
        return data;
    } catch (error) {
        throw error;
    }
}

export { updateAdminDetails }