import { useContext } from 'react';
import { ImEye } from "react-icons/im";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify';
import { BsArrowRight } from 'react-icons/bs';

import Nav from '../components/Nav';
import Footer from '../components/Footer';
import "../styles/home.css";
import banner from "../assets/images/Banner-mobile.png";
import successImg from "../assets/images/success.png";
import signUpImg from "../assets/images/signUp.png";
import bidImg from "../assets/images/bid.png";
import winImg from "../assets/images/win.png";
import AuctionSlider from "../components/AuctionSlider";
import { RootContext } from "../index";
import history from '../components/History';
import Ripple from '../components/Ripple';
import HelmetData from '../components/Helmet';

const Home = observer(() => {
  const root = useContext(RootContext);

  const goToAuctions = () => {
    history.push('/auctions');
  }

  const goToStories = () => {
    history.push('/stories');
  }

  const goToHowToBid = () => {
    history.push('/how_to_bid')
  }

  const goToDashboard = () => {
    if(root.authStore.isAdmin)
      history.push('/orgauctions');
    else
      toast.info("Login as an organization to start a campaign");
  }


  return (
    <>
      <Nav />
      <HelmetData 
        title="School Bidz - Have fun raising money for your favorite organizations."
      />
      <div className="home-header">
        <div className="title-div">
          <div className="home-header-image">
            <img src={banner} alt="banner" />
          </div>
          <div className="home-header-text">
            <h4>We Are</h4>
            <h3>Shaping A Better Future.</h3>
            <p className="title-div-paragraph">
              We are your premier Fundraising Management System.
            </p>
            <p className="title-div-paragraph">
              We have eliminated all the pain points of fundraising.
            </p>
            <p className="title-div-paragraph">
              No more:
              <ul className="ml-8 list-disc">
                <li>Parent participation issues.</li>
                <li>Person to person sales.</li>
                <li>Money accounting.</li>
                <li>Product delivery.</li>
                <li>Oversaturated local markets.</li>
              </ul>
            </p>
            <div className="home-header-btn-div">
              <button 
                className="home-header-btn green-btn" 
                onClick={goToAuctions}
              >BID NOW</button>
              <button 
                className="home-header-btn red-btn" 
                style={{marginRight: 0}}
                onClick={goToDashboard}
              >START A CAMPAIGN</button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-auctions-header">
        <p className="home-auctions-title">Active Auctions</p>
        {root.auctionStore.auctions.length > 1 && (
          <div className="animate-arrow">
            <BsArrowRight size={20}/>
          </div>
        )}
      </div>
      {root.auctionStore.busy ? (
        <div className="activity-indicator">
          <Ripple />
        </div>
      ) : (
        <div className="home-auction-container">
          <AuctionSlider auctions={root.auctionStore.auctions} />
          <button className="auctions-btn" onClick={goToAuctions}>VIEW ALL AUCTIONS</button>
        </div>
      )}
      {root.auctionStore.almostEndingAuctions.length > 0 && (
        <div className="home-auctions-header">
          <p className="home-auctions-title">Almost Ending Auctions</p>
          <div className="animate-arrow-1">
            <BsArrowRight size={20}/>
          </div>
        </div>
      )}
      {root.auctionStore.busy ? (
        <div className="activity-indicator">
          <Ripple />
        </div>
      ) : (
        <div 
          className="home-auction-container-2" 
          style={root.auctionStore.almostEndingAuctions.length === 0 ? {margin: 0} : {}}
        >
          <AuctionSlider auctions={root.auctionStore.almostEndingAuctions} />
        </div>
      )}
      <div className="stories-div-mobile">
        <p className="stories-div-title">Success Stories</p>
        <p className="stories-div-caption-1">Hear From</p>
        <p className="stories-div-caption-2">Our Users</p>
        <p className="stories-div-description">
        Read what our participants have to say about the platform.
        </p>
        <img src={successImg} alt="success" />
        <button onClick={goToStories}>
          <ImEye size={25} color={"white"} style={{ marginRight: "10px" }} />
          VIEW ALL
        </button>
      </div>
      <div className="stories-div-desktop">
        <div className="stories-text-div">
          <p className="stories-div-title">Success Stories</p>
          <p className="stories-div-caption-1">Hear From</p>
          <p className="stories-div-caption-2">Our Users</p>
          <p className="stories-div-description">
            Read what our participants have to say about the platform.
          </p>
          <div className="stories-text-div-action">
            <button className="home-header-btn green-btn" onClick={goToAuctions}>BID NOW</button>
            <button className="home-header-btn red-btn">START A CAMPAIGN</button>
          </div>
        </div>
        <div className="stories-image-div">
          <img src={successImg} alt="success" />
          <button 
            //onClick={goToStories}
          >
            {/* <ImEye size={20} color={"white"} style={{ marginRight: "10px" }} /> */}
            COMING SOON
          </button>
        </div>
      </div>
      <div className="help-div">
        <h2>How</h2>
        <h2>School Bidz works?</h2>
        <p>Click the link to see simple and detailed videos on how School Bidz works.</p>
        <div className="help-pictures">
          <div className="help-pictures-row">
            <div className="help-pictures-item">
              <img src={signUpImg} alt="sign up" />
              <h4>
                Sign Up
              </h4>
              <p>Start a user profile. </p>
            </div>
            <div className="help-pictures-item">
              <img src={bidImg} alt="bid" />
              <h4>
                Bid
              </h4>
              <p>Place the lowest unique bid in the auction that you desire to support.</p>
            </div>
          </div>
          <div className="help-pictures-item">
            <img src={winImg} alt="win" />
            <h4>
              Win
            </h4>
            <p className="help-pictures-item-desc">Once you win the auction, School Bidz will ship the item directly to you.</p>
          </div>
        </div>
        <button className="help-div-btn" onClick={goToHowToBid}>
          WATCH HOW TO BID
        </button>
      </div>
      <div className="help-div-desktop">
        <h2>How School Bidz Works?</h2>
        <p>Click the link to see simple and detailed videos on how School Bidz works.</p>
        <div className="help-pictures">
          <div className="help-pictures-row">
            <div className="help-pictures-item">
              <img src={signUpImg} alt="sign up" />
              <h4>
                Sign Up
              </h4>
              <p style={{ "width": "60%" }}>Start a user profile.</p>
            </div>
            <div className="help-pictures-item">
              <img src={bidImg} alt="bid" />
              <h4>
                Bid
              </h4>
              <p style={{ "width": "60%" }}>Place the lowest unique bid in the auction that you desire to support.</p>
            </div>
            <div className="help-pictures-item">
              <img src={winImg} alt="win" />
              <h4>
                Win
              </h4>
              <p style={{ "width": "60%" }}>Once you win the auction, School Bidz will ship the item directly to you.</p>
            </div>
          </div>
        </div>
        <button className="help-div-btn" onClick={goToHowToBid}>
          WATCH HOW TO BID
        </button>
      </div>
      <Footer upper_div={true} />
    </>
  );
})

export default Home;
