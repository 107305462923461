import { Link } from 'react-router-dom';
import { useContext } from 'react';

import "../dashboard_styles/sidebar.css";
import {
    AiOutlineSetting, AiOutlineForm, AiOutlineLogout
} from 'react-icons/ai';
import "../dashboard_styles/sidebar.css";
import { RootContext } from '..';
import History from '../components/History';
import { RiAuctionLine, RiMessage3Line } from 'react-icons/ri';
import { BsPeople } from 'react-icons/bs';
import { IoIosNotificationsOutline } from 'react-icons/io';

interface OrgSidebarProps {
    unreadMessages?: number
}

export default function OrgSidebar(props: OrgSidebarProps) {
    const root = useContext(RootContext);

    const handleLogout = () => {
        root.authStore.signOut();
        History.push('/');
    }

    return (
        <div className="sidebar">
            <nav>
                <Link to="/orgdashboard" className="side-bar-nav-link"> <AiOutlineSetting size={20} /> Dashboard </Link>
                <Link to="/orgstatement" className="side-bar-nav-link"> <AiOutlineForm size={20} /> Organization Statement </Link>
                <Link to="/orginbox" className="side-bar-nav-link">
                    <RiMessage3Line size={20} /> Messages
                    {props.unreadMessages! > 0 && (
                        <span className="messages-bubble">{props.unreadMessages}</span>
                    )}
                </Link>
                <Link to="/orgnotification" className="side-bar-nav-link"> 
                    <IoIosNotificationsOutline size={20}  /> Notifications 
                    {root.authStore.admin.unread_notifications.length > 0 && (
                        <span className="notifications-bubble">{root.authStore.admin.unread_notifications.length}</span>
                    )}
                </Link>
                <Link to="/orgauctions" className="side-bar-nav-link">
                    <RiAuctionLine size={20} /> Auctions
                </Link>
                <Link to="/orgteams" className="side-bar-nav-link">
                    <BsPeople size={20} /> Teams </Link>
                
                <Link to="/orgaccountsettings" className="side-bar-nav-link">
                    <AiOutlineSetting  size={20} /> Account Settings </Link>
                <button className="side-bar-nav-link" onClick={handleLogout}>
                    <AiOutlineLogout  size={20} /> Logout 
                </button>
            </nav>
        </div>
    );
}