import { makeAutoObservable } from "mobx";
import Alert from "../models/alert";
import OrganizationModel from "../models/organization-model";

class Admin {
    id: number = null!;
    username: string = null!;
    image_url: string = null!;
    role: string = null!;
    read_notifications: Alert[] = null!;
    unread_notifications: Alert[] = null!;
    token: string = null!;
    profile_image: string = null!;
    status: string = null!;
    organization: OrganizationModel = null!

  constructor(
    adminId: number,
    username: string, 
    image_url: string, 
    role: string,
    read_notifications: Alert[],
    unread_notifications: Alert[],
    token: string,
    organization: OrganizationModel
    ) {

    makeAutoObservable(this, {
      id: false
    })
    
    this.id = adminId;
    this.username = username;
    this.image_url = image_url;
    this.role = role;
    this.read_notifications = read_notifications;
    this.unread_notifications = unread_notifications;
    this.token = token;
    this.organization = organization;
  }
}

export default Admin