import Auction from "../models/auction";
import "../styles/AuctionSlider.css";
import AuctionItem from "./AuctionItem";

const AuctionSlider = (props: { auctions: Auction[]}) => {

  return (
    <div className="auction-slider-container">
      {props.auctions.slice(0, 4).map((item, index) => (
        <AuctionItem auction={item} key={index} />
      ))}
    </div>
  )
}

export default AuctionSlider;
