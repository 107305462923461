import Nav from "../components/Nav";
import Footer from "../components/Footer";
import '../styles/Stories.css';
import illustration from "../assets/images/404.png";

import { useEffect } from "react";

function Stories() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])

  return (
    <>
      <Nav />
      <div className="not-found-container">
        <img src={illustration} alt="Not found" />
        <h2>Coming Soon</h2>
      </div> 
      {/* <div className="stories-header">
        <div className='stories-title-div'>
          <h2>Hear From Our Users</h2>
          <p>Read what our participants have to say about the platform.</p>
        </div>
      </div>
      <div className="stories-masonry">
        {stories.map((item, index) => (
          <div className="stories-masonry-item" key={index}>
            <img src={item.image} alt="story" />
            <div className="stories-masonry-desc">
              <p>{item.date}</p>
              <h4>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>
      <div className="stories-btn-div">
        <button className="stories-btn">
          LOAD MORE
        </button>
      </div> */}
      <Footer upper_div={false}/>
    </>
  )
}

export default Stories;
