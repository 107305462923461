import { makeAutoObservable } from "mobx";

import Auction from "./auction";
import Team from "./team";

class Organization {
  id: number = null!;
  about: string = null!;
  adminId: number = null!;
  name: string = null!;
  imageUrl: string = null!;
  website: string = null!;
  country: string = null!;
  facebookAddress: string = null!;
  twitterAddress: string = null!
  instagramAddress: string = null!;
  coverImage: string = null!;
  groups: Team[] = null!;
  auctions: Auction[] = null!;
  closedAuctions: Auction[] = null!;
  createdOn: string = null!;

  constructor(
    id: number, about: string, adminId: number, name: string, imageUrl: string, website: string, country: string,
    facebookAddress: string, twitterAddress: string, instagramAddress: string,
    coverImage: string, groups: Team[], auctions: Auction[], closedAuctions: Auction[], createdOn: string,
  ) {
    makeAutoObservable(this, {
      id: false
    })

    this.id = id;
    this.about = about;
    this.adminId = adminId;
    this.name = name;
    this.imageUrl = imageUrl;
    this.website = website;
    this.country = country;
    this.facebookAddress = facebookAddress;
    this.twitterAddress = twitterAddress;
    this.instagramAddress = instagramAddress;
    this.coverImage = coverImage;
    this.groups = groups;
    this.auctions = auctions;
    this.closedAuctions = closedAuctions;
    this.createdOn = createdOn;
  }

  static EmptyInstance() {
    return new Organization(null!, null!, null!, null!, null!, null!, null!, null!, null!, null!, null!, [], [], [], null!);
  }
}

export default Organization;