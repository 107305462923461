import { useState, useEffect } from 'react';
import ReCaptcha from "react-google-recaptcha";

import "../styles/Register.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import img from "../assets/images/register-img.png";
import Ripple from "../components/Ripple";
import { registerOrgMember } from '../services/auth';
import OrgMemberRegisterForm from '../models/org-member-register-form';
import OrganizationModel from '../models/organization-model';
import { toast } from 'react-toastify';
const axios = require('axios');
const { REACT_APP_SERVER } = process.env;

const axiosInstance = axios.create({
    baseURL: REACT_APP_SERVER,
    headers: {
        "accept": "Application/json",
    }
})


interface myProps {
    organizationId: number
}

const OrgMemberRegister = (props: myProps) => {
    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [vPassword, setVPassword] = useState<string>("");
    const [orgName, setOrgName] = useState<string>("");
    const [busy, setBusy] = useState<boolean>(false);
    const [disabled] = useState<boolean>(false);


    useEffect(() => {
        fetchOrgInfo();
    }, [])

    const generateOrgMemberForm = (): OrgMemberRegisterForm => {
        return {
            email: email,
            name: username,
            password: password,
            password_confirmation: vPassword,
            organization_id: props.organizationId,
            profile_image: "usr_avatar.png"
        }
    }

    const handleSubmit = async () => {
        if (!validateForm())
            return;

        setBusy(true);
        let form = generateOrgMemberForm();
        let role = "";
        try {
            await registerOrgMember(form, role);
            setBusy(false);
            toast.success("Click the link in the email we sent to verify your email address");
            resetForm();
        } catch (error) {
            setBusy(false);
            showAlert(error, "error", 5000, true);
            resetForm();
        }
    }

    const validateEmail = (): boolean => {
        if (email.length === 0) {
            showAlert("Please enter your email", "info");
            return false;
        }
        return true;
    }

    const validateUsername = (): boolean => {
        if (username.length === 0) {
            showAlert("Please enter a username", "info");
            return false
        }
        return true;
    }

    const validatePassword = (): boolean => {
        if (password.length === 0) {
            showAlert("Please enter a password", "info");
            return false;
        } else if (password !== vPassword) {
            showAlert("Your passwords do not match", "info");
            return false;
        }

        return true;
    }

    const validateOrgName = (): boolean => {
        if (orgName.length === 0) {
            showAlert("Please enter your organization name", "info");
            return false;
        }
        return true;
    }



    const validateForm = (): boolean => {
        if (!validateEmail())
            return false;

        if (!validateUsername())
            return false;

        if (!validatePassword())
            return false;

        if (!validateOrgName())
            return false;


        return true
    }

    const showAlert = (message: string | any, color: string, delay: number = 5000, server: boolean = false): void => {
        if (server && typeof message.response.data != "string") {
            let keys = Object.keys(message.response.data.errors);
            message = message.response.data.errors[keys[0]];
        } else {
            message = message.response.data
        }

        if(color === 'success')
            toast.success(message);
        else if(color === 'info')
            toast.info(message);
        else if(color === 'error')
            toast.error(message);
    }

    const resetForm = () => {
        setUsername("");
        setPassword("");
        setEmail("");
        setVPassword("");
        setOrgName("");
    }

    const fetchOrgInfo = () => {
        axiosInstance.get(`api/organisations/${props.organizationId}`).then((res: any) => {
            let orgModel: OrganizationModel = res.data;
            setOrgName(orgModel.name);
        }).catch((e: any) => console.log(e));
    }


    return (
        <>
            <Nav />
            <div className="register-header">
                <div className="register-title-div">
                    <h3>They Need Your Help!</h3>
                    <h3>Fundraising For Schools</h3>
                </div>
            </div>
            <div className="form-div">
                <div className="form-container">
                    <img src={img} alt="child learning" />
                    <div className="input-div">
                        <div className="input-tabs">
                            <button
                                className={`input-tab active-tab`}
                            >
                                <p>Organisation Member</p>
                                <span></span>
                            </button>
                        </div>
                        <div className="input-group">
                            <p>Email</p>
                            <input
                                placeholder="someone@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.currentTarget.value)}
                                type="email"
                            />
                        </div>
                        <div className="input-group">
                            <p>Username</p>
                            <input
                                placeholder="someone"
                                value={username}
                                onChange={(e) => setUsername(e.currentTarget.value)}
                            />
                        </div>
                        <div className="input-group">
                            <p>Password</p>
                            <input
                                placeholder="8+ characters, 1 capital letter, 1 number"
                                value={password}
                                onChange={(e) => setPassword(e.currentTarget.value)}
                                type="password"
                            />
                        </div>
                        <div className="input-group">
                            <p>Verify Password</p>
                            <input
                                value={vPassword}
                                onChange={(e) => setVPassword(e.currentTarget.value)}
                                type="password"
                            />
                        </div>
                        <div className="input-group">
                            <p>Organization Name</p>
                            <input
                                value={orgName}
                                type="text"
                                disabled
                            />
                        </div>

                        {disabled && (
                            <ReCaptcha
                                sitekey="6LcgHRkaAAAAAPZv5aQ_rCXd6QpkMO3ji4l2eGYr"
                            />
                        )}
                        {busy ? (
                            <div className="loader">
                                <Ripple color="#ff3366" />
                            </div>
                        ) : (
                            <button
                                className="form-action"
                                onClick={handleSubmit}
                                disabled={disabled}
                                style={{ backgroundColor: disabled ? "#616161" : "" }}
                            >
                                Sign Up
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <Footer upper_div={false} />
        </>
    )
}

export default OrgMemberRegister;