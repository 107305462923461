import { useContext, useEffect, useState, useCallback } from 'react';
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import "../../dashboard_styles/statement.css";
import Nav from '../../dashboard_components/Nav';
import Sidebar from '../../dashboard_components/Sidebar';
import { RootContext } from '../..';
import CustomTable from '../../components/CustomTable';
import { getTransactionHistory, getAuctionStatement, getWins } from "../../services/bidder";


const auctionLinkFormatter = (cell: any, row: any) => {
    return (
        <Link to={`/auction/${row.id}`} className="auction-link">
            <p>{cell}</p>
        </Link>
    )
}

const statementColumns = [
    { dataField: 'id', text: 'NO.' },
    { dataField: 'start', text: 'START DATE' },
    { dataField: 'prize', text: 'PRIZE' },
    { dataField: 'auction', text: 'AUCTION', formatter: auctionLinkFormatter },
    { dataField: 'bids', text: 'BIDS' },
    { dataField: 'status', text: 'STATUS' },
    { dataField: 'lub', text: 'LOWEST UNIQUE BIDDER' }
];

const historyColumns = [
    { dataField: 'id', text: 'NO.' },
    { dataField: 'date', text: 'TRANSACTION DATE' },
    { dataField: 'type', text: 'TYPE' },
    { dataField: 'amount', text: 'AMOUNT' },
    { dataField: 'paymentMethod', text: 'PAYMENT METHOD' },
    { dataField: 'status', text: 'STATUS' },
    { dataField: 'credits', text: 'CREDITS' }
]

const winnings = [
    { dataField: "id", text: 'NO.' },
    { dataField: "auction.name", text: "AUCTION NAME" },
    { dataField: "auction.prizes[0].name", text: "PRIZE NAME" },
    { dataField: "status", text: "SHIPPING STATUS" }
]




const BidderStatement = observer(() => {
    const root = useContext(RootContext);
    const [transactionHistory, setTransactionHistory] = useState<any>([]);
    const [auctionStatement, setAuctionStatement] = useState<any>([]);
    const [wins, setWins] = useState<any>([]);

    const fetchStatement = useCallback(async () => {
        try {
            const statement = await getAuctionStatement(root.authStore.user.id);
            setAuctionStatement(statement);
        } catch (error) {}
    }, [root.authStore.user.id])

    const fetchHistory = useCallback(async () => {
        try {
            const history= await getTransactionHistory(root.authStore.user.id);
            setTransactionHistory(history);
        } catch (error) {}
    }, [root.authStore.user.id])

    const fetchWins = useCallback(async () => {
        try {
            const {data} = await getWins(root.authStore.user.id);
            console.log(data);
            setWins(data)
        } catch (error) {}
    }, [root.authStore.user.id])

    useEffect(() => {
        fetchStatement();
        fetchHistory();
        fetchWins();
    }, [fetchStatement, fetchHistory, fetchWins])

    
    
    return (
        <div className="page-container">
            <Nav />
            <div className="page-content-box">
                <Sidebar />
                <div className="page-content">
                    <div className="content-box">
                        <div className="settings-box">
                            <div className="settings-header">
                                <h1>Statements</h1>
                                <p> Here you will find your purchase history as well as your auction progression.</p>
                            </div>
                            <div className="statement-history-full">
                                <div className="statement-title-div">
                                    <p>Auction Statement</p>
                                </div>
                                <div style={{width: '100%', height: '100%', overflowX: 'scroll'}}>
                                    <CustomTable 
                                        columns={statementColumns}
                                        data={auctionStatement}
                                        classes={'dashboard-table'}
                                        headerClasses={"dashboard-table-header"}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                            <div className="statement-history-full">
                                <div className="statement-title-div">
                                    <p>Transaction History</p>
                                </div>
                                <div style={{width: '100%', height: '100%', overflowX: 'scroll'}}>
                                    <CustomTable 
                                        columns={historyColumns}
                                        data={transactionHistory}
                                        pagination={false}
                                        headerClasses={"dashboard-table-header"}
                                        classes={"dashboard-table"}
                                    />
                                </div>
                            </div>
                            <div className="statement-history-full">
                                <div className="statement-title-div">
                                    <p>Your Wins</p>
                                </div>
                                <div style={{width: '100%', height: '100%', overflowX: 'scroll'}}>
                                    <CustomTable 
                                        columns={winnings}
                                        data={wins}
                                        pagination={false}
                                        headerClasses={"dashboard-table-header"}
                                        classes={"dashboard-table"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-copyright-div">
                            <p>COPYRIGHT © 2021 SCHOOLBIDZ  |  ALL RIGHTS RESERVED</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default BidderStatement;