import React, { useContext, useRef, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineLeft, AiOutlineMenu, AiOutlineSetting } from "react-icons/ai";
import { RiArrowDownSLine, RiAuctionLine, RiDashboardLine, RiMessage3Line } from "react-icons/ri";
import { IoIosNotificationsOutline, IoMdClose } from "react-icons/io";
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { GiCash } from 'react-icons/gi';
import { BsPeople } from 'react-icons/bs';

import "../dashboard_styles/nav.css";
import logo from "../assets/images/logo_horizontal.png";
import history from "../components/History";
import { RootContext } from '..';
import RootStore from '../stores/root';

const { REACT_APP_SERVER } = process.env;

const backgrounds = [
    'linear-gradient(to right, #ffab28, #ffc728)',
    'linear-gradient(to right, #ffab28, #ffc728)',
    'linear-gradient(to right, #973dcb, #b14ebf)',
    'linear-gradient(to right, #2679fd, #2679fd)'
]

const Nav = observer(() => {
    const root = useContext(RootContext);
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [location] = useState(useLocation());
    const mobileMenuRef = useRef<any>();
    const mobileDropdownRef = useRef<any>();
    const [profileImage, setProfileImage] = useState<string>("");
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [initial, setInitial] = useState<string>('');

    useEffect(() => {
        setProfileImageFun()
    }, [])

    const toggleMenu = () => {
        const mobileMenuNode = mobileMenuRef.current;

        if (menuVisible) {
            enableScroll();
            mobileMenuNode.classList.remove("slide-into");
            mobileMenuNode.classList.add("slide-out");
        }
        else {
            disableScroll();
            mobileMenuNode.classList.remove("slide-out");
            mobileMenuNode.classList.add("slide-into");
        }
        setMenuVisible(!menuVisible);
    }

    const disableScroll = () => {
        const body = document.body;
        body.classList.add("no-scroll");
    }

    const enableScroll = () => {
        const body = document.body;
        body.classList.remove("no-scroll");
    }

    const setProfileImageFun = () => {
        switch (root.authStore.role) {
            case "customer":
                setProfileImage(root.authStore.user.image_url)
                setNotificationCount(root.authStore.user.unread_notifications.length);
                setInitial(root.authStore.user.username.substring(0,1))
                break;
            case "organisation_admin":
                setProfileImage(root.authStore.admin.image_url)
                setNotificationCount(root.authStore.admin.unread_notifications.length);
                setInitial(root.authStore.admin.username.substring(0,1))
                break;
            case "organisation_member":
                setProfileImage(root.authStore.orgMember.image_url)
                setNotificationCount(root.authStore.orgMember.unread_notifications.length);
                setInitial(root.authStore.orgMember.username.substring(0,1))
                break;
            default:
                break;
        }
    }
    
    const handleLogout = () => {
        root.authStore.signOut();
        history.push('/');
    }

    const goHome = () => {
        history.push('/');
    }

    const goToAdminNotifications = () => {
        if(root.authStore.admin)
            history.push('/orgnotification');
        else
            history.push('/orgmembernotifications');
    }

    const goToBidderNotifications = () => {
        history.push('/biddernotification');
    }

    return (
        <div className="dashboard-nav-container">
            <button className="menu-btn" onClick={toggleMenu}>
                {menuVisible ? <IoMdClose size={30} color={"white"} /> : <AiOutlineMenu size={30} color="white" />}
            </button>
            <div className="logo-box" onClick={goHome}>
                <img src={logo} alt="logo" />
            </div>
            <nav className="icons">
                <Link to='/' className="icons-link">
                    <AiOutlineLeft />
                    <p className='icons-link-text'>Back to Website</p>
                </Link>
                <div className="notifications-div" onClick={root.authStore.isAdmin ? goToAdminNotifications : goToBidderNotifications}>
                    <IoIosNotificationsOutline size={35} />
                    {!root.authStore.isAdmin && root.authStore.user.unread_notifications.length > 0 && (
                        <span className="notification-bubble">{root.authStore.user.unread_notifications.length}</span>
                    )}
                    {root.authStore.isAdmin && root.authStore.role === "organisation_admin" && root.authStore.admin.unread_notifications.length > 0 && (
                        <span className="notification-bubble">{root.authStore.admin.unread_notifications.length}</span>
                    )}
                    {root.authStore.isAdmin && root.authStore.role === "organisation_member" && root.authStore.orgMember.unread_notifications.length > 0 && (
                        <span className="notification-bubble">{root.authStore.orgMember.unread_notifications.length}</span>
                    )}
                </div>
                <div className="user-profile-div">
                    <div className="user-profile-icon" style={{background: backgrounds[Math.floor(Math.random() * 4)]}}>
                        {profileImage === 'avatar_01.jpg' ? (
                            <p className="">{initial}</p>
                        ) : (
                            <img src={`${REACT_APP_SERVER}/images/profileimages/im${profileImage}`} alt="user-profile" />
                        )}
                    </div>
                    <RiArrowDownSLine size={20} />
                    <MobileDropdown 
                        reference={mobileDropdownRef} 
                        onLogoutPress={handleLogout}
                        root={root}
                    />
                </div>
            </nav>
            <MobileMenu reference={mobileMenuRef} location={location} root={root} notificationCount={notificationCount} />
        </div>
    )
})

const MobileMenu = observer((props: { reference: React.RefObject<any>, location: any, root: RootStore, notificationCount: number }) => {
    const handleItemPress = (item: MenuItem) => {
        history.push(item.dest);
        toggleScroll();
    }

    const toggleScroll = () => {
        const body = document.body;
        body.classList.toggle('no-scroll');
    }

    return (
        <div className="dashboard-mobile-nav" ref={props.reference}>
            {!props.root.authStore.isAdmin && bidderMenuItems.map((item, index) => (
                <div
                    key={index}
                    className={props.location.pathname === item.dest ? "selected-dashboard-mobile-nav-item" : "mobile-menu-item"}
                    onClick={() => handleItemPress(item)}
                >
                    {item.icon}
                    <p className="mobile-menu-item-label">{item.label}</p>
                    {/* {item.label === 'Messages' && (
                        <span className='messages-bubble'>{}</span>
                    )} */}
                    {item.label === 'Notifications' && (
                        <span className='notifications-bubble-2'>
                            {props.root.authStore.user.unread_notifications.length}
                        </span>
                    )}
                </div>
            ))}
            {props.root.authStore.isAdmin && adminMenuItems.map((item, index) => (
                <div
                    key={index}
                    className={props.location.pathname === item.dest ? "selected-dashboard-mobile-nav-item" : "mobile-menu-item"}
                    onClick={() => handleItemPress(item)}
                >
                    {item.icon}
                    <p className="mobile-menu-item-label">{item.label}</p>
                    {/* {item.label === 'Messages' && (
                        <span className='messages-bubble'>{}</span>
                    )} */}
                    {item.label === 'Notifications' && (
                        <span className='notifications-bubble-2'>{props.notificationCount}</span>
                    )}
                </div>
            ))}
        </div>
    )
})

const MobileDropdown = (props: { reference: React.RefObject<any>, onLogoutPress: any, root: RootStore }) => {
    const handleItemPress = (action: string) => {
        switch (action) {
            case 'back':
                history.push('/');
                break;
            case 'profile':
                props.root.authStore.isAdmin ? history.push('/orgaccountsettings'): history.push('/bidderdashboard_accountsettings');
                break;
            case 'logout':
                props.onLogoutPress();
                break;
            default:
                break;
        }
    }
    return (
        <div className="mobile-dashboard-dropdown">
            <button onClick={() => handleItemPress('back')}>Go Back to Website</button>
            <button onClick={() => handleItemPress('profile')}>Account</button>
            <button onClick={() => handleItemPress('logout')}>Logout</button>
        </div>
    )
}

interface MenuItem {
    label: string,
    icon: React.ReactElement,
    dest: string
}

const bidderMenuItems: MenuItem[] = [
    {
        label: "Dashboard",
        icon: <RiDashboardLine size={25} />,
        dest: "/bidderdashboard"
    },
    {
        label: "Customer Statement",
        icon: <HiOutlineDocumentReport size={25} />,
        dest: "/bidderstatement"
    },
    {
        label: "Buy Credits",
        icon: <GiCash size={25} />,
        dest: "/biddercredits"
    },
    {
        label: "Messages",
        icon: <RiMessage3Line size={25} />,
        dest: "/bidderinbox"
    },
    {
        label: "Notifications",
        icon: <IoIosNotificationsOutline size={25} />,
        dest: "/biddernotification"
    },
    {
        label: "Account Settings",
        icon: <AiOutlineSetting size={25} />,
        dest: "/bidderdashboard_accountsettings",
    }
]

const adminMenuItems: MenuItem[] = [
    {
        label: "Dashboard",
        icon: <RiDashboardLine size={25} />,
        dest: "/orgdashboard"
    },
    {
        label: "Statement",
        icon: <HiOutlineDocumentReport size={25} />,
        dest: "/orgstatement"
    },
    {
        label: "Messages",
        icon: <RiMessage3Line size={25} />,
        dest: "/orginbox"
    },
    {
        label: "Notifications",
        icon: <IoIosNotificationsOutline size={25} />,
        dest: "/orgnotification"
    },
    {
        label: "Auctions",
        icon: <RiAuctionLine size={25} />,
        dest: "/orgauctions"
    },
    {
        label: "Teams",
        icon: <BsPeople size={25} />,
        dest: "/orgteams"
    },
    {
        label: "Account Settings",
        icon: <AiOutlineSetting size={25}/>,
        dest: "/orgaccountsettings"
    }
]

export default Nav;