import { observer } from "mobx-react-lite";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/AuctionItem.css";
import Auction from "../models/auction";
import history from "./History";

const { REACT_APP_SERVER } = process.env;

const AuctionItem = observer((props: { auction: Auction }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: Math.floor(Math.random() * (4000 - 3000 + 800) + 3000),
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false
};

  const handlePress = () => {
    history.push(`/auction/${props.auction.id}`)
  }

  return(
    <div className="auction-item-container" onClick={handlePress}>
      <div className="auction-item-org">
        <p className="line-clamp-1">{props.auction.organization.name}</p>
      </div>
      <Slider {...settings}>
        {props.auction.prize.images && 
          props.auction.prize.images.map(image => (
            <img 
              src={`${REACT_APP_SERVER}/images/prizeimages/im${image.name}`} 
              alt='auction prize'
              className="auction-img"
            />
          ))}
      </Slider>
      <p className="auction-item-title">{props.auction.prize.name}</p>
      <p className="auction-item-description line-clamp-2 h-[42px]">
        {props.auction.description}
      </p>
      <div className="auction-item-bids">
        <p>Bids Remaining</p>
        <p>{props.auction.numberOfBids}</p>
      </div>
      <div className="auction-item-action-div">
        <button className="auction-item-bid">
          BID NOW
        </button>
      </div>
    </div>
  )
})

export default AuctionItem;