import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { Message } from "../models/message-box";
import { formatMessageDate } from "../utils";
import { RootContext } from "..";

interface ChatBubbleProps {
  data: Message
}

const ChatBubble = observer((props: ChatBubbleProps) => {
  const root = useContext(RootContext);

  return (
    <div className={props.data.from === root.authStore.user.id ? "message-box right-user": "message-box left-user"}>
      <div className={props.data.from === root.authStore.user.id ? "right-text" : "left-text"}>
        <p className="bubble-text">{props.data.text}</p>
        <p className="date">{formatMessageDate(props.data.created_at)}</p>
      </div>
    </div>
  )
})

export default ChatBubble;